import {
  Box,
  Container,
  Stack,
  useColorModeValue,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import { FaInstagram, FaGithub } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { SiDiscord } from 'react-icons/si';
import { BackToTop } from './BackToTop';
import { SocialButton } from './SocialButton';
import { getEnvVariable } from 'utils/env';

/**
 * Renders the footer component for the Fever Dreams website.
 *
 * @param {number} scrollPosition - The current scroll position.
 * @param {Function} onGoToTop - The handler function for scrolling to the top.
 * @returns {JSX.Element} The rendered footer component.
 */
export function FdFooter({
  scrollPosition = 0,
  onGoToTop = () => { console.warn(`No GoToTop handler`) }
}) {

  const REACT_APP_git_hash = getEnvVariable("REACT_APP_git_hash", process.env.REACT_APP_git_hash)
  const REACT_APP_discord_link = getEnvVariable("REACT_APP_discord_link", process.env.REACT_APP_discord_link)

  return (
    <Box flexShrink={0}>
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}>
          <Container
            as={Stack}
            maxW={'full'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ md: 'space-between' }}
            align={{ md: 'center' }}>
            <Wrap>
              <WrapItem>
                <SocialButton label={'X'} href={'https://x.com/FeverDreamsAI'}>
                  <FaXTwitter />
                </SocialButton>
              </WrapItem>
              <WrapItem>
                <SocialButton label={'Discord'} href={REACT_APP_discord_link}>
                  <SiDiscord />
                </SocialButton>
              </WrapItem>
              <WrapItem>
                <SocialButton label={'Instagram'} href={'https://www.instagram.com/feverdreamsai'}>
                  <FaInstagram />
                </SocialButton>
              </WrapItem>
              <WrapItem>
                <SocialButton label={`Github (${REACT_APP_git_hash})`} href={'https://github.com/entmike/feverdreams'}>
                  <FaGithub />
                </SocialButton>
              </WrapItem>
            </Wrap>
            <BackToTop scrollPosition={scrollPosition} onGoToTop={onGoToTop} />
          </Container>
        </Box>
      </Box>
    </Box>
  );
}