import { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoImagesOutline } from "react-icons/io5";
import {
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon
} from '@chakra-ui/react';
import { PiBarbellLight, PiHourglassHighLight } from "react-icons/pi";
import Datasets from 'components/Training/Datasets';
import FluxTraining from 'components/Training/FluxTraining';
import TrainingQueue from 'components/Training/TrainingQueue';

export default function TrainingPage() {
  const params = useParams()
  const navigate = useNavigate()

  let defaultTrainingValue = {
    name : "New Training",
    version : "1.0",
    steps : 1000,
    learning_rate : 0.0004,
    trigger_word : "",
    lora_rank : 16
  }
  
  const [trainingValue, setTrainingValue] = useState(defaultTrainingValue)
  const tab = params.tab
  
  let tabList = [
    {
      key: "datasets",
      icon: <Icon as={IoImagesOutline} mr={2} />,
      text: "Datasets",
      tab: <TabPanel key="datasets"><Datasets /></TabPanel>
    },
    {
      key: "training",
      icon: <Icon as={PiBarbellLight} mr={2} />,
      text: "Training",
      tab: <TabPanel key="training"><FluxTraining id={params.id} key="training" onChange={v=>{setTrainingValue(v)}} value={trainingValue}/></TabPanel>
    },
    {
      key: "queue",
      icon: <Icon as={PiHourglassHighLight} mr={2} />,
      text: "Queue",
      tab: <TabPanel key="queue"><TrainingQueue /></TabPanel>
    }
  ]
  

  let si = 0
  tabList.forEach((t, index) => { if (t.key === tab) si = index })

  return (
    <Flex direction="column" w={'full'} h={'100%'}>
      <Heading>{`Training`}</Heading>
      <Tabs
        variant={"enclosed"}
        isLazy
        lazyBehavior="unmount"
        index={si}
        onChange={(index) => {
            navigate(`/train/${tabList[index].key}`);
        }}
      >
        <TabList overflowX="auto" overflowY="hidden">{tabList.map(tab => <Tab key={tab.key}>{tab.icon && tab.icon}{tab.text}</Tab>)}</TabList>
        <TabPanels>{tabList.map(tab => tab.tab)}</TabPanels>
      </Tabs>
    </Flex>
  );
}
