import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { Tooltip, IconButton, Flex, Slider, SliderTrack, SliderThumb, SliderFilledTrack } from '@chakra-ui/react';
import { AiOutlineClear } from 'react-icons/ai'
import { PropBox } from './PropBox';
import { getEnvVariable } from 'utils/env'
import { calculateBlobSHA256 } from 'utils/pieceUtils'

/**
 * MaskEditor component for editing masks on an image.
 *
 * @component
 * @param {Object} props.bgImage - The background image object.
 * @param {string} props.bgImage.url - The URL of the background image.
 * @param {string} props.bgImage.type - The type of the background image.
 * @param {Object} props.mask - The mask object.
 * @param {string} props.mask.mask - The URL of the mask image.
 * @param {Function} props.onChange - The function to handle changes in the mask.
 * @param {string} props.value - The value of the mask.
 * @returns {JSX.Element} The MaskEditor component.
 */
export function MaskEditor({ 
  value = {}, 
  bgContent, 
  onChange=()=>{console.warn("No onChange handler")}
}) {
  // console.log(value)
  const coordsRef = useRef({ x: 0, y: 0 });
  // Reference Background Layer
  const backgroundRef = useRef(null);
  // Drawing Layer
  const drawingCanvasRef = useRef(null);
  const drawingContextRef = useRef(null);
  // Guide Layer
  const guideCanvasRef = useRef(null);
  const guideContextRef = useRef(null);

  const [bgBlob, setBgBlob] = useState("")

  const [isPainting, setIsPainting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [bgContent, setBgContent] = useState(null);
  const [brushDiameter, setbrushDiameter] = useState(20);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  const img = useMemo(() => {
    const image = new Image();
    image.onload = function () {
      // When the image is loaded, set its width and height to the state
      setImgDimensions({ width: this.naturalWidth, height: this.naturalHeight });
    };
    if (bgBlob instanceof Blob) image.src = bgBlob;
  }, [bgBlob]);

  useEffect(() => {
    if (guideCanvasRef.current) {
      guideContextRef.current = guideCanvasRef.current.getContext('2d');
    }
  }, []);

  useEffect(() => {
    const canvas = drawingCanvasRef.current;
    const context = canvas.getContext('2d');
    drawingContextRef.current = context;
  }, [img, drawingCanvasRef, brushDiameter])


  useEffect(() => {
    const canvas = drawingCanvasRef.current;
    canvas.width = imgDimensions.width;
    canvas.height = imgDimensions.height;

    const bgCanvas = backgroundRef.current
    bgCanvas.width = imgDimensions.width
    bgCanvas.height = imgDimensions.height

    const guideCanvas = guideCanvasRef.current;
    guideCanvas.width = imgDimensions.width;
    guideCanvas.height = imgDimensions.height;

    const image = new Image();
    image.onload = () => {
      const bgCtx = bgCanvas.getContext('2d');
      bgCtx.drawImage(image, 0, 0, imgDimensions.width, imgDimensions.height);
    }
    if (bgBlob instanceof Blob) image.src = bgBlob
  }, [imgDimensions.width, imgDimensions.height, bgBlob]);

  useEffect(() => {
    if (value.content && drawingContextRef.current) {
      const maskImage = new Image();
      maskImage.onload = () => {
        drawingContextRef.current.drawImage(maskImage, 0, 0, maskImage.width, maskImage.height);
      }
      if (value.content instanceof Blob) maskImage.src = URL.createObjectURL(value.content)
    }
  }, [value.content, drawingContextRef])

  useEffect(() => {
    if (drawingContextRef.current) {
      drawingContextRef.current.strokeStyle = "#000000";
      drawingContextRef.current.lineWidth = brushDiameter;
    }
  }, [brushDiameter, drawingContextRef]);


  const getRelativeCoordinates = (event) => {
    const canvas = drawingCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    return {
      x: (event.clientX - rect.left) * scaleX,
      y: (event.clientY - rect.top) * scaleY,
    }
  }

  const clearCanvas = () => {
    const canvas = drawingCanvasRef.current;
    const context = drawingContextRef.current;
    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    saveCanvasAsBlob();
  };

  const handleMouseDown = (event) => {
    setIsPainting(true);
    const coords = getRelativeCoordinates(event);
    coordsRef.current = coords;
    drawingContextRef.current.lineCap = 'round'
    drawingContextRef.current.lineJoin = 'round'
    drawingContextRef.current.lineWidth = brushDiameter
    drawingContextRef.current.beginPath();
    drawingContextRef.current.moveTo(coords.x, coords.y);
  };

  const handleMouseUp = () => {
    exitPainting();
    saveCanvasAsBlob();
  };

  useEffect(() => {
    if (isPainting) {
      drawingContextRef.current.strokeStyle = "#000000";
      drawingContextRef.current.lineWidth = brushDiameter;
    } else {

    }
  }, [isPainting, brushDiameter])


  const exitPainting = () => {
    setIsPainting(false);
    drawingContextRef.current.globalCompositeOperation = "source-over";
  };

  const saveCanvasAsBlob = () => {
    // const mask = drawingCanvasRef.current.toDataURL('image/png');
    drawingCanvasRef.current.toBlob((blob) => {
        calculateBlobSHA256(blob).then( hash=> {
          console.log("Mask SHA256: ", hash)
          onChange({ hash, content : blob })
        });
    })
  };

  /**
   * Hides the brush guide by clearing the entire guide canvas.
   */
  const hideBrushGuide = () => {
    const guideContext = guideCanvasRef.current.getContext('2d');
    // Clear the entire guide canvas
    guideContext.clearRect(0, 0, guideCanvasRef.current.width, guideCanvasRef.current.height);
  };

  const handleMouseMove = (event) => {
    const guideCanvas = guideCanvasRef.current;
    const guideContext = guideContextRef.current;

    // Get the canvas position in relation to the viewport
    const rect = guideCanvas.getBoundingClientRect();

    // Calculate the mouse position relative to the canvas
    const x = (event.clientX - rect.left) * (guideCanvas.width / rect.width);
    const y = (event.clientY - rect.top) * (guideCanvas.height / rect.height);

    if (isPainting) {
      const isRightMouseButton = event.nativeEvent.which === 3;
      const coords = getRelativeCoordinates(event);
      coordsRef.current = getRelativeCoordinates(event);

      if (isRightMouseButton) {
        drawingContextRef.current.globalCompositeOperation = "destination-out";
      } else {
        drawingContextRef.current.globalCompositeOperation = "source-over";
      }

      drawingContextRef.current.lineTo(coords.x, coords.y);
      drawingContextRef.current.stroke();
      guideContext.clearRect(0, 0, guideCanvas.width, guideCanvas.height); // Clear the guide canvas when painting
    } else {
      // Draw the brush guide
      guideContext.clearRect(0, 0, guideCanvas.width, guideCanvas.height); // Clear the guide canvas
      guideContext.beginPath();
      guideContext.arc(x, y, brushDiameter / 2, 0, Math.PI * 2);
      guideContext.fillStyle = "rgba(0,0,0,0.5)";  // Translucent black
      guideContext.fill();
    }
  };

  const lookupContentByHash = useCallback(async (h) => {
    // console.log("Retrieving content for hash", h)
    if (h) {
        const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
        const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)
        setIsLoading(true)
        fetch(`${REACT_APP_api_url}/v3/getimagehash/${h}`).then(response => {
            if (response.status === 200) {
                return response.json()
            }
        }).then(lookupContent => {
            setIsLoading(false)
            if (lookupContent === null) {
                console.log(`No metadata found for hash ${h}, probably a new upload.`)
            } else {
                // Set value.content to Blob from hash
                fetch(`${REACT_APP_images_url}/uploads/${h}`).then(response => response.blob()).then(blob => {
                  const newValue = { hash: h, content: blob }
                  console.log(newValue)
                  onChange(newValue)
                })
            }
        })
    }
}, [])

useEffect(() => {
    lookupContentByHash(value.hash)
},[value.hash, lookupContentByHash])

useEffect(() => {
  if (bgContent.content && bgContent.content instanceof Blob) {
      setBgBlob(URL.createObjectURL(bgContent.content))
  }
}, [bgContent.content, setBgBlob])

  return (
    <PropBox label="Inpaint" type="mask" value={value} onChange={v => onChange(v)}>
      <Flex w={"full"}>
        <Tooltip hasArrow label="Clear Mask">
          <IconButton onClick={clearCanvas} icon={<AiOutlineClear />}/>
        </Tooltip>
        <Slider
          id='brushDiameter'
          value={brushDiameter}
          step={1}
          min={1}
          max={250}
          onChange={v => { setbrushDiameter(v) }}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip hasArrow label={`Brush Diameter (${brushDiameter})`}><SliderThumb /></Tooltip>
        </Slider>
      </Flex>

      <div style={{ position: "relative" }}>
        {/* Background Image Canvas */}
        <canvas ref={backgroundRef} style={{ width: imgDimensions.width, maxWidth: "100%", height: "auto" }} />

        {/* Drawing Canvas */}
        <canvas ref={drawingCanvasRef} style={{ position: "absolute", left: 0, top: 0, maxWidth: "100%", height: "auto", borderWidth: 1, opacity: 0.75 }} />

        {/* Brush Guide Canvas */}
        <canvas
          ref={guideCanvasRef}
          style={{ position: "absolute", left: 0, top: 0, maxWidth: "100%", height: "auto", cursor: "none" }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={hideBrushGuide}
          onMouseLeave={exitPainting}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    </PropBox>
  );
};
