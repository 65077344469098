import React from 'react';
import { Box, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineHeart, AiFillHeart, AiOutlineClose, AiOutlineSave } from 'react-icons/ai';
import { RiGitRepositoryPrivateLine } from 'react-icons/ri'
import { BiLinkExternal } from 'react-icons/bi';
import { DownloadIcon } from '@chakra-ui/icons';
import { FaPaintBrush, FaTrash, FaTrashRestore } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useAuth0 } from "@auth0/auth0-react";
import { getEnvVariable } from 'utils/env';

export function InfinityLightboxControls({
  mode = "preview",
  selectedPiece,
  onDelete,
  onUndelete,
  onMove,
  onCloseHandler,
  onCreate,
  isMoving,
  source }) {
  const { token, user, permissions } = useAuth();
  const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)
  const navigate = useNavigate()
  const loginWithRedirect = useAuth0().loginWithRedirect;
  const [pinLoading, setIsPinLoading] = useState(false)

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>{selectedPiece && <Box style={{
      position: 'absolute',
      top: '0px',
      right: '0px',
      left: '0px',
      textAlign: 'right',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1001
    }}>
      
      {mode !== "uploads" && <>
        {/* Like */}
        {selectedPiece.source === "pieces" && <Button
          colorScheme={'pink'}
          isLoading={pinLoading}
          size="md"
          variant={"ghost"}
          onClick={(e) => {
            e.stopPropagation();
            if (!token) {
              loginWithRedirect()
            }
            let method = "POST"
            if (selectedPiece.pinned) {
              method = "DELETE"
            } else {
              method = "POST"
            }
            setIsPinLoading(true)
            fetch(
              `${process.env.REACT_APP_api_url}/pin/${selectedPiece._id}`,
              {
                method: method,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({}),
              }
            ).then(r => {
              if (selectedPiece.pinned) {
                selectedPiece.pinned = false  // Probably not the best way to do this
                selectedPiece.likes = selectedPiece.likes ? selectedPiece.likes : 0
                selectedPiece.likes--
              } else {
                selectedPiece.pinned = true  // Probably not the best way to do this
                selectedPiece.likes = selectedPiece.likes ? selectedPiece.likes : 0
                selectedPiece.likes++
              }
              setIsPinLoading(false)
            })
          }}
          leftIcon={(selectedPiece.pinned) ? <AiFillHeart /> : <AiOutlineHeart />}
        >
          {(selectedPiece && selectedPiece.likes ? selectedPiece.likes : 0)}</Button>}
        {/* Piece Page (New Tab) */}
        {selectedPiece && source !== "workspace" && source !== "deleted" && <>
          <Tooltip hasArrow label="Open Details in new tab">
            <IconButton variant={"ghost"} colorScheme={'blue'} isRound icon={<BiLinkExternal />} onClick={(e) => { e.stopPropagation(); window.open(`/piece/${selectedPiece._id}`, '_blank') }} />
          </Tooltip>
        </>}
        {/* Download */}
        <Tooltip hasArrow label="Download .PNG">
          <IconButton
            colorScheme={'green'}
            variant={"ghost"}
            borderRadius={"full"}
            onClick={() => {
              let url = `${REACT_APP_images_url}/${selectedPiece.location.png}`
              window.open(url, "_blank")
            }}
            ml={1}
            icon={<DownloadIcon />}
          />
        </Tooltip>
        {user && user.sub && <>
          {/* Save to Personal Gallery */}
          {(mode === "review" || mode === "preview") && (user && user.sub === selectedPiece.author && permissions.includes("supporter")) && <Tooltip label={"Save to Personal Gallery"}>
            <IconButton variant={"ghost"} colorScheme={"green"} isRound icon={<RiGitRepositoryPrivateLine />} onClick={(e) => { e.stopPropagation(); onMove("personal_pieces") }} />
          </Tooltip>}
          {/* Save to Personal Gallery */}
          {(mode === "review" || mode === "preview") && (user && user.sub === selectedPiece.author) && <Tooltip label={"Publish to Gallery"}>
            <IconButton variant={"ghost"} colorScheme={"green"} isRound icon={<AiOutlineSave />} onClick={(e) => { e.stopPropagation(); onMove("pieces") }} />
          </Tooltip>}
          {/* Create */}
          {mode !== "review" && selectedPiece && (!selectedPiece.private || (selectedPiece.author === user.sub || permissions.includes("seeprompts"))) && <>
            <IconButton variant={"ghost"} colorScheme={"green"} isRound icon={<FaPaintBrush />} onClick={() => {
              if (onCreate) {
                // Figure out if I need to fire this event or not
                onCreate(selectedPiece)
                onCloseHandler()
                navigate(`/${selectedPiece.architecture}/${selectedPiece._id}`)
              } else {
                navigate(`/${selectedPiece.architecture}/${selectedPiece._id}`)
              }
            }
            } />
          </>}
          {/* Delete */}
          {selectedPiece && (permissions.includes("admin") || (user && user.sub === selectedPiece.author)) && mode !== "deleted" && <>
            <IconButton isLoading={isMoving} variant={"ghost"} colorScheme={"red"} isRound icon={<FaTrash />} onClick={e => { e.stopPropagation(); onDelete(e) }} />
          </>}
          {/* Undelete */}
          {mode !== "workspace" && selectedPiece && (permissions.includes("admin") || (user && user.sub === selectedPiece.author)) && mode === "deleted" && <>
            <IconButton isLoading={isMoving} variant={"ghost"} colorScheme={"red"} isRound icon={<FaTrashRestore />} onClick={e => { e.stopPropagation(); onUndelete(e) }} />
          </>}
        </>}
      </>}
      {mode === "uploads" && <>
        {/* Delete Upload */}
        {selectedPiece && (permissions.includes("admin") || (user && user.sub === selectedPiece.author)) && <>
          <IconButton isLoading={isMoving} variant={"ghost"} colorScheme={"red"} isRound icon={<FaTrash />} onClick={e => { e.stopPropagation(); onDelete(e) }} />
        </>}
      </>}
      {/* Close */}
      <IconButton icon={<AiOutlineClose />} onClick={e => { if (onCloseHandler) onCloseHandler() }} variant={"ghost"} />
    </Box>}
    </>
  )
}
