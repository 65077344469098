import { useState } from 'react';
import { Box, Heading, Wrap, Center, IconButton, Text, Button } from '@chakra-ui/react';
import { BiHelpCircle } from 'react-icons/bi';
/**
 * Renders a help header component with a title, description, and a toggle button to show/hide help content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.HTML - The HTML content to be rendered. If provided, the description will be rendered as plain text.
 * @param {string} props.title - The title of the help header.
 * @param {string} props.description - The description of the help header.
 * @returns {JSX.Element} The rendered help header component.
 */
export function HelpHeader({
    HTML,
    title,
    description
}) {
    const [showHelp, setShowHelp] = useState(false);

    return(
        <>
        <Wrap><Heading>{title}</Heading>
        <IconButton isRound variant={"ghost"} icon={<BiHelpCircle/>} onClick={()=>{setShowHelp(!showHelp)}}/>
        </Wrap>
        {showHelp && <Box mt={5} borderWidth={1} p={5} borderRadius="md">
            {HTML && <Text>{description}</Text>}
            {HTML && description && <div dangerouslySetInnerHTML={{ __html: description }} />}
        <Button size={"sm"} colorScheme={"green"} onClick={()=>{
              setShowHelp(false)
            }}><Center>Got it.</Center></Button>
        </Box>}
        </>
    )
}