import { useState, useEffect } from 'react'
import { BiLayerPlus } from 'react-icons/bi'
import { Flex, Spacer, Badge, Input, List, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, PopoverHeader, Tooltip, ListItem } from '@chakra-ui/react'

export function NodeDropdown({
    obj,
    onClick = (v) => { console.log(`No onClick handler for NodeDropdown.  Value: ${v}`) }
}) {
    const [filteredNodes, setFilteredNodes] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        setFilteredNodes(Object.keys(obj).filter((value, index, loras) => {
            // if (!value.meta) return true
            if (value.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true
            return false
        }))
    }, [filter, obj])

    return (
        <Popover key="addLayer">
            <PopoverTrigger><span>
                <Tooltip hasArrow openDelay={250} label="Add Node...">
                    <IconButton icon={<BiLayerPlus />} variant={"ghost"}/>
                </Tooltip>
            </span></PopoverTrigger>
            <PopoverContent>
                <PopoverHeader fontWeight='semibold'>
                    {Object.keys(obj).length > 0 && <Input placeholder='Filter node...' value={filter} onClick={e => { e.stopPropagation() }} onChange={e => { setFilter(e.currentTarget.value) }} />}
                </PopoverHeader>
                <PopoverArrow />
                {/* <PopoverCloseButton /> */}
                <PopoverBody maxHeight={"15rem"} overflowY={"auto"} overflowX={"hidden"} p={0}>
                    <List>
                        {filteredNodes.map((key, index) => {
                            return <ListItem key={key} value={key} p={2} style={{ cursor: "pointer" }} _hover={{ backgroundColor: "gray.500" }} onClick={e => { onClick(key) }}><Flex>{key}
                        </Flex></ListItem>
                        })}
                    </List>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}