import React from 'react'
import { useState } from 'react'
import {
  Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Box,
  Show, Hide, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, IconButton
} from '@chakra-ui/react'
import { FaGear } from "react-icons/fa6";
import ModelFeed from 'components/shared/Feed/ModelFeed';
import ModelFilters from './ModelFilters';
import { useAuth } from 'contexts/AuthContext';

export default function ModelBrowser({
  hide,
  filters = {
    type: [],
    baseModel: [],
    name: "",
    alsoDescription: false,
    includeNsfw: false,
    includeTrash: false,
    includeOffline: false,
  },
  sort = {
    direction: 'desc',
    by: 'date'
  },
  onSelect
}) {
  const [myFilters, setMyFilters] = useState(filters)
  const [mySort, setMySort] = useState(sort)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { permissions } = useAuth()

  const featuredFeed = <ModelFeed onSelect={onSelect} filters={{ ...myFilters, onlyFeatured: true }} sort={mySort} />
  const favoritesFeed = <ModelFeed onSelect={onSelect} filters={{ ...myFilters, onlyFavorites: true }} sort={mySort} />
  const browseFeed = <ModelFeed onSelect={onSelect} filters={myFilters} sort={mySort} />
  const tabs = [
    { name: 'Featured', feed: featuredFeed },
    { name: 'Favorites', feed: favoritesFeed }
  ]
  if(permissions.includes("any_models")){
    tabs.push({ name: 'Browse', feed: browseFeed })
  }
  // if(permissions.includes("nsfw")){
  //   tabs.push({name: 'NSFW', feed: <ModelFeed onSelect={onSelect} filters={{...myFilters, includeNsfw: true}} sort={mySort} />})
  // }
  // if(permissions.includes("admin")){
  //   tabs.push({name: 'Trash', feed: <ModelFeed onSelect={onSelect} filters={{...myFilters, includeTrash: true}} sort={mySort} />})
  // }
  return <>
    <Flex direction={'row'}>
      <ModelFilters hide={hide} filters={myFilters} sort={mySort} onChange={(f, s) => {
        setMyFilters(f)
        setMySort(s)
      }} />
      <Box flex={3} >
        <Tabs isLazy lazyBehavior="unmount">
          <TabList>
            {tabs.map(tab => <Tab>{tab.name}</Tab>)}
          </TabList>
          <TabPanels>
            {tabs.map(tab => <TabPanel p={0}>
              <Show above="md"><Box h={'100%'} overflowY={'scroll'}>
                <Box display={'block'} h={"calc(100dvh - 280px)"} w={"full"}>{tab.feed}</Box>
              </Box>
              </Show>
              <Hide above="md">{tab.feed}</Hide>
            </TabPanel>)}
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>

    <Hide above="md"><IconButton colorScheme='blue' aria-label="Show Filters" rounded="full" position="absolute" bottom={'80px'} right={1} icon={<FaGear />} onClick={onOpen} /></Hide>

    <Drawer placement='left' size={'lg'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth='1px'>Filters</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <ModelFilters filters={myFilters} sort={mySort} onChange={(f, s) => {
            setMyFilters(f)
            setMySort(s)
          }} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  </>
}