import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { generateStarterJob, conformJob, compile } from 'utils/pieceUtils'
import { generateStarterJob as generateFluxStarterJob, conformJob as conformFluxJob, compile as compileFlux } from 'utils/fluxUtils'
import {
    Text, Button, Flex, useToast, useDisclosure, Show, Hide, Badge, Box, IconButton,
    Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton
} from '@chakra-ui/react'
import { FaImage } from "react-icons/fa";
import { WorkshopPreview } from './WorkshopPreview'
import { CaptiveMessage } from "../shared/CaptiveMessage"
import { SaveFilterDialog } from './SaveFilterDialog'
import { InfinityLightbox } from 'components/shared/Feed/InfinityLightbox'
import { WorkshopProperties } from './WorkshopProperties'
import { FluxProperties } from './FluxProperties'
import { WorkshopGrid } from './WorkshopGrid'
import { useAuth } from 'contexts/AuthContext'
import { getEnvVariable } from 'utils/env'
import { WorkshopProvider } from "contexts/WorkshopContext"

/**
 * Represents the Workshop component.
 * @param {boolean} inModal - Indicates if the component is rendered in a modal.
 * @param {Object} defaults - The default values for the component.
 * @param {Object} initialSelectedItem - The initial selected item for the component.
 * @returns {JSX.Element} The Workshop component.
 */
export default function Workshop({
    inModal = false,
    defaults = null,
    initialSelectedItem = null,
    architecture = null
}) {
    const starters = useMemo(() => {
        return {
            "stable-diffusion": generateStarterJob(false),
            "flux": generateFluxStarterJob(false)
        }
    }, [])
    const conform = useMemo(() => {
        return {
            "stable-diffusion": conformJob,
            "flux": conformFluxJob
        }
    }, [])
    const compiles = useMemo(() => {
        return {
            "stable-diffusion": compile,
            "flux": compileFlux
        }
    }, [])
    const { isAuthenticated, token, permissions } = useAuth()
    const navigate = useNavigate()
    const params = useParams()
    const loginDisclosure = useDisclosure()
    const messageDisclosure = useDisclosure()
    const { isOpen: isSaveFilterOpen, onClose: onSaveFilterClose } = useDisclosure()
    const [isLightboxOpen, setIsLightboxOpen] = useState(false)
    const [isLoaded, setIsLoaded] = useState(true)
    const toast = useToast()
    const [fetching, setFetching] = useState(false)
    const [isGenerating, setIsGenerating] = useState(false)
    const [invalidItem, setInvalidItem] = useState(false)
    const [filters, setFilters] = useState({})
    const [workItems, setWorkItems] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [data, setData] = useState(!params.uuid ? starters[architecture] : null)
    const [selectedItem, setSelectedItem] = useState(initialSelectedItem)

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    const imageClickHandler = () => {
        if (!inModal) setIsLightboxOpen(true)
    }

    const rollHandler = (e, amount) => {
        let method = "POST"
        setFetching(true)
        fetch(
            `${REACT_APP_api_url}/v3/rolldice`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ uuid: data._id, amount }),
        }
        ).then((response => {
            setFetching(false)
            toast({
                title: "Job Received",
                description: `${amount} more coming up`
            })
        })).catch(err => {
            setFetching(false)
        })
    }

    const generateHandler = (d, options) => {
        setIsGenerating(true)
        let bs = options.batchSize
        let priority = options.priority
        let isPrivate = options.isPrivate
        let parent_id = options.parent_id || "new"
        let debug = options.debug
        compiles[architecture](d, {
            token, batchSize: bs, priority, toast, isPrivate, debug, parent_id,
            onComplete: () => {
                setIsGenerating(false)
            },
            onError: () => {
                setIsGenerating(false)
            }
        })
    }

    const retryHandler = e => {
        return new Promise((resolve, reject) => {
            // setWorkItems(workItems.filter((item) => item._id !==data._id))
            setFetching(true)
            fetch(`${REACT_APP_api_url}/v3/retry`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ uuid: e._id }),
            }).then((response) => {
                return response.json()
            }).then((d) => {
                toast({
                    title: `${e._id} marked for retry.`
                })
                resolve(d)
            })
        })
    }

    const cancelHandler = e => {
        return new Promise((resolve, reject) => {
            setWorkItems(workItems.filter((item) => item._id !== e._id))
            setFetching(true)
            fetch(`${REACT_APP_api_url}/v3/cancel`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ uuid: e._id }),
            }).then((response) => {
                return response.json()
            }).then((d) => {
                setFetching(false)
                toast({
                    title: `${e._id} cancelled.`
                })
                resolve(d)
            })
        })
    }

    const setPrivateHandler = (e, isPrivate) => {
        fetch(`${REACT_APP_api_url}/setprivate`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ uuid: e._id, private: isPrivate }),
            }
        ).then(d => {
            updateData({ ...data, private: isPrivate })
            toast({
                title: `${e._id} parameters set to ${isPrivate ? "hidden" : "visible"}.`
            })
        })
    }

    const deleteHandler = e => {
        workItems.forEach((workitem, index) => {
            if (workitem._id === e._id) {
                if (index <= (workItems.length - 1)) {
                    if (index < workItems.length - 1) {
                        updateData(conform[architecture](workItems[index + 1]))
                        navigate(`/${architecture}/${workItems[index + 1]._id}`)
                        // console.log(workItems[index+1]._id)
                    }
                }
            }
        })
        setWorkItems(workItems.filter((item) => item._id !== e._id))
        setFetching(true)
        fetch(`${REACT_APP_api_url}/delete/${e._id}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({}),
            }
        ).then(d => {
            setFetching(false)
            // toast({
            //     title: `${e._id} deleted.`
            // })
        })
    }

    const handleMoved = (uuid) => {
        let newItems = JSON.parse(JSON.stringify(workItems))
        newItems.forEach((item, index) => {
            if (item._id === uuid) {
                newItems.splice(index, 1)
                setWorkItems(newItems)
                if (data && data._id === uuid) {
                    let nextItem = workItems[index + 1]
                    updateData(nextItem)
                    navigate(`/${architecture}/${nextItem._id}`)
                }
            }
        })
    }

    const clickHandler = (item) => {
        if (!item) {
            navigate(`/${architecture}`)
            return
        }
        // alert(`${item._id}\n${data._id}`)
        if (item._id === data._id) {
            setData(starters[architecture])
            navigate(`/${architecture}`)
        } else {
            navigate(`/${architecture}/${item._id}`)
        }
    }

    const publishHandler = e => {
        setWorkItems(workItems.filter((item) => item._id !== data._id))
        setFetching(true)
        fetch(`${REACT_APP_api_url}/review/${data._id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
        }).then(response => {
            return response.json()
        }).then(d => {
            setFetching(false)
            toast({
                title: `${data._id} published.`
            })
            return d
        }).then(
            // lookupWorkItem(data._id)
        )
    }

    const personalPublishHandler = e => {
        setWorkItems(workItems.filter((item) => item._id !== data._id))
        setFetching(true)
        fetch(`${REACT_APP_api_url}/review_personal/${e._id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
        }).then(response => {
            return response.json()
        }).then(d => {
            setFetching(false)
            toast({
                title: `${e._id} saved to Personal Gallery.`
            })
            return d
        })
        // .then(lookupWorkItem(data._id))
    }

    const lookupWorkItem = useCallback(async (uuid) => {
        setIsLoaded(false)
        const jobUrl = `${REACT_APP_api_url}/v3/anyjob/${uuid}`
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
        let d = await fetch(jobUrl, { headers })
            .then((response) => {
                return response.json()
            }).then(actualData => {
                let j = conform[architecture](actualData)
                setData(j)
                return j
            }).catch(err => {
                console.error(err)
                return null
            }).finally(item => {
                setFetching(false)
                setIsLoaded(true)
            })
        return d
    }, [token, REACT_APP_api_url, architecture, conform])

    const updateData = useCallback(obj => {
        setData((prevData) => {
            let newData = conform[architecture]({ ...prevData, ...obj }, architecture)

            for (let param in newData.params) {
                if (filters[param] !== undefined) {
                    if (JSON.stringify(filters[param]) !== JSON.stringify(newData.params[param])) {
                        console.log(`Updating ${param} filter to ${newData.params[param]}`)
                        if (filters[param] !== newData.params[param]) setFilters({ ...filters, [param]: newData.params[param] })
                    }
                }
            }

            return newData
        })
    }, [filters, architecture, conform])

    useEffect(() => {
        if (token) {
            loginDisclosure.onClose()
            if (process.env["REACT_APP_require_vetting"] && !permissions.includes("vetted")) {
                messageDisclosure.onOpen()
            } else {
                messageDisclosure.onClose()
            }
        } else {
            // if(!isAuthenticated) loginWithRedirect({redirect : window.location.href})
        }
    }, [token, loginDisclosure, messageDisclosure, permissions])

    useEffect(() => {
        if (params.uuid || initialSelectedItem) {
            let uuid = params.uuid || initialSelectedItem._id
            lookupWorkItem(uuid).then(item => {
                if (item) {
                    item.priority = "medium"
                    updateData(item)
                    setSelectedItem(item)
                    setInvalidItem(false)
                } else {
                    setInvalidItem(true)
                }
            })
        } else {
            console.log(`Setting starter for ${architecture}`)
            updateData(starters[architecture])
            setSelectedItem(starters[architecture])
            setInvalidItem(false)
        }
    }, [architecture, params.uuid, initialSelectedItem, updateData, setSelectedItem, setInvalidItem, lookupWorkItem, starters])

    return <WorkshopProvider id={selectedItem?._id}>
        {isLightboxOpen && <InfinityLightbox
            source="workspace"
            mode="workspace"
            selectedPiece={selectedItem}
            items={workItems}
            onMoved={handleMoved}
            onCloseHandler={(e) => {
                setIsLightboxOpen(false);
            }}
            onSelectHandler={(item) => {
                updateData(item);
                navigate(`/${architecture}/${item._id}`);
            }}
        />
        }

        <SaveFilterDialog
            isOpen={isSaveFilterOpen}
            onClose={onSaveFilterClose}
            filters={filters}
        />
        <CaptiveMessage
            disclosure={messageDisclosure}
            title="Vetting Required"
            message={
                <Text>
                    You need the <Badge variant={'outline'} colorScheme={'green'}>vetted</Badge> role to do that.
                    Ask your friends or on Discord for an access token.
                </Text>
            }
        />
        {isAuthenticated && (!process.env["REACT_APP_require_vetting"] || permissions.includes('vetted')) && !invalidItem && data && data.params && (
            <Flex h={'100%'} w={'full'} gap={2} p={2} direction={{ base: 'column', lg: 'row' }} >
                {/* <!-- Main --> */}
                <Flex gap={2} h={"100%"} w={'100%'} direction={{ base: 'column', lg: 'row' }}>
                    {/* <!-- Properties and Preview --> */}
                    <Flex h={"100%"} gap={2} flex={2} direction={{ base: 'column', md: 'row' }}>
                        <Box flex={1} width={['100%', '100%', '60%', '55%']}>
                            {/* Properties */}
                            {architecture === "stable-diffusion" && <WorkshopProperties
                                architecture={architecture}
                                isLoaded={isLoaded}
                                data={data}
                                onChange={(v) => updateData(v)}
                                rollHandler={rollHandler}
                                generateHandler={generateHandler}
                                fetching={fetching}
                                isGenerating={isGenerating}
                            />}
                            {architecture === "flux" && <FluxProperties
                                architecture={architecture}
                                isLoaded={isLoaded}
                                data={data}
                                onChange={(v) => updateData(v)}
                                rollHandler={rollHandler}
                                generateHandler={generateHandler}
                                fetching={fetching}
                                isGenerating={isGenerating}
                            />}
                        </Box>
                        {/* <!-- Workshop Preview --> */}
                        <Box flex={1} height={['auto', 'auto', '100%', '100%']} width={['100%', '100%', '60%', '55%']}>
                            <WorkshopPreview
                                isLoaded={isLoaded}
                                data={data}
                                onChange={(d) => {
                                    updateData(d);
                                }}
                                onParent={(uuid) => {navigate(`/${architecture}/${uuid}`)}}
                                onImageClick={imageClickHandler}
                                publishHandler={publishHandler}
                                personalPublishHandler={personalPublishHandler}
                                deleteHandler={deleteHandler}
                                setPrivateHandler={setPrivateHandler}
                                architecture={architecture}
                            />
                        </Box>
                    </Flex>
                    {/* <!-- Grid --> */}
                    {!inModal && (
                        <Show above="md" flex="1">
                            <Box h={'100%'} w={['100%', '100%', '100%', '33.33%']}>
                                <WorkshopGrid selectedItem={selectedItem} onClick={clickHandler} cancelHandler={cancelHandler} retryHandler={retryHandler} architecture={architecture} />
                            </Box>
                        </Show>

                    )}
                    <Hide above="lg">
                        <IconButton colorScheme='blue' aria-label="Browse" rounded="full" position="absolute" bottom={'80px'} right={1} icon={<FaImage />} onClick={onOpen} />
                    </Hide>

                    <Drawer placement='right' size={'md'} onClose={onClose} isOpen={isOpen}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerBody>
                                <WorkshopGrid selectedItem={selectedItem} onClick={clickHandler} cancelHandler={cancelHandler} retryHandler={retryHandler} architecture={architecture} />
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Flex>
            </Flex>
        )}
        {invalidItem && (
            <>
                <Text>{params.uuid} is inaccessible.</Text>
                <Button onClick={(e) => { setData(starters[architecture]); setInvalidItem(false); }}>Return to your Workshop</Button>
            </>
        )}
    </WorkshopProvider>
}