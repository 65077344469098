import { useState, useEffect } from "react"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Heading, IconButton, Flex, Spacer } from "@chakra-ui/react"
import Workshop from "./Workshop"
import { MdClose } from "react-icons/md"
import { FiMaximize2, FiMinimize2 } from "react-icons/fi"
/**
 * Renders a modal for creating a workshop.
 *
 * @param {Object} piece - The workshop piece object.
 * @param {boolean} isOpen - Indicates whether the modal is open or not.
 * @param {Object} defaults - The default values for the workshop.
 * @param {Function} onClose - The function to be called when the modal is closed.
 * @returns {JSX.Element} The modal workshop component.
 */
export function ModalWorkshop({
  piece,
  isOpen,
  defaults,
  onClose = () => { console.warn("No onClose handler") },
  architecture = "stable-diffusion"
}) {
    const [selectedItem, setSelectedItem] = useState((piece && piece.params!==undefined)?piece:null)
    const [size, setSize] = useState("5xl")
    useEffect(() => {
      if(!piece || piece.params===undefined){
        // Fetch metadata
        setSelectedItem(piece)
      }else{
        setSelectedItem(piece)
      }
    },[piece])
    
    return <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay bg='none'
        backdropFilter='auto'
        backdropInvert='10%'
        backdropBlur='10px'
      />
      <ModalContent>
        <ModalHeader><Flex>
          <Heading size={"md"}>Create</Heading><Spacer />
          <IconButton variant={"ghost"} onClick={()=>{setSize(size==="full"?"5xl":"full")}} icon={size==="full"?<FiMinimize2 />:<FiMaximize2 />}/>
          <IconButton variant={"ghost"} onClick={onClose} icon={<MdClose />}/>
          </Flex>
        </ModalHeader>
        <ModalBody>
          {selectedItem && <Workshop architecture={architecture} initialSelectedItem={selectedItem} inModal={true} defaults={defaults}/>}
        </ModalBody>
      </ModalContent>
    </Modal>
}