import {
    NumberIncrementStepper, NumberDecrementStepper, NumberInput, NumberInputStepper, NumberInputField,
    FormLabel, IconButton, Tooltip, Flex, Wrap,
} from '@chakra-ui/react';
import { MdSwapHoriz } from 'react-icons/md'

/**
 * Renders a component for size options.
 *
 * @param {Object} value - The current value of the prompts.
 * @param {Function} onChange - The function to handle changes in the prompts.
 * @returns {JSX.Element} The rendered component.
 */
export const SizeOptions = ({
    value,
    onChange = () => {console.warn("SizeOptions onChange not implemented")},
    includeNegative = true
}) => {
    

    return (value !== undefined) ? <Wrap>
    {/* width */}
    <Wrap>
        <Tooltip hasArrow label="Width of your render before any upscaling is applied.  Must be a multiple of 64." openDelay={250}>
            <FormLabel whiteSpace={"nowrap"} htmlFor="width">Width</FormLabel>
        </Tooltip>
        <NumberInput
            id="width"
            value={value.width}
            min={128}
            max={2048}
            step={64}
            clampValueOnBlur={true}
            onChange={v => { onChange({ ...value, "width": v }) }}>
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    </Wrap>
    {/* Swap w/h */}
    <Tooltip hasArrow label="Swap width/height">
        <IconButton variant={"outline"} onClick={v => { onChange({ ...value, "width": value.height, "height": value.width }) }} icon={<MdSwapHoriz />} />
    </Tooltip>
    {/* height */}
    <Wrap>
        <Flex>
            <Tooltip hasArrow label="Height of your render before any upscaling is applied.  Must be a multiple of 64." openDelay={250}>
                <FormLabel whiteSpace={"nowrap"} htmlFor="height">Height</FormLabel>
            </Tooltip>
        </Flex>
        <NumberInput
            id="height"
            value={value.height}
            min={128}
            max={2048}
            step={64}
            clampValueOnBlur={true}
            onChange={v => { onChange({ ...value, "height": v }) }}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    </Wrap>
</Wrap>:null
}