import { FormLabel, Tooltip, Flex, Switch, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'
import { PropBox } from './PropBox'

/**
 * Renders the PostProcessingOptions component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the options.
 * @param {Function} [props.onChange] - The callback function to handle value changes.
 * @returns {JSX.Element} The rendered PostProcessingOptions component.
 */
export const PostProcessingOptions = ({ value, onChange = () => { console.warn("No PostProcessingOptions onChange hander.") } }) => {
    return <PropBox type="post_processing" label="Post-processing Options" value={value} onChange={v => onChange && onChange(v)} >
        <Flex w={"full"} direction={"column"}>
            {/* skin_enhancement */}
            <Flex>
                <Tooltip hasArrow label="Adds skin detail.  Can help counteract some of the 'airbrushed' feel that some models have." openDelay={250} >
                    <FormLabel htmlFor="skin_enhancement">Add skin detail (lite)</FormLabel>
                </Tooltip>
                <Switch
                    id="skin_enhancement"
                    isChecked={value.skin_enhancement}
                    onChange={e => onChange({ ...value, "skin_enhancement": e.target.checked })}
                />
            </Flex>
            {/* skin_enhancement_strength */}
            {value.skin_enhancement && <Flex w={"full"} direction="column">
            <Tooltip hasArrow label="Skin enhancement strength" openDelay={250}>
                <FormLabel htmlFor="skin_enhancement_strength">Strength ({(value.skin_enhancement_strength !== undefined) ? value.skin_enhancement_strength : 0.5})</FormLabel>
            </Tooltip>
            <Slider flex={1} id='skin_enhancement_strength' value={value.skin_enhancement_strength} step={0.05} min={0} max={1} onChange={v => onChange && onChange({ ...value, "skin_enhancement_strength": v })} >
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
            </Flex>}

            {/* texturize */}
            <Flex>
                <Tooltip hasArrow label="Texturize image, giving it a subtle grain effect." openDelay={250} >
                    <FormLabel htmlFor="texturize">Texturize</FormLabel>
                </Tooltip>
                <Switch
                    id="texturize"
                    isChecked={value.texturize}
                    onChange={e => onChange({ ...value, "texturize": e.target.checked })}
                />
            </Flex>
            {/* texturize_strength */}
            {value.texturize && <Flex w={"full"} direction="column">
            <Tooltip hasArrow label="Texturize strength" openDelay={250}>
                <FormLabel htmlFor="texturize_strength">Strength ({(value.texturize_strength !== undefined) ? value.texturize_strength : 0.5})</FormLabel>
            </Tooltip>
            <Slider flex={1} id='texturize_strength' value={value.texturize_strength} step={0.05} min={0} max={1} onChange={v => onChange && onChange({ ...value, "texturize_strength": v })} >
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
            </Flex>}


        </Flex>
    </PropBox>
}