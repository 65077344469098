import { FormLabel, Select, Tooltip, Flex, Slider, SliderTrack, SliderThumb, SliderFilledTrack } from '@chakra-ui/react';
import { generateFaceRestoreList } from 'utils/pieceUtils';
import { PropBox } from './PropBox';

/**
 * Renders the FaceRestoreOptions component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the options.
 * @param {Function} props.onChange - The callback function to handle value changes.
 * @returns {JSX.Element} The rendered Face Restore Options component.
 */
export const FaceRestoreOptions = ({
  value,
  onChange = () => console.warn("No FaceRestoreOptions onChange handler.")
}) => {
  const faceRestoreList = generateFaceRestoreList()
  const updateValue = function (v) { onChange && onChange(v) }

  return <PropBox type="facerestore" label="Face Restore Options" value={value} onChange={v => updateValue(v)}>
    {/* model */}
    <Flex w={"full"}>
      <Tooltip hasArrow label="Facial Restore Model" openDelay={250}>
        <FormLabel htmlFor="model">Model</FormLabel>
      </Tooltip>
      <Select id="model" value={value.model}
        onChange={e => updateValue({ ...value, model: e.target.selectedOptions[0].value })}
      >{faceRestoreList.map(facemodel => {
        return <option key={facemodel.key} value={facemodel.key}>{facemodel.text ? facemodel.text : facemodel.key}</option>
      })}</Select>
    </Flex>
    {/* weight */}
    {value.model === "codeformer.pth" && <>
      <Tooltip hasArrow label="0 = Maximum effect, 1 = Minimum effect" openDelay={250}>
        <FormLabel htmlFor="weight">Weight ({value.weight})</FormLabel>
      </Tooltip>
      <Flex w={"full"} direction={"column"}>
        <Slider
          id='weight'
          value={value.weight}
          step={0.05}
          min={0}
          max={1}
          onChange={v => updateValue({ ...value, weight: v })}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Flex>
    </>}
  </PropBox>
}