import { Box, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getEnvVariable } from 'utils/env';
import { ModelOverlay } from './ModelOverlay';

/**
 * Renders an InfinityPreview component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.piece - The piece object.
 * @param {string} [props.mode="preview"] - The mode of the component.
 * @param {string} props.source - The source of the component.
 * @param {Function} [props.onClick=() => { console.warn("No onClick handler for InfinityPreview") }] - The onClick event handler.
 * @param {Function} [props.onMoved=() => { console.warn("No onMoved handler for InfinityPreview") }] - The onMoved event handler.
 * @returns {JSX.Element} The rendered InfinityPreview component.
 */
export function ModelPreview({
  model,
  onClick = () => { console.warn("No onClick handler for InfinityPreview") },
  onMoved = () => { console.warn("No onMoved handler for InfinityPreview") },
}) {
  const [isInterested, setIsInterested] = useState(false) 
  const [model_url, setModelUrl] = useState(null)

  const REACT_APP_model_images_url = getEnvVariable("REACT_APP_model_images_url", process.env.REACT_APP_model_images_url)
  const REACT_APP_hidden_model_images_url = getEnvVariable("REACT_APP_hidden_model_images_url", process.env.REACT_APP_hidden_model_images_url)
  
  useEffect(() => {
  if(model?.hidden){
    setModelUrl(REACT_APP_hidden_model_images_url)
  }else{
    setModelUrl(REACT_APP_model_images_url)
  }
  }, [model, REACT_APP_model_images_url, REACT_APP_hidden_model_images_url])

  function touchover() {
    setIsInterested(true)
    window.setTimeout(() => {
      setIsInterested(false)
    }, 4000)
  }
  function over() {
    setIsInterested(true)
  }
  function out() {
    setIsInterested(false)
  }

  return (
    <Box
      className="infinitypreview"
      borderRadius={"lg"}
      style={{ overflow: "hidden" }}
      pos="relative"
      onTouchStart={touchover}
      onMouseOver={over}
      onMouseOut={out}
    >
      {/* Blur image */}
      <Image pos="absolute"
        alt={model._id}
        src={`${model_url}/models/${model._id}.preview.jpg`}
        style={{ objectFit: "cover", top: "0", left: "0", width: "100%", height: "100%" }}
        borderRadius="xl"
      />
      {/* Blur layer */}
      <Box
        style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", backdropFilter: "blur(20px)", WebkitBackdropFilter: "blur(20px)" }}>
        {/* Image */}
        <Image 
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
            pos="absolute" 
            alt={model._id}
            onClick={onClick} 
            src={`${model_url}/models/${model._id}.preview.jpg`}/>
        {/* Overlay */}
        {/* <ModalWorkshop architecture={piece.architecture} piece={piece} isOpen={modalOpen} onClose={e => { setModalOpen(false) }} /> */}
        <ModelOverlay model={model} isInterested={isInterested} 
          onClick={onClick}
        //   onCreate={()=>{ onCreate?onCreate(piece):setModalOpen(true)}}
        //   onMoved={e => { onMoved(piece._id) }}
        />
      </Box>
    </Box>
  );
}
