import { Heading, Stack, Text, Box, Container } from '@chakra-ui/react';
import { Stats } from './Stats.js';
import InfinityFeed from 'components/shared/Feed/InfinityFeed';
import { getEnvVariable } from 'utils/env.js';

export default function Hero() {

  const REACT_APP_title = getEnvVariable("REACT_APP_title", process.env.REACT_APP_title);
  
  return (
    <>
      <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
        <Text
          bgGradient="linear(to-l, #7928CA, #FF0080)"
          bgClip="text"
          // fontSize="lg"
          fontWeight="bold"
          textAlign={'center'}
          whiteSpace="nowrap"
          pl={{ base: 2, md: 0 }}
          mb={5}
          display={{ md: 'block' }}
        >
          {REACT_APP_title}
        </Text>
      </Heading>
      <Box
        rounded={'xl'}
        position={'relative'}
        p={5}
        style={{ overflow: 'hidden' }}
      >
        <Stats />
      </Box>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <Heading>Recently Liked</Heading>
        <InfinityFeed method={`continuation`} path={`/v3/recentlikes/desc`} />
      </Container>
    </>
  );
}
