import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Select, Box, Text, useDisclosure } from "@chakra-ui/react";
import Webcam from "react-webcam";

/**
 * WebcamCapture component for capturing photos using the webcam.
 *
 * @component
 * @param {function} onCapture - The callback function to handle the captured image (optional).
 * @returns {JSX.Element} The WebcamCapture component.
 */
const WebcamCapture = ({
  onCapture = () => { console.warn("No onCapture handler for WebcamCapture") }
}) => {
  const webcamRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [cameraError, setCameraError] = useState(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Convert the base64 image to a file
    fetch(imageSrc).then(r => r.blob()).then(blobFile => {
      onCapture(blobFile)
      onClose()
    });
  };

  const handleDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      const cameraOptions = videoDevices.map(device => ({ value: device.deviceId, label: device.label }));
      if (cameraOptions.length > 0) {
        setCameras(cameraOptions);
        setSelectedCamera(cameraOptions[0].value);
      } else {
        setCameraError('Could not access cameras. Please ensure you have given necessary permissions and try again.');
      }
    } catch (error) {
      setCameras([]);
      setCameraError('Could not access cameras. Please ensure you have given necessary permissions and try again.');
    }
  }

  useEffect(() => {

  }, []);

  return (
    <>
      <Button onClick={e => {
        handleDevices()
        onOpen()
      }}>Open Camera</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {cameraError && <Text color="red.500">{cameraError}</Text>}
            {cameras.length > 1 && (
              <Box marginBottom="20px">
                <Select placeholder="Select a Camera" value={selectedCamera} onChange={(e) => setSelectedCamera(e.target.value)}>
                  {cameras.map((camera, index) => (
                    <option key={index} value={camera.value}>
                      {camera.label}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              minScreenshotWidth={1280}
              minScreenshotHeight={720}
              videoConstraints={selectedCamera ? { deviceId: selectedCamera } : undefined}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={capture}>
              Take Photo
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WebcamCapture;
