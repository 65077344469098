import { useState, useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getEnvVariable } from 'utils/env'
import { useAuth } from 'contexts/AuthContext'
import { ComfyNode } from './ComfyNode'
import {
  Box, Heading, Badge, Text, Flex, IconButton, Tooltip, useToast, Wrap,
  Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Spacer
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { NodeDropdown } from './NodeDropdown'
import JsonView from '@uiw/react-json-view'
import { nordTheme } from '@uiw/react-json-view/nord';
import { FiCopy } from 'react-icons/fi'

export function Editor() {
  const params = useParams()
  const toast = useToast()
  const accordionRefs = useRef({});
  const { isAuthenticated, token, permissions } = useAuth()
  const [isLoaded, setIsLoaded] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [expandedNodes, setExpandedNodes] = useState([])
  const [scrollTo, setScrollTo] = useState(null)
  const [data, setData] = useState({})
  const [assets, setAssets] = useState({})
  const [objectInfo, setObjectInfo] = useState(null)

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

  const lookupJob = useCallback(async (id) => {
    setIsLoaded(false)
    const jobUrl = `${REACT_APP_api_url}/v3/anyjob/${id}`
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }

    let d = await fetch(jobUrl, { headers })
      .then((response) => {
        return response.json()
      }).then(actualData => {
        if (actualData && actualData.pipeline) {
          return (actualData)
        } else {
          return (null)
        }
      }).then(d => {
        setFetching(false)
        setIsLoaded(true)
        return d
      })
    return d
  }, [token, REACT_APP_api_url])

  useEffect(() => {
    const object_info = async () => {
      const infoUrl = `${REACT_APP_api_url}/object_info`
      const headers = {
        "Content-Type": "application/json"
      }
      return await fetch(infoUrl, { headers })
        .then((response) => {
          return response.json()
        }).then(json => {
          return json
        }).then(o => {
          setFetching(false)
          setIsLoaded(true)
          // console.log(o)
          return o
        })
    }
    object_info().then((d) => { if (objectInfo === null) setObjectInfo(d) })
  }, [objectInfo, REACT_APP_api_url])

  useEffect(() => {
    if (params && params.id) {
      lookupJob(params.id).then((d) => { setData(d) })
    }
  }, [params, lookupJob])

  useEffect(() => {
    if (scrollTo) {
      const nodeElement = accordionRefs.current[scrollTo];
      if (nodeElement) {
        setTimeout(() => { nodeElement.scrollIntoView({ block: "start" }) }, 100)
      }
    }
  }, [scrollTo])

  return (
    <Box>
      <Heading size={"lg"}>Pipeline Editor</Heading>
      Welcome to the pipeline editor.  Here you can edit the pipeline and assets for a job.  Just kidding, this is a read-only for now.
      <Tabs>
        <TabList>
          <Tab>Nodes</Tab>
          <Tab>JSON</Tab>
          <Tab>Assets</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {objectInfo && <NodeDropdown obj={objectInfo} onClick={v => {
              alert(`You clicked on ${v}`)
            }} />}
            {data && data.pipeline && objectInfo && <Accordion index={expandedNodes} allowMultiple>
              {Object.keys(data.pipeline).map((k, i) => {
                return <AccordionItem key={k} borderWidth={1} mb={4} rounded={5} ref={el => accordionRefs.current[k] = el}>
                  <AccordionButton onClick={e => {
                    let expandIndex = i
                    // Expand the node
                    let found = false
                    for (let j = 0; j < expandedNodes.length; j++) {
                      if (expandedNodes[j] === expandIndex) {
                        found = true
                        break
                      }
                    }
                    if (!found) {
                      setExpandedNodes([...expandedNodes, expandIndex])
                    } else {
                      let newExpandedNodes = []
                      for (let j = 0; j < expandedNodes.length; j++) {
                        if (expandedNodes[j] !== expandIndex) {
                          newExpandedNodes.push(expandedNodes[j])
                        }
                      }
                      setExpandedNodes(newExpandedNodes)
                    }

                  }}>
                    <AccordionIcon />
                    <Flex flex={1} direction={"column"} textAlign={"start"}>
                      <Heading size={"md"}>{k}</Heading>
                      <Wrap><Badge colorScheme='blue'>{data.pipeline[k].class_type}</Badge></Wrap>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ComfyNode name={k} pipeline={data.pipeline} value={data.pipeline[k]} objectInfo={objectInfo} onOutputClick={name => {
                      let expandIndex = -1
                      let expandNode = null
                      Object.keys(data.pipeline).forEach((k, i) => {
                        if (k === name) {
                          expandIndex = i
                          expandNode = k
                        }
                      })

                      // Expand the node
                      let found = false
                      for (let j = 0; j < expandedNodes.length; j++) {
                        if (expandedNodes[j] === expandIndex) {
                          found = true
                          break
                        }
                      }

                      // Scroll to the node
                      // console.log(`Scrolling to ${expandNode}`)
                      // setScrollTo(expandNode)
                      const nodeElement = accordionRefs.current[expandNode];
                      if (nodeElement) {
                        setTimeout(() => { nodeElement.scrollIntoView({ block: "start" }) }, 200)
                      }
                      if (!found) {
                        setExpandedNodes([...expandedNodes, expandIndex])
                      }
                    }} />
                  </AccordionPanel>
                </AccordionItem>
              })}
            </Accordion>}
          </TabPanel>
          {data && data.pipeline && <TabPanel>
            <Tooltip hasArrow label="Copy to clipboard">
              <IconButton icon={<FiCopy />} onClick={e => {
                navigator.clipboard.writeText(JSON.stringify(data.pipeline, null, 2)).then(
                  () => {
                    toast({ title: `Copied to clipboard.` })
                  }, () => {
                    /* clipboard write failed */
                  }
                );
              }} variant={"ghost"} />
            </Tooltip>
            <JsonView
              // indentWidth={20}
              shortenTextAfterLength={0}
              value={data.pipeline}
              style={nordTheme}
              enableClipboard={false}
              collapsed={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </TabPanel>}
          <TabPanel>
            {data && data.assets && <Accordion defaultIndex={expandedNodes} allowMultiple>
              {Object.keys(data.assets).map((k, i) => {
                return <AccordionItem key={k} borderWidth={1} mb={4} rounded={5}>
                  <AccordionButton>
                    <AccordionIcon />
                    <Flex flex={1}>
                      <Text>{k}</Text>
                      <Spacer />
                      {/* <Badge colorScheme='blue'>{data.pipeline[k].class_type}</Badge> */}
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <JsonView
                      value={data.assets[k]}
                      // indentWidth={20}
                      shortenTextAfterLength={0}
                      style={nordTheme}
                      enableClipboard={false}
                      collapsed={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </AccordionPanel>
                </AccordionItem>
              })}
            </Accordion>}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}