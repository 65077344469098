import { useState, useRef } from 'react';
import InfinityFeed from 'components/shared/Feed/InfinityFeed';
import {
  Box, Flex, Button, Tabs, TabList, Tab, TabPanels, TabPanel,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  useToast, useDisclosure, IconButton, Tooltip
} from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { WorkshopGridToolbar } from './WorkshopGridToolbar';
import { PropBox } from './PropBox';
import { getEnvVariable } from 'utils/env';
import { QueueFeed } from 'components/shared/Feed/QueueFeed'
import { AiOutlineDelete, AiOutlineClear } from 'react-icons/ai'
import { VscRefresh } from "react-icons/vsc";

/**
 * Renders a grid of workshop items.
 *
 * @param {Object} inititalSelectedItem - The initial selected item.
 * @param {Function} onClick - The click event handler for the workshop grid.
 * @param {string} architecture - The architecture of the workshop.
 * @returns {JSX.Element} The rendered workshop grid component.
 */
export function WorkshopGrid({
  inititalSelectedItem = null,
  onClick = () => { console.warn("No onClick handler for WorkshopGrid") },
  cancelHandler = () => { console.warn("No cancelHandler for WorkshopGrid") },
  retryHandler = () => { console.warn("No retryHandler for WorkshopGrid") },
  architecture = "stable-diffusion",
}) {
  const REACT_APP_api_url = getEnvVariable('REACT_APP_api_url', process.env.REACT_APP_api_url)
  
  // Hooks
  const { token, user } = useAuth()
  const toast = useToast()
  const { isOpen: isAlertDialogOpen, onOpen: onAlertDialogOpen, onClose: onAlertDialogClose } = useDisclosure()

  // States
  const [selectedItem, setSelectedItem] = useState(inititalSelectedItem)
  const [workspaceSize, setWorkspaceSize] = useState(0)
  const [filters, setFilters] = useState({ source: ["queue", "reviews"], architecture })
  const [fetchingItems, setFetchingItems] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isClearing, setIsClearing] = useState(false)
  const [refresh, setRefresh] = useState(0)
  
  // Refs
  const cancelRef = useRef()

  const filterChangeHandler = (f) => {
    setFilters(f)
  }

  const clearFailedJobsHandler = e => {
    setIsClearing(true)
    fetch(`${REACT_APP_api_url}/clear_failed`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({architecture}),
    }).then(response => {
      return response.json()
    }).then(d => {
      setIsClearing(false)
      toast({
        title: `Failed Jobs Cleared.`
      })
      return d;
    }).then(d => {
      setIsClearing(false)
      // return fetchWorkspace(0)
    }).then(d => {
      onAlertDialogClose()
    })
  }

  const confirmOnEmptyWorkspaceHandler = e => {
    // setFetchingItems(true)
    fetch(`${REACT_APP_api_url}/empty_workspace`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ architecture }),
    }).then(
      response => { return response.json() }
    ).then(
      d => {
        // setFetchingItems(false);
        setWorkspaceSize(d.count)
        onAlertDialogOpen()
      }
    )
  }

  const emptyWorkspace = e => {
    setIsDeleting(true)
    fetch(`${REACT_APP_api_url}/empty_workspace`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ architecture }),
    }).then(response => {
      return response.json()
    }).then(d => {
      setIsDeleting(false)
      toast({
        title: `Workspace Emptied.`
      })
      return d;
    }).then(d => {
      // return fetchWorkspace(0)
    }).then(d => {
      onAlertDialogClose()
    })

  }

  const onCreate = (p) => {
    if (selectedItem === p._id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(p._id);
    }
    onClick(p)
  }

  const funkyHeight = "calc(100dvh - 370px)"
  // const funkyHeight = "calc(100dvh - 300px)"

  return (<>
    <AlertDialog
      isOpen={isAlertDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={onAlertDialogClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Empty Workspace
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure? You are about to delete{' '}
            {workspaceSize.toLocaleString()} items.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={fetchingItems}
              ref={cancelRef}
              onClick={onAlertDialogClose}
            >
              Cancel
            </Button>
            <Button
              isLoading={fetchingItems}
              colorScheme="red"
              onClick={(e) => {
                setFetchingItems(true);
                emptyWorkspace();
              }}
              ml={3}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
    <PropBox h={"100%"} label="Browse" clipboard={false} isLoaded={!fetchingItems} collapsible={false}>
      <Flex direction="column" h={'100%'} w={"full"}>
        {/* Header Area */}
        <Box flexShrink={0} alignItems={'center'}>
          <WorkshopGridToolbar
            filters={filters}
            isFetchingItems={fetchingItems}
            isClearing={isClearing}
            isDeleting={isDeleting}
            // onRefresh={onRefresh}
            onFilterChange={filterChangeHandler}
            onClearFailedJobs={clearFailedJobsHandler}
            onEmptyWorkspace={confirmOnEmptyWorkspaceHandler}
          />
        </Box>
        <Box flex={1} h="100%" overflow={'hidden'} w={"full"}>
          <Tabs h="100%" isLazy>
            <TabList>
              <Tab>Workspace</Tab>
              <Tab>Published</Tab>
              <Tab>Personal</Tab>
              <Tab>Queued</Tab>
            </TabList>
            <TabPanels>
              <TabPanel >
                <Tooltip hasArrow label="Empty Workspace...">
                  <IconButton isLoading={isDeleting} icon={<AiOutlineDelete />} variant={"ghost"} colorScheme={"red"} onClick={e => { confirmOnEmptyWorkspaceHandler() }} />
                </Tooltip>
                <Box overflowY={'scroll'} display={'block'} h={"100%"} w={"full"}>
                  <Box display={'block'} h={"calc(100dvh - 420px)"} w={"full"}>
                    <InfinityFeed h={"100vh"} mode="review" filters={filters} path={`/v3/my/reviews/${user.sub}/desc`} source="work" method="continuation" onCreate={onCreate} />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel h={"100%"} >
                <Box overflowY={'scroll'} display={'block'} h={"100%"} w={"full"}>
                  <Box display={'block'} h={funkyHeight} w={"full"}>
                    <InfinityFeed filters={{ source: "pieces", architecture }} path={`/v3/my/pieces/${user.sub}/desc`} method="continuation" onCreate={onCreate} />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel h={"100%"} >
                <Box overflowY={'scroll'} display={'block'} h={"100%"} w={"full"}>
                  <Box display={'block'} h={"calc(100dvh - 370px)"} w={"full"}>
                    <InfinityFeed filters={{ source: "personal_pieces", architecture }} path={`/v3/my/personal/${user.sub}/desc`} method="continuation" onCreate={onCreate} />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel h={"100%"} >
                <Tooltip hasArrow label="Clear Failed Jobs...">
                  <IconButton isLoading={isClearing} icon={<AiOutlineClear />} variant={"ghost"} colorScheme={"yellow"} onClick={e => { clearFailedJobsHandler() }} />
                </Tooltip>
                <Tooltip hasArrow label="Refresh">
                  <IconButton icon={<VscRefresh />} variant={"ghost"} colorScheme={"blue"} onClick={() => { setRefresh(refresh + 1) }} />
                </Tooltip>
                <Box overflowY={'scroll'} display={'block'} h={"100%"} w={"full"}>
                  <Box display={'block'} h={"calc(100dvh - 370px)"} w={"full"}>
                    <QueueFeed retryHandler={retryHandler} cancelHandler={cancelHandler} refresh={refresh} filters={{architecture}} onSelect={piece=>{onCreate(piece)}}/>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex >
    </PropBox >
  </>
  )
}