import { Box, Badge, Button, Checkbox, CheckboxGroup, Flex, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Switch, Wrap } from '@chakra-ui/react';
import { generateBaseModelTypeList, generateModelTypeList } from 'utils/pieceUtils';
import { useAuth } from 'contexts/AuthContext';

function FilterToggle({
  children, value, onChange
}){

  let colorScheme = "grey"
  let variant = "solid"
  if(value===true){
    colorScheme = "green"
    variant = "outline"
  }
  if(value===false){
    colorScheme = "red"
    variant = "outline"
  }
  return <Badge style={{"webkit-user-select": "none", "-ms-user-select": "none", "user-select": "none", cursor:"pointer"}} onClick={(e)=>{
    if(value===true){
      onChange(false)
      return
    }
    if(value===false){
      onChange(undefined)
      return
    }
    if(value===undefined){
      onChange(true)
      return
    }
  }} colorScheme={colorScheme} variant={variant} mr={2}>{children}</Badge>
}

export default function ModelFilters({
    hide = { },
    filters, sort, 
    onChange = (filters, sort) => { console.warn("ModelFilters: No onChange function provided")}
    }) {
    
    const {token, permissions} = useAuth()
 
    const fileTypes = generateModelTypeList();
    const baseModelTypes = generateBaseModelTypeList();

    return <Box flex={1}>
          <Box >
            {/* <Box p={2}><hr /></Box> */}
            {!hide.sort && <>
              <RadioGroup onChange={v => onChange(filters, {...sort, direction:v})} value={sort.direction}>
                <Stack direction={['column', 'row']}>
                  <Heading size={"sm"}>Sort</Heading>
                  <Radio value='asc'>Ascending</Radio>
                  <Radio value='desc'>Descending</Radio>
                </Stack>
              </RadioGroup>
              <RadioGroup onChange={v => onChange(filters, {...sort, by:v})} value={sort.by}>
                <Stack direction={['column', 'row']}>
                  <Heading size={"sm"}>By</Heading>
                  <Radio value='date'>Date</Radio>
                  <Radio value='popular'>Popular</Radio>
                </Stack>
              </RadioGroup>
            </>}
            {/* <Box p={2}><hr /></Box> */}
            <FormLabel htmlFor='name' ml={2}>Search by name
              <Wrap>
                <Input flex={1} id="name" value={filters.name} onChange={e => { onChange({...filters, name : e.currentTarget.value}, sort)}}></Input>
              </Wrap>
            </FormLabel>
            <Checkbox ml={2} checked={filters.alsoDescription} onChange={e => { onChange({...filters, alsoDescription : e.currentTarget.checked}, sort)}}>Search also in description</Checkbox>
            {!hide.modelTypes && <CheckboxGroup value={filters.type} onChange={v=>{onChange({...filters, type : v}, sort)}}>
              <Stack direction={['column', 'row']}>
                <Heading size={"sm"}>Model Types</Heading>
                {fileTypes.map(type => {
                  return <Checkbox value={type.key}>{type.text}</Checkbox>
                }
                )}
              </Stack>
            </CheckboxGroup>}
            {!hide.modelArchitecture && <CheckboxGroup value={filters.baseModel} onChange={v=>{onChange({...filters, baseModel : v}, sort)}}>
              <Stack direction={['column', 'row']}>
                <Heading size={"sm"}>Model Architecture</Heading>
                {baseModelTypes.map(type => {
                  return <Checkbox value={type.key}>{type.text}</Checkbox>
                }
                )}
              </Stack>
            </CheckboxGroup>}

            {permissions.includes("nsfw") && <FilterToggle value={filters['includeNsfw']} onChange={v => {onChange({...filters, includeNsfw : v}, sort) }}>NSFW</FilterToggle> }
            {permissions.includes("admin") && <FilterToggle value={filters['includeTrash']} onChange={v => {onChange({...filters, includeTrash : v}, sort) }}>Trash</FilterToggle> }
            {permissions.includes("admin") && <FilterToggle value={filters['includeOffline']} onChange={v => {onChange({...filters, includeOffline : v}, sort) }}>Offline</FilterToggle> }
            
          </Box>
        </Box>
}