import React, { createContext, useState, useEffect, useContext } from 'react';

const WorkshopContext = createContext();

export const useWorkshop = () => {
    return useContext(WorkshopContext);
};

export const WorkshopProvider = ({ id = null, children }) => {
    const [workshopId, setWorkshopId] = useState(null);
    useEffect(() => {
        if (id) {
            setWorkshopId(id);
        }else{
            setWorkshopId(null);
        }

    }, [id]);

    const value = {
        workshopId : workshopId?workshopId:"new",
    };

    return (
        <WorkshopContext.Provider value={value}>
            {children}
        </WorkshopContext.Provider>
    );
};