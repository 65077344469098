import React from 'react';
import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { TagPopover } from 'components/shared/TagPopover';
import { FaTrash, FaTrashRestore, FaTags } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from 'contexts/AuthContext';

import { getEnvVariable } from 'utils/env';
import { formatDistanceToNow } from 'date-fns'

export function UploadOverlay({
  onClick = () => console.warn("No onClick defined"),
  onMoved = () => console.warn("No onMoved defined"),
  upload,
  isInterested,
}) {
  const { isAuthenticated, token, user, permissions } = useAuth();
  const [change, setChange] = useState(0)
  const { loginWithRedirect } = useAuth0()
  const [isDeleted, setIsDeleted] = useState(upload.deleted ? true : false)
  const [isMoving, setIsMoving] = useState(false)
  const [isTagging, setIsTagging] = useState(false)

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
  // console.log(piece)

  let duration = 0.2
  let interestedStyle = {
    cursor: "pointer",
    visibility: "visible",
    opacity: 1,
    transition: `opacity ${duration}s linear`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }
  let uninterestedStyle = {
    visibility: "hidden",
    opacity: 0.4,
    transition: `visibility 0s ${duration}s, opacity ${duration}s linear, backdrop-filter ${duration}s`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }
  let overlayStyle, maskStyle
  if (isInterested) {
    overlayStyle = interestedStyle
  } else {
    overlayStyle = uninterestedStyle
  }
  maskStyle = {}
  
  if (isMoving) {
    overlayStyle.backdropFilter = "blur(10px)"
    overlayStyle.backgroundColor = "rgba(0,0,0,0.4)"
  }

  useEffect(() => {
    setIsDeleted((upload.deleted) ? true : false)
  }, [upload]);
  useEffect(() => {

  }, [isDeleted]);

  let timeRelative = "Long, Long Ago..."
  for (let field of ["saved_on"]) {
    if (upload[field] && upload[field].$date) {
      const timestamp = new Date(upload[field].$date);
      timeRelative = formatDistanceToNow(timestamp, { addSuffix: true });
    }
  }

  return (<>
    <Box style={maskStyle}>
      <Box style={overlayStyle} onClick={() => {
        if (onClick) {
          onClick(upload)
        } else {
          // onOpen()
        }
      }}>
        {/* Left Column */}
        <Flex direction={"column"} style={{
          position: "absolute",
          top: 0,
          left: 0,
          // zIndex : 1,
          opacity: 0.7,
          // color : "#FFF"
        }}
        >
        </Flex>
        
        {/* Right Column */}
        <Flex direction={"column"} style={{
          position: "absolute",
          top: 0,
          right: 0,
          // zIndex : 2
        }} onClick={(e) => { e.stopPropagation() }}>

          {user && (upload?.users?.includes(user.sub) || permissions.includes("admin")) &&
            <Tooltip hasArrow label={isDeleted ? "Undelete" : "Delete"}>
              <IconButton
                isRound
                isLoading={isMoving}
                colorScheme={'red'}
                size="md"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMoving(true)
                  if (!isAuthenticated) {
                    loginWithRedirect()
                  } else {
                    let method = "POST"
                    let url = `${REACT_APP_api_url}/v3/deleteimagehash/${upload._id}`
                    fetch(url, {
                      method,
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                      },
                    }).then(d => {
                      onMoved()
                      setIsMoving(false)
                    })
                  }
                }}
                icon={(isDeleted) ? <FaTrashRestore /> : <FaTrash />}
              />
            </Tooltip>}
          {user && (upload?.users.includes(user.sub) || permissions.includes("admin")) && <TagPopover upload={upload} hash={upload._id} onChange={v=>{setChange(change+1)}}/>}
        </Flex>
      </Box>
    </Box>
  </>);
}