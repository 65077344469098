import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Flex, Heading, Text, Center, Spinner, Box, Badge,Wrap, ButtonGroup, Button, Link, IconButton, Tooltip } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { cancelTraining, getTrainingQueue } from 'utils/api';
import { IoCopyOutline } from "react-icons/io5";
import { TiCancel } from "react-icons/ti";

export default function TrainingQueue() {
    const [trainingQueue, setTrainingQueue] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth();
    const toast = useToast();
    const navigate = useNavigate();

    // Create a function to fetch the training queue
    const fetchTrainingQueue = useCallback(() => {
        setLoading(true);
        getTrainingQueue(token).then(({ data, error }) => {
            if (error) {
                toast({
                    title: "Error",
                    description: error?.message,
                    status: "error",
                    duration: 2500,
                    isClosable: true,
                });
            }
            if (data) {
                setTrainingQueue(data);
                setLoading(false);
            }
        });
    }, [token, toast]);

    // Call fetchTrainingQueue in useEffect
    useEffect(() => {
        fetchTrainingQueue();
    }, [fetchTrainingQueue]);

    return (
        <>
            <Heading size="md">Training Queue</Heading>
            {loading && <Center><Spinner /></Center>}
            {!loading && (
                <Flex direction="column">
                    {trainingQueue.map((t, index) => (
                        <Box key={index} borderWidth={1} borderRadius={"md"} w={"full"} p={5} mb={4}>
                            <Flex direction="row" justifyContent="space-between" alignItems="center">
                                {/* ID and Creation Time */}
                                <Flex direction="column">
                                    <Text>ID: 
                                        <Tooltip hasArrow label={t._id} openDelay={250}>
                                            <Link onClick={e => { navigate(`/train/training/${t._id}`) }}>{t._id.substring(0,8)}...</Link>
                                        </Tooltip>
                                        {/* Icon button to copy ID to clipboard */}
                                        <Tooltip hasArrow label={`Copy Training ID`} openDelay={250}>
                                            <IconButton
                                                variant={"ghost"}
                                                size="xs"
                                                aria-label="Copy Training ID"
                                                icon={<IoCopyOutline />}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(t._id);
                                                    toast({
                                                        title: "Copied",
                                                        description: "Training ID copied to clipboard.",
                                                        status: "info",
                                                        duration: 2500,
                                                        isClosable: true,
                                                    });
                                                }} />
                                        </Tooltip>
                                        {/* Icon button to cancel the job */}
                                        {t.status==="queued" && <Tooltip hasArrow label={`Cancel`} openDelay={250}>
                                            <IconButton
                                                variant={"ghost"}
                                                size="xs"
                                                aria-label="Cancel job"
                                                colorScheme='red'
                                                icon={<TiCancel />}
                                                onClick={() => {
                                                    cancelTraining(token, t._id).then(({ data, error }) => {
                                                        if (error) {
                                                            toast({
                                                                title: "Error",
                                                                description: error?.message,
                                                                status: "error",
                                                                duration: 2500,
                                                                isClosable: true,
                                                            });
                                                        } else {
                                                            toast({
                                                                title: "Success",
                                                                description: "Training job canceled successfully.",
                                                                status: "success",
                                                                duration: 2500,
                                                                isClosable: true,
                                                            });
                                                            // Refresh the training queue after cancellation
                                                            fetchTrainingQueue();
                                                        }
                                                    });
                                                }} />
                                        </Tooltip>}
                                    </Text>
                                    <Text>Created: {new Date(t.created).toLocaleString()}</Text>
                                    <Wrap><Badge variant={"outline"}>{t.status}</Badge></Wrap>
                                </Flex>
                                {/* Name and version */}
                                <Flex direction="column">
                                    <Heading size="sm">{t.name}</Heading>
                                    <Text>{t.version}</Text>
                                </Flex>
                                {/* Steps, learning rate, trigger word, and lora rank */}
                                <Flex direction="column">
                                    <Text>Steps: {t.steps}</Text>
                                    <Text>Learning Rate: {t.learning_rate}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Text>Trigger Word: {t.trigger_word}</Text>
                                    <Text>Lora Rank: {t.lora_rank}</Text>
                                </Flex>
                            </Flex>
                        </Box>
                    ))}
                    {trainingQueue.length === 0 && <Center><Text>No training jobs in queue.</Text></Center>}
                </Flex>
            )}
        </>
    );
}
