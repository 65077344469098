import JsonView from '@uiw/react-json-view'
import { nordTheme } from '@uiw/react-json-view/nord';
import {
    Tabs, TabList, TabPanels, TabPanel, Tab,
    Box, Heading, Badge, Flex, Input, Wrap, Text, HStack, Select, Switch, Spacer, SimpleGrid, FormLabel, Button, ButtonGroup
} from '@chakra-ui/react'
import { NumberInput, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, NumberInputField } from '@chakra-ui/react'

export function ComfyNode({
    onOutputClick = name=>console.log(name),
    name,
    pipeline,
    value,
    objectInfo
}) {
    const obj = objectInfo[value.class_type]

    // Flatten all inputs (required, optional, hidden)
    const allInputs = obj => {
        let inputs = {}
        if (obj.input) {
            Object.keys(obj.input).forEach(k => {
                if (k !== "hidden") {
                    Object.keys(obj.input[k]).forEach(fieldname => {
                        inputs[fieldname] = obj.input[k][fieldname]
                    })
                } else {
                    // Hidden inputs - Ignore.
                }
            })
        }
        return inputs
    }
    const generateInput = ({ fieldname, value }) => {
        let component = null
        let type = null
        let meta = null

        const field = allInputs(obj)[fieldname]

        if (Array.isArray(field)) {
            type = field[0]
            // Dropdown values
            if (Array.isArray(type)) {
                if (obj.name === "CheckpointLoaderSimple" && fieldname === "ckpt_name") {
                    type = "STR"
                } else {
                    type = "DROPDOWN"
                }
            }
            if (field.length > 1) meta = field[1]
        } else {
            console.log("Unknown property type!!")
        }
        // Bindings
        if (value === null) {
            component = <br />
        }
        if (Array.isArray(value)) {
            let items = [
                <option key={"none"}>- Not set -</option>
            ]
            Object.keys(pipeline).forEach((nodename, i) => {
                let node = pipeline[nodename]
                let classType = node.class_type
                let obj = objectInfo[classType]
                if (obj.output && obj.output.length > 0) {
                    for (let output in obj.output) {
                        // console.log(value[1])
                        if (obj.output[output] === type) {
                            items.push(<option key={`${nodename}|${output}`} value={`${nodename}|${output}`}>{nodename} - {obj.output_name[output]}({value[1]})</option>)
                        }
                    }
                }
            })
            component = <Flex>
                <Select id={`${"-"}|${fieldname}`} value={`${value[0]}|${value[1]}`}>{items}</Select>
            </Flex>
        } else {
            // Primitive
            if (type === "STRING" || type === "STR") component = <Input value={value} />
            if (type === "DROPDOWN") component = <Select id={fieldname} value={value}>
                {field[0].map((v, i) => {
                    return <option key={v} value={v}>{v}</option>
                })}
            </Select>
            if (type === "BOOLEAN") component = <Switch id={fieldname} checked={value} />
            if (type === "INT" || type === "FLOAT") component = <NumberInput id={fieldname} value={value} min={meta.min} max={meta.max} step={meta.step} clampValueOnBlur={true} onChange={v => { }}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            // console.log(meta)
        }
        return [<FormLabel htmlFor={fieldname}>{fieldname}<Badge colorScheme='blue'>{type}</Badge></FormLabel>, component]
    }
    return (
        <Tabs>
            <TabList>
                <Tab>Properties</Tab>
                <Tab>JSON</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Flex direction={"column"}>
                        {/* <Heading size={"sm"}>{name}</Heading> */}
                        {Object.keys(obj.input).map((k, i) => {
                            if (obj && obj.input && obj.input[k] && k !== "hidden") {
                                return <Box key={k} borderWidth={1} p={2} rounded={5} mb={4}>
                                    <Heading size={"sm"}>{k === "required" ? "Required" : "Optional"} Inputs</Heading>
                                    <SimpleGrid columns={[1, null, 2]} spacing={2}>
                                        {Object.keys(obj.input[k]).map((fieldname, i) => {
                                            return generateInput({ fieldname, value: value.inputs[fieldname] })
                                        })}
                                    </SimpleGrid>
                                </Box>
                            } else {
                                return <></>
                                // return <Heading size="sm">No {k} inputs for {name}</Heading>
                            }
                        })}
                        {obj.output && obj.output.length > 0 && <Box borderWidth={1} p={2} rounded={5}>
                            <Heading size={"sm"}>Outputs</Heading>
                            <SimpleGrid columns={[1, null, 2]} spacing={2}>
                                {Object.keys(obj.output).map((output, i) => {
                                    return <>
                                        <Flex><span>{obj.output_name[output]}<Badge colorScheme='green'>{obj.output[output]}</Badge></span></Flex>
                                        <ButtonGroup size={"sm"}>{Object.keys(pipeline).filter((node)=>{
                                            let match = false
                                            if(pipeline[node].inputs){
                                                Object.keys(pipeline[node].inputs).forEach((input)=>{
                                                    // console.log(`Checking ${input} in ${node} for ["${name}", ${i}]`)
                                                    const inp = pipeline[node].inputs[input]
                                                    if(Array.isArray(inp)){
                                                        if(inp[0] === name && inp[1] === i) {
                                                            match = true
                                                        }
                                                    }
                                                })
                                            }
                                            return match
                                        }).map(node=>{
                                            return <Button size="sm" variant={"outline"} onClick={()=>{onOutputClick(node)}}>{node}</Button>
                                        })}</ButtonGroup>
                                    </>
                                })}
                            </SimpleGrid>
                        </Box>}
                    </Flex>
                </TabPanel>
                <TabPanel>

                        {value && <JsonView
                            // indentWidth={20}
                            shortenTextAfterLength={0}
                            value={value}
                            style={nordTheme}
                            enableClipboard={false}
                            // collapsed={true}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />}
                        {false && obj && <JsonView
                            value={obj}
                            // indentWidth={20}
                            shortenTextAfterLength={0}
                            style={nordTheme}
                            enableClipboard={false}
                            // collapsed={true}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />}

                </TabPanel>
            </TabPanels>
        </Tabs>

    )
}