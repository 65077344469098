import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
// import { useState } from 'react-usestateref'
import { useParams, useNavigate } from 'react-router-dom';
import { ViewIcon, DownloadIcon } from '@chakra-ui/icons';
import { FaPaintBrush } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart, AiOutlineShareAlt } from 'react-icons/ai';
import { useAuth0 } from '@auth0/auth0-react';
import AugmentModal from '../shared/AugmentModal';

import {
  Tooltip, TabPanel, Tabs, TabPanels, Heading, Link, Image, IconButton, 
  Flex, HStack, Button, Box, useClipboard, Skeleton, Center, Text, Wrap, useToast
} from '@chakra-ui/react';
import { DreamAuthor } from '../shared/DreamAuthor';
import { dt } from '../../utils/dateUtils';
import { useAuth } from '../../contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

function PiecePage() {

  const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)
  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

  const { isAuthenticated, token, user, permissions } = useAuth();
  const { loginWithRedirect } = useAuth0();
  const [data, setData] = useState(null);
  const [isPinned, setIsPinned] = useState(false);
  const [delta, setDelta] = useState(0)
  const [loading, setLoading] = useState(true);
  const [pinLoading, setPinLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shareURL, setShareURL] = useState(window.location.href);
  const { hasCopiedURL, onCopyURL } = useClipboard(shareURL);
  const toast = useToast()
  const params = useParams()
  const navigate = useNavigate()

  const fetchPiece = useCallback(() => {
    const uuid = params.uuid;
    let headers
    if (token) {
      headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    } else {
      // console.log("Not logged in")
    }
    fetch(`${REACT_APP_api_url}/v3/job/${uuid}`, { headers })
      .then((response) => {
        let obj = response.json();
        return obj;
      })
      .then((actualData) => {
        if (actualData) {
          actualData.dominant_color = actualData.dominant_color || [0, 0, 0];
          if (actualData.pinned) {
            setIsPinned(true)
          } else {
            setIsPinned(false)
          }
          if (actualData.userdets.user_str === null) {
            actualData.userdets = {
              user_str: actualData.str_author,
              nickname: "Unknown User"
            }
          }
          actualData.images = []
          actualData.images.push({
            label: "Image",
            hash: actualData._id
          })
          actualData.selectedTab = 0
          setData(actualData);
          setError(null);
        } else {
          setError(`This piece (${uuid}) does not exist.`)
        }
        return actualData
      }).catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.uuid, token, REACT_APP_api_url])

  useEffect(() => {
    fetchPiece();
  }, [params.uuid, token, fetchPiece])




  return <>
    <AugmentModal />
    {error}
    {!error && <>
      <HStack mt={4} mb={4} maxW="1024" m="auto" position={'relative'}>
        <Skeleton isLoaded={!loading} className='w-100'>
          <Wrap className="w-100" align={"center"} alignItems="center" px='5'>
            <Flex minWidth="250px" mr="auto!important" alignItems="center">
              <Box>
                <DreamAuthor userdets={(data && data.userdets) ? data.userdets : {
                  user_str: data && data.str_author,
                  user_name: "New User"
                }} />
              </Box>
              <Box>
                <Heading as="h4" size="sm">
                  {/* {params.uuid} */}
                </Heading>
                {data && data.timestamp && (() => {
                  return <Text fontSize={"xs"}>
                    {dt(data.timestamp)}
                  </Text>
                })()}
                <Flex alignItems="center">
                  <Heading as="h5" pr="2" size="xs">
                    {(data && data.userdets) ? data.userdets.nickname ? data.userdets.nickname : data.userdets.display_name : "Unknown User"}
                  </Heading>
                  <Button
                    colorScheme='blue'
                    variant='outline'
                    size="xs"
                    onClick={() => {
                      fetch(
                        `${REACT_APP_api_url}/follow/${data.str_author}`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );
                    }}
                  >
                    Follow
                  </Button>
                </Flex>
              </Box>
            </Flex>
            <Wrap alignItems="center">
              <Box>
                <Tooltip hasArrow label="Views">
                  <Button leftIcon={<ViewIcon />} variant={"outline"} size="md" borderRadius={"full"}>{data && data.views}</Button>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip hasArrow label="Like">
                  <Button
                    // isRound
                    isLoading={pinLoading}
                    colorScheme={'pink'}
                    size="md"
                    borderRadius={"full"}
                    onClick={() => {
                      if (!isAuthenticated) {
                        loginWithRedirect()
                      } else {
                        let method = "POST"
                        if (isPinned) {
                          method = "DELETE"
                        } else {
                          method = "POST"
                        }
                        setPinLoading(true)
                        fetch(
                          `${REACT_APP_api_url}/pin/${data._id}`,
                          {
                            method: method,
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify({}),
                          }
                        ).then(r => {
                          if (isPinned) {
                            setIsPinned(false)
                            if (data.pinned) {
                              setDelta(-1)
                            } else {
                              setDelta(0)
                            }
                          } else {
                            setIsPinned(true)
                            if (!data.pinned) {
                              setDelta(+1)
                            } else {
                              setDelta(0)
                            }
                          }
                          setPinLoading(false)
                        })
                      }
                    }}
                    // ml={1}
                    leftIcon={(isPinned) ? <AiFillHeart /> : <AiOutlineHeart />}
                  >{(data && data.likes ? data.likes : 0) + delta}
                  </Button>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip hasArrow label="Share">
                  <IconButton
                    isRound
                    colorScheme={'purple'}
                    size="md"
                    onClick={event => {
                      navigator.clipboard.writeText(shareURL)
                      toast({
                        title: "Share URL copied",
                        description: "A share link has been copied to your clipboard.",
                        status: "success"
                      })
                    }}
                    // ml={1}
                    // isDisabled
                    icon={<AiOutlineShareAlt />}
                  >{hasCopiedURL ? 'URL Copied' : 'Share'}
                  </IconButton>
                </Tooltip>
              </Box>
              {data && data.params &&
                <Box>
                  <IconButton colorScheme={"blue"} isRound icon={<FaPaintBrush />} onClick={e => { navigate(`/${data.architecture}/${data._id}`) }} />
                </Box>
              }
              {data && data.status === 'complete' &&
                <Box>
                  <Tooltip hasArrow label="Download .PNG">
                    <IconButton
                      colorScheme={'green'}
                      borderRadius={"full"}
                      onClick={() => {
                        window.open(data.location.png, "_blank")

                        fetch(data.location.png)
                          .then(response => response.blob())
                          .then(blob => {
                            const blobUrl = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.download = `${data._id}.png`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          });
                      }
                      }
                      ml={1}
                      icon={<DownloadIcon />}
                    />
                  </Tooltip>
                </Box>
              }
            </Wrap>
          </Wrap>
        </Skeleton>
      </HStack>
      {data && (data.status === "complete") &&
        <Center>
          <Box w={1024}>
            <Tabs variant={"solid-rounded"} index={data.selectedTab} onChange={index => {
              let updatedData = JSON.parse(JSON.stringify(data));
              updatedData.selectedTab = index
              setData({ ...data, ...updatedData });
            }}>
              <TabPanels>
                {data.images && data.images.map(image => {
                  return <TabPanel>
                    <Link>
                      <Image
                        onClick={(() => {
                          let url = ""
                          url = `${REACT_APP_images_url}/${data.location.png}`
                          window.open(url, "_blank")
                        })}
                        maxH="1024"
                        m="auto"
                        mt="3"
                        mb="3"
                        borderRadius="lg"
                        objectFit="cover"
                        src={`${REACT_APP_images_url}/${data && data.location && data.location.jpg}`
                        }
                      />
                    </Link>
                  </TabPanel>
                })}
              </TabPanels>
            </Tabs>
          </Box>
        </Center>
      }
    </>}
  </>

}

export default PiecePage;