import {
    FormLabel, Select, Flex, Tooltip, IconButton,
    Slider, SliderTrack, SliderThumb, SliderFilledTrack
} from '@chakra-ui/react';

import { BiLayerPlus, BiLayerMinus } from 'react-icons/bi';
import { defaultIPAdapterLayer } from 'utils/pieceUtils';
import { generateIPAdapterPresetList } from 'utils/pieceUtils';
import { PropBox } from './PropBox';
import { ImageOptions } from './ImageOptions';
import { MaskEditor } from './MaskEditor';

/**
 * Renders the IPAdapterLayerOptions.
 * @param {string} label - The label for the IP Adapter Layer.
 * @param {Object} value - The value for the IP Adapter Layer.
 * @param {number} index - The index of the IP Adapter Layer.
 * @param {Function} onChange - The function to handle changes in the IP Adapter Layer.
 * @param {Function} onDelete - The function to handle deletion of the IP Adapter Layer.
 * @returns {JSX.Element} The rendered IP Adapter Layer options.
 */
export const IPAdapterLayerOptions = ({
    label = "IP Adapter Layer",
    value = defaultIPAdapterLayer(),
    index = 0,
    onChange = () => { console.warn("No IPAdapterLayerOptions onChange handler.") },
    onDelete = () => { console.warn("No IPAdapterLayerOptions onDelete handler.") }
}) => {
    const variant = "ghost"
    const ipAdapterPresetList = generateIPAdapterPresetList()
    const ipAdapterWeightTypeList = [
        { key: "standard", text: "Standard" },
        { key: "prompt is more important", text : "Prompt is more important" },
        { key: "style transfer", text : "Style Transfer" },
    ]

    return <PropBox collapsible={true} type="ipadapter-layer" label={label} value={value} onChange={v => onChange && onChange(v)} extras={[
        // Add Layer
        <Tooltip key="deleteLayer" hasArrow label={`Delete Layer`} openDelay={250}>
            <IconButton icon={<BiLayerMinus />} variant={variant} colorScheme={"red"} onClick={e => { onDelete && onDelete() }} />
        </Tooltip>
    ]}>
        {/* preset */}
        <Flex>
            <Tooltip hasArrow label="Preset" openDelay={250}>
                <FormLabel htmlFor={`ipadapter_preset_${index}`}>IP Adapter Preset</FormLabel>
            </Tooltip>
            <Select id={`ipadapter_preset_${index}`} value={value.preset}
                onChange={e => onChange && onChange({ ...value, preset: e.target.selectedOptions[0].value })} >
                {ipAdapterPresetList.map(model => {
                    let disabled = false
                    if (model.key.indexOf("-") === 0) disabled = true
                    return <option disabled={disabled} key={model.key} value={model.key}>{model.text ? model.text : model.key}</option>
                })}
            </Select>
        </Flex>

        {/* weight_type */}
        <Flex>
            <Tooltip hasArrow label="Weight Type" openDelay={250}>
                <FormLabel htmlFor={`ipadapter_weight_type_${index}`}>Weight Type</FormLabel>
            </Tooltip>
            <Select id={`ipadapter_weight_type_${index}`} value={value.weight_type}
                onChange={e => onChange && onChange({ ...value, weight_type: e.target.selectedOptions[0].value })} >
                {ipAdapterWeightTypeList.map(weightType => {
                    let disabled = false
                    if (weightType.key.indexOf("-") === 0) disabled = true
                    return <option disabled={disabled} key={weightType.key} value={weightType.key}>{weightType.text ? weightType.text : weightType.key}</option>
                })}
            </Select>
        </Flex>

        {/* weight */}
        <Flex w={"full"} direction={"column"}>
            <Tooltip hasArrow label="Weight" openDelay={250} >
                <FormLabel htmlFor={`weight_${index}`}>Weight ({value.weight})</FormLabel>
            </Tooltip>
            <Slider
                id={`weight_${index}`}
                value={value.weight}
                step={0.05}
                min={0}
                max={1.0}
                onChange={v => onChange && onChange({ ...value, weight: v })}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </Flex>

        {/* noise */}
        <Flex w={"full"} direction={"column"}>
            <Tooltip hasArrow label="Noise" openDelay={250}>
                <FormLabel htmlFor={`noise_${index}`}>Noise ({value.noise})</FormLabel>
            </Tooltip>
            <Slider
                id={`noise_${index}`}
                value={value.noise}
                step={0.05}
                min={0}
                max={1.0}
                onChange={v => onChange && onChange({ ...value, noise: v })}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </Flex>

        {/* image */}
        <ImageOptions label={"Reference Image"} value={value.image} onChange={v => { onChange && onChange({ ...value, image: v }) }} hideImage={value.inpaint && value.inpaint.enabled} />

        {/* Inpaint mask */}
        {value.inpaint && value.inpaint.enabled && <MaskEditor bgImage={value.image} mask={value.inpaint.mask} onChange={v => onChange && onChange({ ...value, inpaint: { ...value.inpaint, mask: v } })} />}
    </PropBox>
}

/**
 * Renders the IPAdapterOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the IPAdapterOptions.
 * @param {Function} props.onChange - The function to handle value changes.
 * @returns {JSX.Element} The rendered IPAdapterOptions component.
 */
export const IPAdapterOptions = ({value, onChange}) => {
    return <PropBox type="ipadapter_layer" label="IP-Adapter Options" value={value} onChange={v => onChange&&onChange(v)} extras={[
        <Tooltip key="deleteLayer" hasArrow label={`Add Layer`} openDelay={250}>
            <IconButton icon={<BiLayerPlus />} variant={"ghost"} colorScheme={"green"} onClick={e => {
                let updatedLayers = [...value.layers]
                updatedLayers.push(defaultIPAdapterLayer())
                onChange&&onChange({ ...value, layers: updatedLayers })
            }} />
        </Tooltip>
    ]}>
        {/* layers */}
        {value.layers.map((layer, index) => {
            return <><IPAdapterLayerOptions index={index} value={layer} label={`Layer ${index}`} key={index}
                onDelete={() => {
                    let updatedLayers = [...value.layers]
                    updatedLayers.splice(index, 1)
                    onChange && onChange({ ...value, layers: updatedLayers })
                }}
                onChange={v => {
                    let updatedLayers = [...value.layers]
                    updatedLayers[index] = v
                    onChange && onChange({ ...value, layers: updatedLayers })
                }
                } />
            </>
        })}
    </PropBox>
}