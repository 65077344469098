import {
    Button,
    Modal,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalOverlay,ModalHeader,
    FormControl, FormLabel, Textarea, useToast,
    Input } from '@chakra-ui/react';

import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

export const SaveFilterDialog = ({
    filters,
    isOpen = false,
    onClose = () => { console.warn("No onClose handler for SaveFilterDialog") },
}) => {
    const {token} = useAuth()
    const toast = useToast()
    const initialRef = useRef(null)
    const [newFilter, setNewFilter] = useState({ filters })

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    
    useEffect(()=>{
        setNewFilter(prevState => {
            return {
                ...prevState,
                filters
            };
        });
    },[filters])

    const onAddNewFilter = (e)=>{
        console.log("Adding new filter",newFilter)
        fetch(`${REACT_APP_api_url}/v3/create_filter`,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({newFilter}),
        }).then(response=>{
            return response.json()
        }).then(d=>{
            toast({
                title: `Filter saved.`
            })
            onClose(e)
            
        }).then(
            // lookupWorkItem(data.uuid)
        )
    }

    return <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
            <ModalContent>
                <ModalHeader>Save Filters</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
            <FormControl>
                <FormLabel>Name</FormLabel>
                <Input ref={initialRef} value={newFilter.name} placeholder='Filters name' onChange={e=>{
                    setNewFilter({...newFilter, "name" : e.currentTarget.value})
                }} />
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea placeholder='Description of your filters' value={newFilter.description} onChange={e=>{
                    setNewFilter({...newFilter, "description":e.currentTarget.value})
                }}/>
            </FormControl>
            {JSON.stringify(newFilter)}
        </ModalBody>

        <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={e=>{onAddNewFilter(e)}}>Add</Button>
            <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
        </ModalContent>
    </Modal>
}