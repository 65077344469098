import { useEffect } from 'react';
import { 
    Tab,
    Code,
    TabPanel,
    Tabs,
    TabList,
    TabPanels,
    Box, Badge, Spinner, Table, Link,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Skeleton,
    Text,
    Progress } from '@chakra-ui/react';
import { DreamAuthor } from './DreamAuthor';
import { dt } from 'utils/dateUtils';
import { useNavigate } from 'react-router-dom';

export default function JobStatus({data, loading, subtype}) {
    const navigate = useNavigate()

    useEffect(() => { }, [])
    return <Box width={"full"}>
        {<Tabs mt={5} index={(()=>{
          switch (subtype) {
            case "processing":
              return 0
            case "queued":
              return 1
            default:
              return 2
          }
        })()} onChange={index=>{           
            if(index===0) navigate(`/status/jobs/processing`)
            if(index===1) navigate(`/status/jobs/queued`)
        }}>
        <TabList>
          <Tab>Processing {data && data.processing && data.processing.length>0 && `(${data.processing.length})`} </Tab>
          <Tab>Queued {data && data.queued && data.queued.length>0 && `(${data.queued.length})`} </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading && <Spinner>Loading</Spinner>}
            {!loading && <TableContainer>
              <Table>
                <TableCaption>List of active jobs</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Author</Th>
                    <Th>Job</Th>
                    <Th>Agent/Resolution</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data && data.processing.map((o, i) => {
                      return (
                        <Tr key={o.uuid}>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                                <DreamAuthor userdets={o.userdets} />
                                <Text>{o && o.userdets?o.userdets.nickname:"Unknown"}</Text>
                            </Skeleton>
                          </Td>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                              <Link color="green.500" to={{pathname:`/${o.architecture}/${o.uuid}`}}>
                                <>
                                  <Code>{o.uuid}</Code>
                                </>
                              </Link>
                            </Skeleton>
                            <Skeleton isLoaded={!loading}>
                              {dt(o.timestamp)}
                            </Skeleton>
                            <Skeleton isLoaded={!loading}>
                              <>
                                <Text w={400} noOfLines={4} wordBreak={"break-all"}>{o.text_prompts?o.text_prompts:o.text_prompt}</Text>
                              </>
                            </Skeleton>
                            <Skeleton isLoaded={!loading}>
                              <>
                                {(o.stats && o.stats.progress)?<>
                                  <Progress rounded={"full"} colorScheme={"green"} value={(o.stats.progress.progress*100)} />
                                  <Text fontSize={"xs"}>{o.stats.task} {o.stats.progress.value}/{o.stats.progress.max}
                                  </Text>
                                </>:"Waiting"}
                                <Text fontSize={"xs"}>Processing Time: {o.processingTime?(o.processingTime/1000):""}</Text>
                              </>
                            </Skeleton>
                          </Td>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                              <>
                                <Code>{o && o.agent_id?o.agent_id:"Unknown"}</Code><br/>
                                <Badge variant={"outline"}>{o && o.width_height?`${o.width_height[0]} x ${o.width_height[1]}`:"Unknown"}</Badge>
                              </>
                            </Skeleton>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>}
          </TabPanel>
          <TabPanel>
            {loading && <Spinner>Loading</Spinner>}
            {!loading && <TableContainer>
              <Table variant="simple">
                <TableCaption>List of waiting jobs</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Author</Th>
                    <Th>Priority</Th>
                    <Th>Job</Th>
                    <Th>Resolution</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading && <Spinner>Loading</Spinner>}
                  {data && data.queued.map((o, i) => {
                      let prompt = "Unknown Prompt"
                      if (o.params)
                          prompt = o.params.prompt
                      return (
                        <Tr key={o.uuid}>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                                <DreamAuthor userdets={o.userdets} />
                                <Text>{o && o.userdets?o.userdets.nickname:"Unknown"}</Text>
                            </Skeleton>
                          </Td>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                              <Link color="green.500" to={{pathname:`/${o.architecture}/${o.uuid}`}}>
                                <>
                                  <Badge variant={"subtle"} colorScheme={"blue"} ml={5}>{o && o.priority?o.priority:"Unknown"}</Badge><br/>
                                </>
                              </Link>
                            </Skeleton>
                          </Td>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                              <Link color="green.500" to={{pathname:`/${o.architecture}/${o.uuid}`}}>
                                <>
                                  <Code>{o.uuid}</Code>
                                </>
                              </Link>
                            </Skeleton>
                            <Skeleton isLoaded={!loading}>
                              {o.timestamp?dt(o.timestamp):"?"}
                            </Skeleton>
                            <Skeleton isLoaded={!loading}>
                              <>
                                <Text w={400} noOfLines={4} wordBreak={"break-all"}>{prompt}</Text>
                              </>
                            </Skeleton>
                          </Td>
                          <Td>
                            <Skeleton isLoaded={!loading}>
                              <Badge variant={"outline"}>{o && o.width_height?`${o.width_height[0]} x ${o.width_height[1]}`:"Unknown"}</Badge>
                            </Skeleton>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>}
          </TabPanel>
        </TabPanels>
      </Tabs>}
    </Box>
}