import {
    Box, Switch, FormLabel, Select, Tooltip, Flex,
    Slider, SliderTrack, SliderThumb, SliderFilledTrack,
} from '@chakra-ui/react';
import { generateAfterDetailerList } from 'utils/pieceUtils';
import { ModelOptions} from './ModelOptions'
import { SamplerOptions } from './SamplerOptions'
import { PropBox } from './PropBox'
import { PromptOptions } from './PromptOptions'

/**
 * Renders the FaceDetailerOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the options.
 * @param {Object} props.mainSamplerPipeline - The main sampler pipeline.
 * @param {Function} props.onChange - The function to handle value changes.
 * @returns {JSX.Element} The rendered Face Detailer Options component.
 */
export const FaceDetailerOptions = ({
    value,
    mainSamplerPipeline,
    onChange = () => console.warn("No FaceDetailerOptions onChange handler.")
}) => {

    const adModelList = generateAfterDetailerList()
    const updateValue = v => { onChange && onChange(v) }
    let pipeline = mainSamplerPipeline
    
    if (value.use_main_model_pipeline === false) pipeline = value.model.pipeline

    return value ? <PropBox type="facedetailer" label="Face Detailer Options" value={value} onChange={v => updateValue(v)}>
        {/* detection_model */}
        <Flex w={"full"}>
            <Tooltip hasArrow label="Face Detection Model" openDelay={250} >
                <FormLabel htmlFor="detection_model">Detection Model</FormLabel>
            </Tooltip>
            <Select id="detection_model" value={value.detection_model}
                onChange={e => updateValue({ ...value, "detection_model": e.target.selectedOptions[0].value })}>{
                    adModelList.map(model => {
                        return <option key={model.key} value={model.key}>{model.text ? model.text : model.key}</option>
                    }
                    )}</Select>
        </Flex>
        {/* use_main_sampler */}
        <Flex>
            <Tooltip hasArrow label="Use Main Pass Sampler Settings" openDelay={250} >
                <FormLabel htmlFor="use_main_sampler">Use Main Pass Sampler Settings</FormLabel>
            </Tooltip>
            <Switch
                size={"sm"}
                id="use_main_sampler"
                isChecked={value.use_main_sampler}
                onChange={e => updateValue({ ...value, "use_main_sampler": e.target.checked })}
            />
        </Flex>
        {/* sampler */}
        {value.use_main_sampler === false && <Flex w={"full"}><SamplerOptions pipeline={pipeline} value={value.sampler} onChange={v => { updateValue({ ...value, "sampler": v }) }} /></Flex>}
        {/* use_main_model_pipeline */}
        <Flex>
            <Tooltip hasArrow label="Use Main Pass Model Pipeline" openDelay={250} >
                <FormLabel htmlFor="use_main_model_pipeline">Use Main Pass Model Pipeline</FormLabel>
            </Tooltip>
            <Switch
                size={"sm"}
                id="use_main_model_pipeline"
                isChecked={value.use_main_model_pipeline}
                onChange={e => updateValue({ ...value, "use_main_model_pipeline": e.target.checked })}
            />
        </Flex>
        {/* model */}
        {value.use_main_model_pipeline === false && <Flex w={"full"}><ModelOptions value={value.model} onChange={v => { updateValue({ ...value, "model": v }) }} /></Flex>}
        {/* sampler.denoise */}
        {value.use_main_sampler === true && <Flex w={"full"}>
            <Tooltip hasArrow label="Denoise Strength" openDelay={250} >
                <FormLabel htmlFor="denoise">Denoise Strength ({value.sampler.denoise})</FormLabel>
            </Tooltip>
            <Slider id='denoise' value={value.sampler.denoise} step={0.05} min={0} max={1} onChange={v => updateValue({ ...value, "sampler": { ...value.sampler, "denoise": v } })}>
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </Flex>}
        {/* use_main_prompts */}
        <Flex>
            <Tooltip hasArrow label="Use Main Pass Prompts" openDelay={250} >
                <FormLabel htmlFor="use_main_prompts">Use Main Pass Prompts</FormLabel>
            </Tooltip>
            <Switch
                id="use_main_prompts"
                isChecked={value.use_main_prompts}
                onChange={e => updateValue({ ...value, "use_main_prompts": e.target.checked })}
            />
        </Flex>
        {/* prompts */}
        {value.use_main_prompts === false && <PromptOptions
            value={value.prompts}
            onChange={v => { updateValue({ ...value, prompts: v }) }} />}
        {/* threshold */}
        <Tooltip hasArrow label="" openDelay={250} >
            <FormLabel htmlFor="threshold">Confidence Threshold({value.threshold})</FormLabel>
        </Tooltip>
        <Flex w={"full"} direction={"column"}>
            <Slider id='threshold' value={value.threshold} step={0.05} min={0} max={1} onChange={v => updateValue({ ...value, "threshold": v })}>
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </Flex>
        {/* dilation */}
        <Tooltip hasArrow label="Enlarges/reduces detected mask area." openDelay={250} >
            <FormLabel htmlFor="dilation">Erosion(-)/Dilation(+) ({value.dilation})</FormLabel>
        </Tooltip>
        <Flex w={"full"} direction={"column"}>
            <Slider id='dilation' value={value.dilation} step={1} min={-128} max={128} onChange={v => updateValue({ ...value, "dilation": v })}>
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </Flex>
        {/* mask_blur */}
        <Tooltip hasArrow label="Mask Blur" openDelay={250} >
            <FormLabel htmlFor="mask_blur">Mask Blur ({value.mask_blur})</FormLabel>
        </Tooltip>
        <Flex w={"full"} direction={"column"}>
            <Slider id='mask_blur' value={value.mask_blur} step={1} min={0} max={64} onChange={v => updateValue({ ...value, "mask_blur": v })}>
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </Flex>
        {/* crop_factor */}
        <Tooltip hasArrow label="Crop Factor - Determine how many times the surrounding area should be included in the detail recovery process based on the detected mask area. If this value is small, the restoration may not work well because the surrounding context cannot be known." openDelay={250} >
            <FormLabel htmlFor="crop_factor">Crop Factor ({value.crop_factor})</FormLabel>
        </Tooltip>
        <Flex w={"full"} direction={"column"}>
            <Slider id='crop_factor' value={value.crop_factor} step={0.25} min={1.0} max={5.0} onChange={v => updateValue({ ...value, "crop_factor": v })}>
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </Flex>
    </PropBox> : <Box>Loading...</Box>
}