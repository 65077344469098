// import { ButtonGroup, Progress, IconButton, Spacer, Tooltip } from '@chakra-ui/react';
// import { WorkspaceFilter } from './WorkspaceFilter';
// import { AiOutlineReload, AiOutlineDelete, AiOutlineClear } from 'react-icons/ai'

/**
 * Renders the WorkshopGridToolbar component.
 *
 * @param {boolean} isFetchingItems - Indicates if items are being fetched.
 * @param {boolean} isClearing - Indicates if clearing is in progress.
 * @param {boolean} isDeleting - Indicates if deleting is in progress.
 * @param {Object} filters - The filters object.
 * @param {Array} filters.source - The source filter array.
 * @param {Function} onFilterChange - The handler for filter change event.
 * @param {Function} onClearFailedJobs - The handler for clear failed jobs event.
 * @param {Function} onEmptyWorkspace - The handler for empty workspace event.
 * @returns {JSX.Element} The rendered WorkshopGridToolbar component.
 */
export const WorkshopGridToolbar = ({ 
  isFetchingItems = false,
  isClearing = false,
  isDeleting = false,
  filters = {source:[]},
  onFilterChange = () => {console.warn("No onFilterChange handler found for WorkshopGridToolbar.")},
  onClearFailedJobs = () => {console.warn("No onClearFailedJobs handler found for WorkshopGridToolbar.")},
  onEmptyWorkspace = () => {console.warn("No onEmptyWorkspace handler found for WorkshopGridToolbar.")}
}) => {
  return <></>
  // const variant = "ghost"
  // return !isFetchingItems ? <ButtonGroup w={"full"}>
  //   <WorkspaceFilter variant={variant} value={filters.source} onChange={v => { onFilterChange({...filters, source: v})}} />
  //   <Spacer />
  // </ButtonGroup> : <ButtonGroup w={"full"} alignItems={"center"}>
  //   <IconButton disabled variant={"unstyled"}/>
  //   <Tooltip hasArrow label={`Loading...`}>
  //     <Progress size='xs' isIndeterminate flex={1} />
  //   </Tooltip>
  //   <IconButton disabled variant={"unstyled"}/>
  // </ButtonGroup>
}