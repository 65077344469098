import React from 'react';
import { useEffect } from 'react';
import { Text, VStack, HStack, Avatar, Heading, Badge, Wrap, WrapItem } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import PngInfo from 'components/shared/PngInfo'


function MyProfile(){
  const { user, isAuthenticated, token, permissions } = useAuth()
  useEffect(() => {

  }, [token]);

  let userAvatar = '/avatar-placeholder.png'
  let userName = 'Loading'

  if(user){
    userAvatar = user.picture
    userName = user.nickname
  }
  return (
    <>
      {isAuthenticated && (
        <HStack pl={5}>
          {user && <Avatar size="xl" src={userAvatar} />}
          <VStack alignItems={'left'}>
            <Heading>{userName}</Heading>
            <Heading size={'md'}>Permissions</Heading>
            <Wrap>
              {permissions &&
                permissions.map((permission) => {
                  return (
                    <WrapItem key={permission}>
                      <Badge variant={'outline'} colorScheme={'green'}>
                        {permission}
                      </Badge>
                    </WrapItem>
                  );
                })}
            </Wrap>
            <PngInfo />
          </VStack>
        </HStack>
      )}
      {!isAuthenticated && <Text>To manage your profile, log in.</Text>}
    </>
  );
}

export default MyProfile;