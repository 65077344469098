import React, { useRef, useState } from 'react';

import {
    Button,Modal,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalOverlay,ModalHeader,
    FormControl, FormLabel, useToast, Input
} from '@chakra-ui/react';

import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

/**
 * SavePresetDialog component for saving presets.
 * 
 * @component
 * 
 * @param {string} type - The type of the preset.
 * @param {any} value - The value of the preset.
 * @param {boolean} [isOpen=false] - Whether the dialog is open or not.
 * @param {Function} [onSave=()=>{console.warn("No onSave handler for SavePresetDialog")}] - The function to be called when the preset is saved.
 * @param {Function} [onClose=()=>{console.warn("No onClose handler for SavePresetDialog")}] - The function to be called when the dialog is closed.
 * 
 * @returns {JSX.Element} The SavePresetDialog component.
 */
export const SavePresetDialog = ({
    type,
    value,
    isOpen = false,
    onSave = ()=>{console.warn("No onSave handler for SavePresetDialog")},
    onClose = ()=>{console.warn("No onClose handler for SavePresetDialog")}
}) => {
    const {token} = useAuth()
    const toast = useToast()
    const initialRef = useRef(null)
    const [name, setName] = useState("")
    const [fetching, setFetching] = useState(false)
    
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    const handleOnSave = (e)=>{
        setFetching(true)
        let saveValue = JSON.parse(JSON.stringify(value))
        if(type==="image" && saveValue.content) delete saveValue.content
        fetch(`${REACT_APP_api_url}/savepreset`,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({name,type, value : saveValue}),
        }).then(response=>{
            return response.json()
        }).then(d=>{
            setFetching(false)
            toast({
                title: `Preset saved.`
            })
            onSave()
            onClose(e)            
        }).then(
            // lookupWorkItem(data.uuid)
        )
    }

    return <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
            <ModalContent>
                <ModalHeader>Save Preset</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
            <FormControl>
                <FormLabel>Name</FormLabel>
                <Input ref={initialRef} value={name} placeholder='Preset Name' onChange={e=>{
                    setName(e.currentTarget.value)
                }} />
            </FormControl>
            {/* {JSON.stringify(value)} */}
        </ModalBody>

        <ModalFooter>
            <Button isLoading={fetching} colorScheme='blue' mr={3} onClick={e=>{ handleOnSave(e) }}>Save</Button>
            <Button isLoading={fetching} onClick={onClose}>Cancel</Button>
        </ModalFooter>
        </ModalContent>
    </Modal>
}