import React from 'react';
import { Tooltip, IconButton, useColorModeValue } from '@chakra-ui/react';

/**
 * Renders the SocialButton component.
 *
 * @param {ReactNode} children - The icon element to be displayed inside the button.
 * @param {string} label - The label for the tooltip.
 * @param {string} href - The URL to navigate to when the button is clicked.
 * @returns {ReactNode} The rendered social button component.
 */
export function SocialButton({ children, label, href }) {
  return <Tooltip label={label}><IconButton
    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
    rounded={'full'}
    cursor={'pointer'}
    as={'a'}
    href={href}
    target={'_blank'}
    transition={'background 0.3s ease'}
    _hover={{
      bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
    }}
    icon={children}
  >
  </IconButton>
  </Tooltip>
};