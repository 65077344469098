import { Tooltip, ButtonGroup, IconButton, Progress, Spacer, useToast } from '@chakra-ui/react';
import { AiOutlineDelete, AiOutlineReload } from 'react-icons/ai'
import { RiGitRepositoryPrivateLine } from 'react-icons/ri'
import { TfiGallery } from "react-icons/tfi";
import { TiCancel } from 'react-icons/ti'
import { BiShow, BiHide } from 'react-icons/bi'
import { useAuth } from "../../contexts/AuthContext";

/**
 * Renders the WorkshopPreviewToolbar component.
 *
 * @component
 * @param {Object} data - The data object.
 * @param {boolean} isLoaded - Indicates if the component is loaded.
 * @param {Function} [onChange] - The onChange event handler.
 * @param {Function} [onDelete] - The onDelete event handler.
 * @param {Function} [onCancel] - The onCancel event handler.
 * @param {Function} [onRetry] - The onRetry event handler.
 * @param {Function} [onPublish] - The onPublish event handler.
 * @param {Function} [onPersonalPublish] - The onPersonalPublish event handler.
 * @returns {JSX.Element} The rendered WorkshopPreviewToolbar component.
 */
export const WorkshopPreviewToolbar = ({
    data,
    onSetPrivate,
    isLoaded,
    onChange = () => { console.warn("No onChange handler for WorkshopPreviewToolbar.") },
    onDelete = () => { console.warn("No onDelete handler for WorkshopPreviewToolbar.") },
    onPublish = () => { console.warn("No onPublish handler for WorkshopPreviewToolbar.") },
    onPersonalPublish = () => { console.warn("No onPersonalPublish handler for WorkshopPreviewToolbar.") },
}) => {
    const { permissions, user } = useAuth()
    const toast = useToast()
    const variant = "ghost"

    return isLoaded ? <ButtonGroup w={"100%"}>
        {data.status !=="new" && <>
            {/* PRIVATE */}
            {data.author === user.sub && <Tooltip hasArrow label={data.private ? "Parameters are hidden." : "Parameters are visible."} >
                <IconButton icon={data.private ? <BiHide /> : <BiShow />} variant={variant} colorScheme={"green"}
                    onClick={e => { onSetPrivate && onSetPrivate(data, !data.private) }} />
            </Tooltip>}
            {/* PUBLISH TO GALLERY */}
            {data.source === "reviews" &&
                <>
                    <Tooltip hasArrow label="Publish to Gallery">
                        <IconButton variant={variant} colorScheme={"green"} icon={<TfiGallery />} onClick={e => { onPublish && onPublish(data) }} />
                    </Tooltip>
                    {permissions.includes("supporter") && <Tooltip hasArrow label="Save to Private Gallery">
                        <IconButton variant={variant} icon={<RiGitRepositoryPrivateLine />} colorScheme={"green"} onClick={e => { onPersonalPublish && onPersonalPublish(data) }} />
                    </Tooltip>}
                </>}

            <Spacer />
            {/* DELETE */}
            {data.source !== "queue" && <Tooltip hasArrow label="Delete">
                <IconButton icon={<AiOutlineDelete />} variant={variant} colorScheme={"red"} onClick={e => { onDelete && onDelete(data) }} />
            </Tooltip>}
        </>}
    </ButtonGroup > : <ButtonGroup w={"full"} alignItems={"center"}>
        <IconButton disabled variant={"unstyled"} />
        <Tooltip hasArrow label={`Loading...`}>
            <Progress size='xs' isIndeterminate flex={1} />
        </Tooltip>
        <IconButton disabled variant={"unstyled"} />
    </ButtonGroup>
}