import {
    FormLabel, Select, Tooltip, Flex, Switch, Wrap, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper
} from '@chakra-ui/react';
import { PromptOptions } from './PromptOptions';
import { SizeOptions } from './SizeOptions';
import { SamplerOptions } from './SamplerOptions'
import { FluxModelOptions } from './FluxModelOptions';
import { PropBox } from './PropBox';

/**
 * Renders the MainPassOptions component.
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the component.
 * @param {Function} props.onChange - The function to handle value changes.
 * @returns {JSX.Element} The rendered main pass options component.
 */
export const FluxMainPassOptions = ({
    value,
    architecture = "stable-diffusion",
    onChange = () => { console.warn("FluxMainPassOptions.onChange not implemented.") }
}) => {
    let resolutions = [
        { w: 512, h: 512, d: "1:1 (Square)" },
        { w: 576, h: 448, d: "4:3 (Standard Monitor)" },
        { w: 608, h: 416, d: "3:2 (Classic Film)" },
        { w: 672, h: 384, d: "16:9 (HD Video)" },
        { w: 768, h: 320, d: "21:9 (Cinemascope)" },
        { w: 448, h: 576, d: "3:4 (Standard Monitor - Vertical)" },
        { w: 416, h: 608, d: "2:3 (Classic Film - Vertical)" },
        { w: 384, h: 672, d: "9:16 (HD Video - Vertical)" },
        { w: 320, h: 768, d: "9:21 (Cinemascope - Vertical)" }
    ]

    resolutions.forEach(r => {
        r.w = r.w * 2
        r.h = r.h * 2
    })

    return <PropBox label="Main Pass" type="fluxMainPass" value={value} onChange={v => onChange(v)}>
        {/* img2img */}
        <Flex>
            <Tooltip hasArrow label="img2img" openDelay={250}>
                <FormLabel htmlFor="img2img">Enable img2img</FormLabel>
            </Tooltip>
            <Switch
                id="img2img"
                isChecked={value.img2img.enabled}
                onChange={e => onChange({ ...value, img2img: { ...value.img2img, enabled: e.target.checked } })}
            />
        </Flex>
        {!value?.img2img?.enabled && <>
            {/* Quick Resolution Picker */}
            <Flex>
                <Tooltip hasArrow label="Recommended Resolutions - These are the recommended resolutions.  You can customize the width and height beyond these aspect ratios if you wish, however going too far may lead to doubles of your subject." openDelay={250}>
                    <FormLabel>Resolution Picker</FormLabel>
                </Tooltip>
                <Select onChange={e => {
                    let r = resolutions[e.currentTarget.value]
                    onChange({ ...value, "width": r.w, "height": r.h })
                }}>
                    {resolutions.map((resolution, i) => {
                        return <option value={i} key={`${resolution.w}x${resolution.h}`}>{resolution.w}x{resolution.h} ({resolution.d})</option>
                    })}
                </Select>
            </Flex>
            {/* Width/Height Controls */}
            <SizeOptions value={value} onChange={v => { onChange({ ...value, width: v.width, height: v.height }) }} />
        </>}
        {/* prompts */}
        <PromptOptions
            includeNegative={false}
            value={value.prompts}
            onChange={v => { onChange({ ...value, prompts: v }) }} />
        {/* CLIP guidance */}
        <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="CLIP Guidance" openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"base_shift"} >CLIP Guidance ({value.clip_guidance})</FormLabel>
                </Tooltip>
            </Flex>
            <NumberInput
                id="clip_guidance"
                value={value.clip_guidance}
                min={0}
                max={10}
                step={0.1}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "clip_guidance": v })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Wrap>
        {/* Sampler */}
        <SamplerOptions
            architecture={architecture}
            pipeline={"flux"}
            value={value.sampler}
            onChange={v => { onChange({ ...value, sampler: v }) }} />
         {/* Model */}
         <FluxModelOptions value={value.model} onChange={v => { onChange({ ...value, model: v }) }} />
    </PropBox>
}