import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { Heading, Tab, TabPanel, Tabs, TabList, TabPanels } from '@chakra-ui/react';
import GpuStatus from 'components/shared/GpuStatus';
import JobStatus from 'components/shared/JobStatus';
import { getEnvVariable } from 'utils/env';

export default function StatusPage() {

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    
    const {token } = useAuth()
    const navigate = useNavigate()
    const params = useParams()
    const [gpuStatus, setGpuStatus] = useState([])
    const [jobStatus, setJobStatus] = useState({
        processing:[],
        queued:[],
        backlog:[]
    })
    const [myJobStatus, setMyJobStatus] = useState({
        jobs:[]
    })
    const [loading, setLoading] = useState(false)
    
    let subtype = "processing"
    let type = "gpu"

    if (params.subtype) subtype = params.subtype
    if (params.type) type = params.type

    const tabMap = [
        {name:"gpu",label:"GPU Status",component:<GpuStatus data={gpuStatus} loading={loading}/>},
        {name:"jobs",label:"Job Status",component:<JobStatus data={jobStatus} loading={loading} subtype={subtype}/>}
    ]
    // if(token) tabMap.push({name:"myjobs",label:"My Jobs",component:<MyJobStatus data={myJobStatus} loading={loading}/>})
    
    let si = 0

    tabMap.map((tab,index)=>{
        if(tab.name===type) {
            si = index
        }
    })

    function fetchMyJobs(){
        setLoading(true);
        const apiURL = `${REACT_APP_api_url}/v3/myjobs/queued/1`
        let active = fetch(apiURL,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            return response.json()
        }).then((actualData) => {
            return actualData.sort((a, b) => {
                const priorityOrder = { high: 1, medium: 2, low: 3 };
                console.log(a.priority)
                return priorityOrder[a.priority] - priorityOrder[b.priority];
            })
        })

        Promise.all([active]).then((data) => {
            setMyJobStatus({
                jobs: data[0]
            })
            setLoading(false);
        })
    }

    function fetchJobStatus() {
        setLoading(true);
        let apiURL = `${REACT_APP_api_url}/v3/public_queue/${subtype}`
        console.log(apiURL)
        fetch(apiURL).then((response) => {
            return response.json();
        }).then((actualData) => {
            let updatedData = JSON.parse(JSON.stringify(jobStatus))
            updatedData[subtype] = actualData.sort((a, b) => {
                const priorityOrder = { high: 1, medium: 2, low: 3 };
                return priorityOrder[a.priority] - priorityOrder[b.priority];
            })
            setJobStatus({ ...jobStatus, ...updatedData });
            setLoading(false)
        });
    }
    function fetchGpuStatus() {
        setLoading(true)
        fetch(`${REACT_APP_api_url}/agentstats`).then((response) => {
            return response.json();
        }).then((actualData) => {
            setGpuStatus(actualData);
            setLoading(false);
        })
    }
    
    useEffect(()=>{
        if(type==="gpu") fetchGpuStatus()
        if(type==="jobs") fetchJobStatus()
        if(type==="myjobs") fetchMyJobs()
    },[params.type, params.subtype])
    
    useEffect(()=>{ },[gpuStatus])

    return (
    <>
    <Heading>Status</Heading>
    <Tabs mt={5} index={si} onChange={index=>{
        if(tabMap[index].name==="jobs"){
            navigate(`/status/${tabMap[index].name}/${subtype}`)
        }else{
            navigate(`/status/${tabMap[index].name}`)
        }
    }}>
        <TabList>{tabMap.map(tab=><Tab>{tab.label}</Tab>)}</TabList>
        <TabPanels>{tabMap.map(tab=><TabPanel>{tab.component}</TabPanel>)}</TabPanels>
    </Tabs>
    </>)
}