import { Box, Center, Spinner, Button, Flex, Heading, Image, Tag, Text, Wrap, useDisclosure } from '@chakra-ui/react'
import { getEnvVariable } from 'utils/env'
import { useAuth } from 'contexts/AuthContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ModalModelInfo } from 'components/Models/ModalModelInfo'
import { ModelPreview } from './ModelPreview'
import { useMemo } from 'react'

export default function ModelFeed({
    filters: passedFilters,
    sort = {
        direction: 'desc',
        by: 'date'
    },
    onSelect
    }) {
    
    // Merge default filters with passed filters
    const filters = useMemo(() => {
        return {
        ...{
            type: [],
            baseModel: [],
            name: "",
            alsoDescription: false,
            includeNsfw: false
        },
        ...passedFilters
    }}, [passedFilters]);
    
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    const { token } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedHash, setSelectedHash] = useState(null)

    const [fetching, setFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const [activeImages, setActiveImages] = useState({})
    const [lastHash, setLastHash] = useState(null)
    let path = '/v3/modelbrowse'
    let apiURL = `${REACT_APP_api_url}${path}`
    const sentinelRef = useRef(null);

    const fetchNewItems = useCallback(
        function (useLastHash = false) {
            setFetching(true)
            let headers = { 'Content-Type': 'application/json' };
            if (token) {
                headers = { ...headers, Authorization: `Bearer ${token}` };
            } else {
                console.log('Not logged in');
            }

            let method = 'POST';
            let body = JSON.stringify({filters, sort});
            let url = `${apiURL}${lastHash?`/${lastHash._id}`:""}`
            // if(!useLastHash) url = `${apiURL}`
            // alert(url)
            return fetch(url, { method, headers, body }).then((response) => response.json())
        },
        [apiURL, filters, sort, token, lastHash]
    );

    useEffect(() => {
        const sentinel = sentinelRef.current;
        const observer = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.target === sentinelRef.current && entry.isIntersecting && !fetching && hasMore) {
                    fetchNewItems(true).then((newItems) => {
                        if (newItems.length === 0) {
                            setHasMore(false);
                        } else {
                            setItems((prevItems) => [
                                ...prevItems,
                                ...newItems.filter(
                                    (newItem) =>
                                        !prevItems.some((prevItem) => prevItem._id === newItem._id)
                                ),
                            ])
                            setLastHash(newItems[newItems.length - 1]);
                        }
                        setFetching(false);
                    }).catch((err) => {
                        console.log(err);
                        setFetching(false);
                    });
                }
            });
        },
            {
                threshold: 0.2,
                rootMargin: '500px',
            }
        );
        observer.observe(sentinel)
        // cleanup function to remove the observer when the component unmounts
        return () => {
            observer.unobserve(sentinel);
        };
    }, [fetchNewItems, fetching, hasMore]);

    // useEffect(() => {
    //     setLastHash(null)
    //     setItems([])
    //     setHasMore(true)
    // }, [
    //     filters,
    //     sort
    // ]);

    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            const imgId = entry.target.dataset.id

            if (entry.isIntersecting) {
                setActiveImages(prevState => ({ ...prevState, [imgId]: true }))
            } else {
                setActiveImages(prevState => {
                    const newState = { ...prevState }
                    delete newState[imgId]
                    return newState
                })
            }
        })
    }

    useEffect(() => {
        const imageNodes = document.querySelectorAll('.infinitygallery > div')
        const imageObserver = new IntersectionObserver(observerCallback)
        imageNodes.forEach(node => imageObserver.observe(node))
        return () => imageNodes.forEach(node => imageObserver.unobserve(node))
    }, [items])

    const selectHandler = (hash) => {
        if(onSelect) {
            return onSelect(hash)
        }else{
            setSelectedHash(hash)
            onOpen()
        }
    }

    return <>
        <ModalModelInfo isOpen={isOpen} onClose={onClose} hash={selectedHash} onRelatedSelect={selectHandler}/>
        <Box className='infinitygallery'>
            {items.map(piece => {
                let pieceClass = "square"
                return <div
                    style={{ height: `250px` }}
                    className={`${pieceClass} fade-in ${activeImages[piece._id] ? "visible" : "unset"}`}
                    data-id={piece._id}
                    key={piece._id}>
                    {activeImages[piece._id] && <ModelPreview model={piece} onClick={selectHandler} />}
                    {!activeImages[piece._id] && <Box pos="relative" borderRadius="lg" bg={"blue.800"} />}
                </div>
            })}
        </Box>
        <div ref={sentinelRef} style={{ width: '100%' }}>
            <Center p={5}>{fetching && <Spinner label="Loading more..." />}</Center>
            {!hasMore && <Center p={5}>No more items</Center>}
        </div>
    </>
}

