import { Box, Wrap, FormLabel, Select, Tooltip, Flex, Input } from '@chakra-ui/react';
import { generateRoopModelList } from 'utils/pieceUtils';
import { PropBox } from './PropBox';
import { ImageOptions } from './ImageOptions';

/**
 * Renders the RoopOptions component.
 *
 * @component
 * @param {Object} value - The current value of the RoopOptions.
 * @param {Function} onChange - The onChange event handler for RoopOptions.
 * @returns {JSX.Element} The rendered RoopOptions component.
 */
export const RoopOptions = ({
    value,
    onChange = () => { console.warn("No RoopOptions onChange hander.") }
}) => {

    const roopModels = generateRoopModelList()

    return value !== undefined ? <PropBox type="roop" label="Roop Options" value={value} onChange={v => onChange && onChange(v)}>

        {/* model */}
        <Flex w={"full"}>
            <Tooltip hasArrow label="Model" openDelay={250}>
                <FormLabel htmlFor="model">Model</FormLabel>
            </Tooltip>
            <Select id="model" value={value.model}
                onChange={e => onChange && onChange({ ...value, model: e.target.selectedOptions[0].value })}>{
                    roopModels.map(model => {
                        return <option key={model.key} value={model.key}>{model.text ? model.text : model.key}</option>
                    }
                    )}</Select>
        </Flex>

        {/* facenumbers */}
        <Wrap w={"full"}>
            <Tooltip hasArrow label="Face Numbers" openDelay={250}>
                <FormLabel htmlFor="facenumbers">Face Numbers</FormLabel>
            </Tooltip>
            <Input id="facenumbers" value={value.facenumbers}
                onChange={e => onChange && onChange({ ...value, facenumbers: e.target.value })} />
        </Wrap>

        {/* image */}
        <ImageOptions label={"Reference Image"} value={value.image} onChange={v => { onChange && onChange({ ...value, image: v }) }} />
    </PropBox> : <Box>Loading...</Box>
}