import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useToast
} from '@chakra-ui/react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEnvVariable } from 'utils/env';

export const CaptiveMessage = (props) => {
  
  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
  
  const navigate = useNavigate()
  let {title, message} = props
  const toast = useToast()
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <Modal isOpen={props.disclosure.isOpen} onClose={()=>{navigate('/')}}>
      <ModalOverlay bg='none'
        backdropFilter='auto'
        backdropInvert='10%'
        backdropBlur='10px'/>
      <ModalContent>
        <ModalHeader>{title}</ModalHeader><ModalCloseButton />
        <ModalBody>
          {message}
          <Input placeholder='Enter code' value={code} onChange={e=>{
              setCode(e.target.value)
          }}></Input>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' isLoading={loading} colorScheme={"green"} onClick={e=>{
              setLoading(true)
              fetch(`${REACT_APP_api_url}/redeeminvite`,{
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${props.token}`,
                  },
                  body: JSON.stringify({ invite_code: code }),
              }).then((response) => {
                  return response.json();
              }).then((data) => {
                  setLoading(false)
                  if(data.success){
                      toast({
                          title: "Welcome!",
                          description: data.message,
                          status: "success"
                      })
                      props.disclosure.onClose()
                  }else{
                      toast({
                          title: "Error",
                          description: data.message,
                          status: "error"
                      })
                  }
                  return data;
              });
              }}>Submit</Button>
          <Button variant='ghost' colorScheme='red' mr={3} onClick={()=>{navigate('/')}}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};