import {Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from "@chakra-ui/react"
import ModelBrowser from "./ModelBrowser"

export const ModalModelBrowser = ({
    isOpen,
    onClose,
    hide,
    modelArchitecture=["sd-1.x"],
    modelType=["LORA"],
    onSelect
}) => {   
    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={"4xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Model Browser</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ModelBrowser onSelect={onSelect} hide={hide} filters={{
                        type: modelType,
                        baseModel: modelArchitecture,
                        name: "",
                        alsoDescription: false,
                        includeNsfw: false,
                        includeTrash: false,
                        includeOffline: false
                    }}/>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}