/**
 * Retrieves the value of an environment variable.
 * 
 * @param {string} key - The name of the environment variable.
 * @param {any} defaultValue - The default value to return if the environment variable is not found.
 * @returns {any} The value of the environment variable, or the default value if not found.
 */
export function getEnvVariable(key, defaultValue) {
    // First, try to get the value from window.env (for production)
    if (window.env && window.env[key]) {
        return window.env[key];
    }

    // Fallback to process.env (for development)
    return defaultValue;
}