import { IconButton } from '@chakra-ui/react';
import { BsChevronUp } from 'react-icons/bs';
export function BackToTop({ scrollPosition, onGoToTop = () => { console.warn(`No GoToTop handler`) } }) {
    return (
        <>
            {scrollPosition > 150 && <IconButton isRound={"full"} size={"sm"} icon={<BsChevronUp />}
                // style={{
                //     backdropFilter: "blur(10px)",
                //     backgroundColor: "rgba(0,60,220,0.4)"
                // }}
                onClick={e => { onGoToTop() }} />}
        </>
    )
}