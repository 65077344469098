import {
    FormLabel, Select, Flex, Tooltip, IconButton,
    Slider, SliderTrack, SliderThumb, SliderFilledTrack
} from '@chakra-ui/react';

import { BiLayerPlus, BiLayerMinus } from 'react-icons/bi';
import { ImageOptions } from './ImageOptions';
import { PropBox } from './PropBox';
import { generateCnTypeList, generateCnUnionPreprocessorList, defaultControlNetUnionLayer } from 'utils/fluxUtils';

/**
 * Renders the options for a ControlNet layer.
 *
 * @param {Object} value - The current value of the ControlNet layer.
 * @param {number} index - The index of the ControlNet layer.
 * @param {string} label - The label for the ControlNet layer.
 * @param {Function} onChange - The function to handle value changes.
 * @param {Function} onDelete - The function to handle layer deletion.
 * @returns {JSX.Element} The rendered ControlNet layer options.
 */
export const ControlNetUnionLayerOptions = ({
    value,
    index,
    label,
    onChange = () => console.warn("No onChange handler for ControlNetUnionLayerOptions"),
    onDelete = () => console.warn("No onDelete handler for ControlNetUnionLayerOptions")
}) => {

    const variant = "ghost"
    const cnPreprocessorList = generateCnUnionPreprocessorList()
    const cnUnionTypes = generateCnTypeList()

    return <PropBox type="controlnet-layer" label={label} value={value} onChange={onChange} extras={[
        // Add Layer
        <Tooltip key="deleteLayer" hasArrow label={`Delete Layer`} openDelay={250} >
            <IconButton icon={<BiLayerMinus />} variant={variant} colorScheme={"red"} onClick={onDelete} />
        </Tooltip>
    ]}>

        {/* preprocessor */}
        <Flex>
            <Tooltip hasArrow label="ControlNet Preprocessors each detect features in your reference image in different ways.  Go watch a YT video on ControlNet to learn more.  This tooltip is too small to explain." openDelay={250} >
                <FormLabel htmlFor={`preprocessor_${index}`}>Preprocessor</FormLabel>
            </Tooltip>
            <Select id={`preprocessor_${index}`} value={value.preprocessor}
                onChange={e => onChange({ ...value, preprocessor: e.target.selectedOptions[0].value })} >
                {cnPreprocessorList.map(preprocessor => {
                    return <option key={preprocessor.key} value={preprocessor.key}>{preprocessor.text ? preprocessor.text : preprocessor.key}</option>
                })}
            </Select>
        </Flex>

        {/* type */}
        <Flex>
            <Tooltip hasArrow label="ControlNet Union Type" openDelay={250} >
                <FormLabel htmlFor={`type_${index}`}>Type</FormLabel>
            </Tooltip>
            <Select id={`type_${index}`} value={value.type}
                onChange={e => onChange({ ...value, type: e.target.selectedOptions[0].value })} >
                {cnUnionTypes.map(type => {
                    let disabled = (type.key.indexOf("-") === 0)?true:false
                    return <option disabled={disabled} key={type.key} value={type.key}>{type.text ? type.text : type.key}</option>
                })}
            </Select>
        </Flex>

        {/* strength */}
        <Flex w={"full"} direction={"column"}>
            <Tooltip hasArrow label="ControlNet Strength controls how strongly the reference image's features are adhered to.  For some variety, try values lower than 1." openDelay={250} >
                <FormLabel htmlFor="strength">Strength ({value.strength})</FormLabel>
            </Tooltip>
            <Slider
                id='controlnet_weight'
                value={value.strength}
                step={0.05}
                min={0}
                max={1.0}
                onChange={v => onChange({ ...value, strength: v })}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </Flex>
        
        {/* start_percent */}
        <Flex w={"full"} direction={"column"}>
            <Tooltip hasArrow label="Start Percent" openDelay={250} >
                <FormLabel htmlFor="start_percent">Start Percent ({value.start_percent})</FormLabel>
            </Tooltip>
            <Slider
                id='controlnet_start_percent'
                value={value.start_percent}
                step={0.05}
                min={0}
                max={1.0}
                onChange={v => onChange({ ...value, start_percent: v })}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </Flex>

        {/* end_percent */}
        <Flex w={"full"} direction={"column"}>
            <Tooltip hasArrow label="End Percent" openDelay={250} >
                <FormLabel htmlFor="end_percent">End Percent ({value.end_percent})</FormLabel>
            </Tooltip>
            <Slider
                id='controlnet_end_percent'
                value={value.end_percent}
                step={0.05}
                min={0}
                max={1.0}
                onChange={v => onChange({ ...value, end_percent: v })}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </Flex> 

        {/* image */}
        <ImageOptions label={"Reference Image"} value={value.image} onChange={v => { onChange({ ...value, image: v }) }} />
    </PropBox>
}

/**
 * Renders the ControlNetUnionOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {any} props.value - The current value of the ControlNetOptions.
 * @param {Function} props.onChange - The function to call when the value of ControlNetOptions changes.
 * @returns {JSX.Element} The rendered ControlNetUnionOptions component.
 */
export const ControlNetUnionOptions = ({ value, onChange }) => {

    return <PropBox type="controlnet" label="ControlNet Union Options" value={value} onChange={v => onChange(v)} extras={[
        <Tooltip hasArrow label={`Add Layer`} openDelay={250} >
            <IconButton icon={<BiLayerPlus />} variant={"ghost"} colorScheme={"green"} onClick={e => {
                let updatedLayers = [...value.layers]
                updatedLayers.push(defaultControlNetUnionLayer())
                onChange({ ...value, layers: updatedLayers })
            }} />
        </Tooltip>
    ]}>
        {/* layers */}
        {value.layers.map((layer, index) => {
            return <><ControlNetUnionLayerOptions index={index} value={layer} label={`Layer ${index}`} key={index}
                onDelete={() => {
                    let updatedLayers = [...value.layers]
                    updatedLayers.splice(index, 1)
                    onChange({ ...value, layers: updatedLayers })
                }}
                onChange={v => {
                    let updatedLayers = [...value.layers]
                    updatedLayers[index] = v
                    onChange({ ...value, layers: updatedLayers })
                }
                } />
            </>
        })}
    </PropBox>
}
