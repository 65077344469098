import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Stack } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { getEnvVariable } from 'utils/env';
import { useUser } from 'contexts/UserContext';
import InfinityFeed from 'components/shared/Feed/InfinityFeed';
import FileDropZone from 'components/shared/FileDropZone';
import { calculateBlobSHA256 } from 'utils/pieceUtils';
import { tagUpload } from 'utils/api';
import {
    Badge, Button, Input, Textarea, useDisclosure, Select,
    Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter,
    FormLabel, FormControl, useToast, Wrap
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

export default function Datasets(props) {
    const params = useParams()
    const navigate = useNavigate()
    const [r, setR] = useState(0)
    const { user, token } = useAuth()
    const { datasets, refresh } = useUser()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const toast = useToast()
    let [newDataset, setNewDataset] = useState({
        name: "",
        description: "",
        private: false
    })

    let [fetching, setFetching] = useState(false)
    let filters = {
        // tags : {$exists : true}
    }
    if (params.id) {
        filters = { ...filters, tags: { $in: [params.id] } }
    }


    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    const onAddNewDataset = (e) => {
        setFetching(true)
        fetch(`${REACT_APP_api_url}/v3/create_dataset`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ newDataset }),
        }).then(response => {
            return response.json()
        }).then(d => {
            setFetching(false)
            toast({
                title: `Dataset created.`
            })
            refresh()
            onClose(e)
        }).then(
            // lookupWorkItem(data.uuid)
        )
    }


    return <>
        {/* <Heading>Datasets</Heading> */}
        {user && user.sub && <>
            <Stack mb={4} direction='row' align='center'>
                {datasets.length === 0 && <p>No datasets exist.  Create one to get started.</p>}
                {datasets.length > 0 && <>
                    <Select size="md" onChange={e => {
                        navigate(`/train/datasets/${e.target.value}`);
                    }}>
                        <option value="">All datasets</option>
                        {datasets.map(dataset => <option selected={params.id === dataset._id} key={dataset._id} value={dataset._id}>{dataset.name}{(dataset?.description)?` - ${dataset.description}`:""}</option>)}
                    </Select>
                </>}
                {false && datasets.length > 0 && <Wrap>
                    {datasets.map(dataset => <Badge colorScheme={(params.id === dataset._id)?"green":"grey"} key={dataset._id} value={dataset._id}>{dataset.name}</Badge>)}
                </Wrap>}
                {params.id && <Button onClick={e => {
                    fetch(`${REACT_APP_api_url}/v3/delete_dataset/${params.id}`, {
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(response => {
                        return response.json()
                    }).then(d => {
                        toast({
                            title: `Dataset deleted.`
                        })
                        refresh()
                        navigate(`/train/datasets`)
                    })
                }} variant={"outline"} colorScheme='red'>Delete</Button>}
                {params.id && <Button onClick={e => {
                    setNewDataset({
                        original: datasets.filter(d => d._id === params.id)[0],
                        name: "",
                        description: "",
                        private: false
                    })
                    onOpen()
                }} variant={"outline"} colorScheme='green'>Clone</Button>}
                <Button variant={"outline"} colorScheme='green' onClick={e => {
                    setNewDataset({
                        name: "",
                        description: "",
                        private: false
                    })
                    onOpen()
                }}>New Dataset...</Button>
            </Stack>
            {/* Upload from File */}
            <FileDropZone onDrop={blob => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.src = URL.createObjectURL(blob);
                img.onerror = (e) => {
                    console.log("Error loading image", e);
                    // setError(`Error loading image. Please check the URL or file.`);
                    // setIsLoading(false);
                };

                img.onload = () => {
                    const processImage = async (blob) => {
                        let hash = await calculateBlobSHA256(blob);
                        const metadata = await fetch(`${REACT_APP_api_url}/v3/getimagehash/${hash}`).then(response => {
                            return response.json()
                        })
                        if (metadata === null) {
                            toast({
                                title: "Uploading new image",
                                description: `Hash : ${hash}`
                            })
                            const formData = new FormData();
                            formData.append("file", blob, hash);
                            let headers = {
                                Authorization: `Bearer ${token}`
                            }
                            await fetch(`${REACT_APP_api_url}/v3/saveimagehash/${hash}`, {
                                method: "POST",
                                headers,
                                body: formData
                            }).then(response => {
                                toast({
                                    title: "New image uploaded",
                                })
                                return response.json()
                            }).then(data => {
                                if(params.id) {
                                    tagUpload(hash, params.id, token).then((data) => {
                                        refresh()
                                    }).then(()=>{
                                        setR(r+1)
                                    }).catch(e => {
                                        console.error(e)
                                    })
                                }else{
                                    setR(r+1)
                                }
                            })
                        } else {
                            toast({
                                title: "Image already uploaded"
                            })
                            if(params.id) {
                                tagUpload(hash, params.id, token).then((data) => {
                                    refresh()
                                }).then(()=>{
                                    setR(r+1)
                                }).catch(e => {
                                    console.error(e)
                                })
                            }else{
                                setR(r+1)
                            }
                        }
                    }
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    canvas.toBlob(processImage, 'image/png');
                };
            }} />
            <InfinityFeed key={r+JSON.stringify(filters)} page={1} path={`/v3/my/uploads/${user.sub}/desc`} mode="uploads" source="uploads" method="continuation" filters={filters} />
        </>
        }
        <Modal
            initialFocusRef={initialRef}
            // finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{newDataset.original?`Clone ${newDataset.original.name}`:"New Dataset"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Dataset name</FormLabel>
                        <Input ref={initialRef} value={newDataset.name} placeholder='Dataset name' onChange={e => {
                            setNewDataset({ ...newDataset, "name": e.currentTarget.value })
                        }} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Description</FormLabel>
                        <Textarea placeholder='Description of your dataset' value={newDataset.description} onChange={e => {
                            setNewDataset({ ...newDataset, "description": e.currentTarget.value })
                        }} />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onAddNewDataset}>
                        Add
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}