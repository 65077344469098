import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InfinityFeed from 'components/shared/Feed/InfinityFeed';
import { Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Heading, RadioGroup, Radio, Stack, Icon } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { HiOutlineFire } from "react-icons/hi2";
import { AiOutlineStar, AiOutlineHeart } from "react-icons/ai";
import { IoDiceOutline } from "react-icons/io5";
import { MdOutlineLocalPolice } from "react-icons/md";

/**
 * Renders the BrowsePage component.
 *
 * @returns {JSX.Element} The rendered BrowsePage component.
 */
export default function BrowsePage() {
  const { permissions } = useAuth();
  const [browseDirection, setBrowseDirection] = useState("desc");
  const params = useParams()
  const type = params.type
  const navigate = useNavigate()
  let order = `/${browseDirection}`
    let tabList = [
    {
      key : "recent", 
      icon : <Icon as={HiOutlineFire} mr={2}/>,
      text : "Recent",
      tab : <TabPanel key="recent"><InfinityFeed path={`/v3/recent${order}`} method={"continuation"}/></TabPanel>
    },
    {
      key : "popular",
      icon : <Icon as={AiOutlineStar} mr={2}/>,
      text : "Popular",
      tab: <TabPanel key="popular"><InfinityFeed path={`/v3/popular${order}`} method={"continuation"}/></TabPanel>
    },
    {
      key : "recently_liked",
      text : "Recently Liked",
      icon : <Icon as={AiOutlineHeart} mr={2}/>,
      tab: <TabPanel key="recently_liked"><InfinityFeed path={`/v3/recentlikes${order}`} method={"continuation"}/></TabPanel>
    },
    {
      key : "random", 
      icon : <Icon as={IoDiceOutline} mr={2}/>,
      text : "Random", 
      tab: <TabPanel key="random"><InfinityFeed path={`/random`} method="random" /></TabPanel>
    },
  ]

  if(permissions.includes("admin")) tabList.push({
    key : "adminfeed",
    icon : <Icon as={MdOutlineLocalPolice} mr={2}/>,
    text : "Admin Feed",
    tab: <TabPanel key="adminfeed"><InfinityFeed path={`/v3/adminfeed${order}`} method={"continuation"} /></TabPanel>
  })

  let si = 0
  tabList.forEach((tab, index)=>{if(tab.key===type) si = index})

  return (
    <Flex direction="column" w={"full"} h={"100%"}>
        <Heading>Browse</Heading>
        {si!==3 && <RadioGroup pl={5} onChange={v=>{
            setBrowseDirection(v)
        }} value={browseDirection}>
        <Stack direction='row'>
          <Radio value='asc'>Ascending</Radio>
          <Radio value='desc'>Descending</Radio>
          {/* <Radio value='random'>Random</Radio> */}
        </Stack>
        </RadioGroup>}
        <Tabs variant={"enclosed"} isLazy lazyBehavior="unmount" index={si} onChange={index=>{navigate(`/browse/${tabList[index].key}`)}}>
            <TabList overflowX="auto" overflowY="hidden">{tabList.map(tab=><Tab key={tab.key} isDisabled={tab.disabled===true?true:false}>{tab.icon && tab.icon}{tab.text}</Tab>)}</TabList>
        <TabPanels>{tabList.map(tab=>tab.tab)}</TabPanels>
        </Tabs>
    </Flex>
  );
}
