import {useState} from 'react'
import {Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from "@chakra-ui/react"
import { ModelInfo } from "./ModelInfo"

export const ModalModelInfo = ({isOpen, onClose, hash, onRelatedSelect}) => {
    const [titleName, setTitleName] = useState(null)
    const [versionName, setVersionName] = useState(null)
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={"4xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{titleName?`${titleName} - ${versionName}`:"Loading..."}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ModelInfo inModal={true} onRelatedSelect={onRelatedSelect} hash={hash} onLoad={(d)=>{
                        setTitleName(d.name)
                        setVersionName(d.version)
                    }}/>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}