import { useState, useEffect, useRef, useCallback } from 'react';
import { Flex, Tooltip } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { submitFluxTraining } from 'utils/api';
import { useUser } from 'contexts/UserContext';
import { getFluxTraining } from 'utils/api';

import {
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
    Button, Input, Select, FormLabel, useToast
} from '@chakra-ui/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { PropBox } from 'components/Workshop/PropBox';

export default function FluxTraining({
       onChange = () => { console.warn("FluxTraining.onChange not implemented.") },
       value = {},
       id = null
    }) {
    
    const { token } = useAuth()
    const { datasets, refresh } = useUser()
    const navigate = useNavigate()
    const toast = useToast()
    const [currentValue, setCurrentValue] = useState(value)
    const { permissions } = useAuth()
    useEffect(()=>{
        if(id){
          getFluxTraining(token, id).then(({data, error})=>{
            if(error){
              console.error(error)
            } else {
              setCurrentValue(data)
            }
          })
        }
      }, [id, token])

    useEffect(() => {
        onChange(currentValue)
    },[currentValue, onChange])
    return <>
        <PropBox type="fluxtraining" label="Training Options" value={currentValue} onChange={v => onChange(v)} collapsible={false}>
            {/* Name */}
            <Flex w={"full"}>
                <Tooltip hasArrow label={"Name"} openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"name"} flex={1}>Name</FormLabel>
                </Tooltip>
                <Input name="name" placeholder="Name of model" flex={1} value={currentValue.name} onChange={e=>{
                    setCurrentValue({ ...currentValue, "name": e.currentTarget.value })
                }}/>
            </Flex>
            {/* Name */}
            <Flex w={"full"}>
                <Tooltip hasArrow label={"Version"} openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"version"} flex={1}>Version</FormLabel>
                </Tooltip>
                <Input name="version" placeholder="Version of model" value={currentValue.version} flex={1} onChange={e=>{
                    setCurrentValue({ ...currentValue, "version": e.currentTarget.value })
                }}/>
            </Flex>
            {/* Dataset */}
            <Flex w={"full"}>
                <Tooltip hasArrow label={"Dataset"} openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"dataset"} flex={1}>Dataset</FormLabel>
                </Tooltip>
                {datasets.length === 0 && <p>No datasets exist.  Create one to get started.</p>}
                {datasets.length > 0 && <>
                    <Select value={currentValue.dataset} flex={1} onChange={e => {
                        setCurrentValue({ ...currentValue, "dataset": e.currentTarget.value })
                    }}>
                        <option value="" disabled>Select a dataset</option>
                        {datasets.map(dataset => <option selected={currentValue.dataset === dataset._id} key={dataset._id} value={dataset._id}>{dataset.name}{(dataset?.description) ? ` - ${dataset.description}` : ""}</option>)}
                    </Select>
                </>}
            </Flex>
            {/* Trigger Word */}
            <Flex w={"full"}>
                <Tooltip hasArrow label={"Trigger Word"} openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"trigger_word"} flex={1}>Trigger Word (optional)</FormLabel>
                </Tooltip>
                <Input id="trigger_word" value={currentValue.trigger_word} placeholder={"weathered basketball"} flex={1} onChange={e=>{
                    setCurrentValue({ ...currentValue, "trigger_word": e.currentTarget.value })
                }}/>
            </Flex>
            {/* Steps */}
            <Flex mr={2}>
                <Tooltip hasArrow label="Steps to train" openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor="steps" flex={1}>Steps</FormLabel>
                </Tooltip>
                <NumberInput
                    id="steps"
                    value={currentValue.steps}
                    min={50}
                    max={2500}
                    step={50}
                    clampValueOnBlur={true}
                    onChange={v => { setCurrentValue({ ...currentValue, "steps": parseInt(v)}) }}
                    flex={1}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Flex>
            {/* Learning Rate */}
            <Flex mr={2}>
                <Tooltip hasArrow label="Learning Rate" openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor="learning_rate" flex={1}>Learning Rate</FormLabel>
                </Tooltip>
                <NumberInput
                    id="learning_rate"
                    value={currentValue.learning_rate}
                    min={0.00000001}
                    max={0.1}
                    clampValueOnBlur={true}
                    onChange={v => { setCurrentValue({ ...currentValue, "learning_rate": parseFloat(v) * 1.0 }) }}
                    flex={1}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Flex>
            {/* LoRA Rank */}
            <Flex mr={2}>
                <Tooltip hasArrow label="LoRA Rank" openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor="lora_rank" flex={1}>LoRA Rank</FormLabel>
                </Tooltip>
                <NumberInput
                    id="lora_rank"
                    value={currentValue.lora_rank}
                    min={4}
                    max={256}
                    step={16}
                    clampValueOnBlur={true}
                    onChange={v => { setCurrentValue({ ...currentValue, "lora_rank": parseInt(v) }) }}
                    flex={1}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Flex>
            <Tooltip openDelay={250} label={permissions.includes("training")?"Submit Training Job":"You do not have permissions to train."}>
                <Button onClick={e => {
                    submitFluxTraining(token, value).then(({data, error})=>{
                        if(error){
                            toast({
                                title: "Error",
                                description: error?.message,
                                status: "error",
                                duration: 2500,
                                isClosable: true,
                            })
                        }
                        
                        if(data){
                            toast({
                                title: "Training Job Submitted",
                                description: data?.id,
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                            })
                            navigate(`/train/training/${data.id}`)
                        }
                    })
                }} variant={"outline"} colorScheme='green' isDisabled={!permissions.includes("training")}>Submit</Button>
            </Tooltip>
            </PropBox>
    </>
}