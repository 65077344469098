import React from 'react';
import './index.css';
import App from './App';
import theme from './theme';
import { ColorModeScript } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from 'contexts/AuthContext';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

const onAuthError = (err) => {
  console.error(err);
};

function AuthenticatedApp() {
  const { isLoading } = useAuth();
  if (isLoading) {
    return <div>Loading...</div>;
  }else{
    return <App />;
  }
}

let app = document.getElementById('root');

if (app) {
  createRoot(app).render(
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Auth0Provider
        audience={getEnvVariable("REACT_APP_auth0_audience", process.env.REACT_APP_auth0_audience)}
        domain={getEnvVariable("REACT_APP_auth0_domain", process.env.REACT_APP_auth0_domain)}
        clientId={getEnvVariable("REACT_APP_auth0_client_id", process.env.REACT_APP_auth0_client_id)}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        onAuthError={onAuthError}
      >
        <AuthProvider>
          <AuthenticatedApp />
        </AuthProvider>
      </Auth0Provider>
    </>
  );
}
