import { useEffect, useState, useRef, useCallback } from "react"
import { getEnvVariable } from 'utils/env'
import { useAuth } from 'contexts/AuthContext'
import { Heading } from '@chakra-ui/react'
import { Box, Avatar, Wrap, Link, Center, Spinner } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'

export default function UsersPage() {
    const sentinelRef = useRef(null)
    const [lastUser, setLastUser] = useState(null)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const { token } = useAuth()

    const fetchUsers = useCallback((lu)=>{
        setLoading(true);

        const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
        let apiURL = `${REACT_APP_api_url}/users`
        if(lu){
            apiURL = `${REACT_APP_api_url}/users/${lu.user_id}`
        }
        
        fetch(apiURL,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            return response.json()
        }).then((actualData) => {
            setLoading(false)
            setData(prevItems => [...prevItems, ...actualData.filter(newItem => !prevItems.some(prevItem => prevItem.user_id === newItem.user_id))])
            if(actualData.length === 0) {
                setHasMore(false)
            }else{
                setLastUser(actualData[actualData.length - 1])
            }
            return actualData
        })
    },[token])

    useEffect(() => {
        const sr = sentinelRef.current
        if(!sr) return

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.target === sentinelRef.current && entry.isIntersecting && !loading && hasMore) {
                    fetchUsers(lastUser);
                }
            })
        }, {
            threshold: 0.2,
            rootMargin: '250px',
        });
        observer.observe(sentinelRef.current);
        return () => {
            // cleanup function to remove the observer when the component unmounts
            observer.unobserve(sr);
        };
    },[sentinelRef, loading, hasMore, fetchUsers, lastUser])

    return <div>
        <Heading size="md">Recent Users</Heading>
        {data && <Box>{data.map((user) => {
            const lastSeenDate = new Date(user.last_seen.$date);
            const lastSeenRelative = formatDistanceToNow(lastSeenDate, { addSuffix: true });
            return <Box key={user.user_id} mb={3} p={2} borderWidth={1} rounded={5}>
                <Link target="_blank" href={`/gallery/${user.user_id}/recent`}><Wrap><Avatar src={user.picture}></Avatar>{user.nickname}<br />{lastSeenRelative}</Wrap></Link>
            </Box>
        })}
        </Box>}
        <div ref={sentinelRef} style={{ width: "100%" }}>
            <Center p={5}>{loading && <Spinner label='Loading more...' />}</Center>
            {!hasMore && <Center p={5}>No more users</Center>}
        </div>
    </div>
}