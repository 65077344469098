import React from 'react';
import { Box, Flex, HStack, Wrap, WrapItem, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { getEnvVariable } from 'utils/env';

export function ModelOverlay({
  onClick = () => console.warn("No onClick defined"),
  onCreate = () => console.warn("No onCreate defined"),
  onMoved = () => console.warn("No onMoved defined"),
  model,
  isInterested
}) {
  const [isPinned, setIsPinned] = useState((model.pinned) ? true : false)

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

  let duration = 0.2
  let uninterestedStyle = {
    cursor: "pointer",
    // visibility: "visible",
    opacity: 1,
    transition: `opacity ${duration}s linear`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }
  let interestedStyle = {
    cursor: "pointer",
    // visibility: "hidden",
    opacity: 0.0,
    transition: `visibility 0s ${duration}s, opacity ${duration}s linear, backdrop-filter ${duration}s`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }
  let overlayStyle = uninterestedStyle
  if (isInterested) {
    overlayStyle = interestedStyle
  }
  
  useEffect(() => {
    setIsPinned((model.pinned) ? true : false)
  }, [model]);
  useEffect(() => {

  }, [isPinned]);

  return (<>
    <Box>
      <Box style={overlayStyle} onClick={() => {
        onClick(model._id)
      }}>
        <Box pos="absolute" bottom="0" left="0" right="0" m={0} p={2} >
          <div style={(() => {
            let bgColor = "rgba(0,0,0,0.4)"
            if(model.nsfw) bgColor = "rgba(200,0,0,0.4)"
            return {
                backgroundColor: bgColor,
                width: "100%", height: "100%",
                padding: "5px",
                borderRadius: "10px",
                backdropFilter: "blur(10px)",
                boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.4)",
            }
          })()}>
            <HStack>
              <Box>
                <Wrap>
                  <WrapItem display="flex" alignItems="center">
                      <Flex direction={"column"}>
                        <Text ml={2} style={{ color: "#FFF", textShadow: "1px 1px 2px #2a2a2a", fontWeight: "bold" }}> {model.name}</Text>
                        <Text ml={2} style={{ color: "#FFF", textShadow: "1px 1px 2px #2a2a2a" }}>{model.version}</Text>
                      </Flex>
                  </WrapItem>
                </Wrap>
              </Box>
            </HStack>
          </div>
        </Box>
        <Flex direction={"column"} style={{
          position: "absolute",
          top: 0,
          left: 0,
          // zIndex : 1,
          opacity: 0.7,
          // color : "#FFF"
        }}
        >

        </Flex>
        <Flex direction={"column"} style={{
          position: "absolute",
          top: 0,
          right: 0,
          // zIndex : 2
        }} onClick={(e) => { e.stopPropagation() }}>
          {/* {((user && user.sub && piece && piece.author === user.sub) || permissions.includes("seeprompts")) && !piece.orphaned && !isDeleted &&
            <>
              <Tooltip hasArrow label="Create">
                <IconButton colorScheme={"green"} isRound icon={<FaPaintBrush />} onClick={e => {
                  e.stopPropagation();
                  if (token) {
                    if (onCreate) {
                      onCreate(piece)
                    } else {
                      // setModalOpen(true)
                    }
                    // navigate(`/${piece.architecture}/${piece._id}`)
                  } else {
                    loginWithRedirect(`/{piece.architecture}/${piece._id}`)
                  }
                }} />
              </Tooltip>
            </>
          } */}
          {/* {user && (piece.author === user.sub || permissions.includes("admin")) &&
            <Tooltip hasArrow label={isDeleted ? "Undelete" : "Delete"}>
              <IconButton
                isRound
                isLoading={isMoving}
                colorScheme={'red'}
                size="md"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMoving(true)
                  if (!isAuthenticated) {
                    loginWithRedirect()
                  } else {
                    let method = "POST"
                    let url = `${REACT_APP_api_url}/delete/${piece._id}`
                    if (isDeleted) {
                      url = `${REACT_APP_api_url}/undelete/${piece._id}`
                    }
                    fetch(url, {
                      method,
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                      },
                    }).then(d => {
                      onMoved()
                      setIsMoving(false)
                    })
                  }
                }}
                icon={(isDeleted) ? <FaTrashRestore /> : <FaTrash />}
              />
            </Tooltip>} */}
        </Flex>
      </Box>
    </Box>
  </>);
}