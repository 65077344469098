import {
    Box, Select, Wrap, WrapItem, FormLabel, Tooltip, Flex, IconButton,
    NumberIncrementStepper, NumberDecrementStepper, NumberInput, NumberInputStepper, NumberInputField,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb
} from '@chakra-ui/react';
import { BsDice3 } from 'react-icons/bs'
import { generateComfySdSamplerList, generateComfySdSchedulerList } from 'utils/pieceUtils'
import { PropBox } from './PropBox'

/**
 * Renders the Sampler Options component.
 * 
 * @param {Object} value - The current value of the sampler options.
 * @param {Object} pipeline - The pipeline object.
 * @param {Function} onChange - The onChange event handler for the sampler options.
 * @returns {JSX.Element} The rendered Sampler Options component.
 */
export const SamplerOptions = ({
    architecture = "stable-diffusion",
    value,
    pipeline,
    onChange = () => { console.warn("No SamplerOptions onChange hander.") }
}) => {
    const samplers = generateComfySdSamplerList()
    const schedulers = generateComfySdSchedulerList()

    return value !== undefined ? <PropBox type="sampler" label="Sampler Options" value={value} onChange={v => onChange && onChange(v)}>
        <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="Each sampler has its own distinct characteristics.  Refer to some sampler studies online to see comparisons." openDelay={250}>
                    <FormLabel htmlFor="sampler_name">Sampler</FormLabel>
                </Tooltip>
            </Flex>
            <WrapItem>
                <Select id="sampler_name" value={value.sampler_name} onChange={e => onChange && onChange({ ...value, "sampler_name": e.target.selectedOptions[0].value })}>
                    {samplers.map(shape => {
                        return <option key={shape.key} value={shape.key}>{shape.text ? shape.text : shape.key}</option>
                    })}
                </Select>
            </WrapItem>
        </Wrap>
        <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="Certain samplers work with different schedulers.  For example, if you are used to using 'DPM++ 2M Karras', you'll want to select the karras scheduler." openDelay={250}>
                    <FormLabel htmlFor="scheduler">Scheduler</FormLabel>
                </Tooltip>
            </Flex>
            <WrapItem>
                <Select id="scheduler" value={value.scheduler}
                    onChange={e => onChange && onChange({ ...value, "scheduler": e.target.selectedOptions[0].value })}
                >{schedulers.map(scheduler => {
                    return <option key={scheduler.key} value={scheduler.key}>{scheduler.text ? scheduler.text : scheduler.key}</option>
                })}
                </Select>
            </WrapItem>
        </Wrap>
        <Flex w={"full"}>
            <Tooltip hasArrow label="Denoise Strength" openDelay={250}>
                <FormLabel htmlFor="denoise">Denoise ({(value.denoise !== undefined) ? value.denoise : 1.0})</FormLabel>
            </Tooltip>
            <Slider flex={1} id='denoise' value={value.denoise} step={0.05} min={0} max={1} onChange={v => onChange && onChange({ ...value, "denoise": v })} >
                <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </Flex>
        <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="How many steps the diffusion process iterates over.  Note, more steps does not equally better images past a certain point of diminishing returns.  Each Sampler has a different number of steps before reaching that point." openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor="steps">Steps</FormLabel>
                </Tooltip>
            </Flex>
            <NumberInput
                id="steps"
                value={value.steps}
                min={5}
                max={75}
                step={1}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "steps": v })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Wrap>
        {pipeline && pipeline.type === "sdxl-1.0" && <>
            <Wrap mr={2}>
                <Flex>
                    <Tooltip hasArrow label="How many refiner steps the diffusion process iterates over.  A low amount is sufficient." openDelay={250}>
                        <FormLabel whiteSpace={"nowrap"} htmlFor="refiner_steps">Refiner Steps</FormLabel>
                    </Tooltip>
                </Flex>
                <NumberInput
                    id="refiner_steps"
                    value={value.refiner_steps}
                    min={0}
                    max={20}
                    step={1}
                    clampValueOnBlur={true}
                    onChange={v => {
                        onChange && onChange({ ...value, "refiner_steps": v })
                    }}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Wrap>
        </>}
        {/* cfg */}
        {(true || architecture==="flux") && <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="Controls how much the rendering process adheres to your text prompts.  Setting too high may result in a 'deep fried' or 'overcooked' look, while too low may result in not a lot of variety, or may not reflect your text prompts." openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"cfg"} >Scale ({value.cfg})</FormLabel>
                </Tooltip>
            </Flex>
            <NumberInput
                id="cfg"
                value={value.cfg}
                min={1}
                max={25}
                step={0.25}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "cfg": v })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Wrap>}
        {/* max_shift */}
        {architecture==="flux" && <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="Max Shift" openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"max_shift"} >Max Shift ({value.max_shift})</FormLabel>
                </Tooltip>
            </Flex>
            <NumberInput
                id="max_shift"
                value={value.max_shift}
                min={0}
                max={5}
                step={0.05}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "max_shift": v })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Wrap>}
        {/* base_shift */}
        {architecture==="flux" && <Wrap mr={2}>
            <Flex>
                <Tooltip hasArrow label="Base Shift" openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={"base_shift"} >Base Shift ({value.base_shift})</FormLabel>
                </Tooltip>
            </Flex>
            <NumberInput
                id="base_shift"
                value={value.base_shift}
                min={0}
                max={5}
                step={0.05}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "base_shift": v })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Wrap>}
        <Wrap>
            <Tooltip hasArrow label="Random seed number used to generate the initial random noise which results in a different image." openDelay={250}>
                <FormLabel whiteSpace={"nowrap"} htmlFor="seed">Image Seed</FormLabel>
            </Tooltip>
            <NumberInput
                id="seed"
                value={value ? value.seed : -1}
                min={-1}
                max={2 ** 32}
                clampValueOnBlur={true}
                onChange={v => {
                    onChange && onChange({ ...value, "seed": parseInt(v) })
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            <Tooltip hasArrow label="Randomize">
                <IconButton
                    variant={"ghost"}
                    icon={<BsDice3 />}
                    style={{ margin: "0px" }}
                    onClick={() => {
                        let r = Math.floor(Math.random() * (2 ** 32))
                        onChange && onChange({ ...value, "seed": r })
                    }}
                />
            </Tooltip>
        </Wrap>
    </PropBox > : <Box>Loading...</Box>
}