import React, { useRef } from 'react';
import { Textarea } from '@chakra-ui/react';

export function PromptTextarea({
  onChange = (e) => { console.warn("PromptTextarea onChange not implemented") },
  value = ''
}) {
  const textareaRef = useRef(null);

  const handleKeyDown = (e) => {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const textBefore = value.substring(0, start);
    const textAfter = value.substring(end, value.length);
    const selectedText = value.substring(start, end);

    if ((e.ctrlKey || e.metaKey) && e.key === 'ArrowUp') {
      e.preventDefault();
      // Wrap the selected text with parentheses
      const newValue = `${textBefore}(${selectedText})${textAfter}`
      onChange({ target: { value: newValue } });
      setTimeout(() => {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(start, end + 2); // +2 for the parentheses
      }, 0);
    } else if ((e.ctrlKey || e.metaKey) && e.key === 'ArrowDown') {
      e.preventDefault();
      // Check if the selected text is surrounded by parentheses
      if (selectedText.startsWith('(') && selectedText.endsWith(')')) {
        const newText = selectedText.substring(1, selectedText.length - 1);
        const newValue = `${textBefore}${newText}${textAfter}`;
        onChange({ target: { value: newValue } });
        setTimeout(() => {
          textareaRef.current.focus();
          // Adjust the selection to exclude the removed parentheses
          textareaRef.current.setSelectionRange(start, end - 2); // -2 for the parentheses
        }, 0);
      }
    }

  };

  return (
    <Textarea
      // style={{ resize: "none" }}
      style={{fontFamily: "monospace"}}
      ref={textareaRef}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      placeholder="Type your prompt here"
      h={"full"}
    />
  )
}