import { Box, Heading, Flex, SimpleGrid, Stat, StatLabel, StatNumber, useColorModeValue, Center } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { BsCardImage, BsPerson, BsCpuFill } from 'react-icons/bs';
import { GiProcessor } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom';
import { getEnvVariable } from 'utils/env';

  function StatsCard(props) {
    const { 
      title,
      stat,
      icon,
      link = null
    } = props;
    const navigate = useNavigate();
    return <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'3px solid'}
      borderColor={useColorModeValue('white.500', 'white.500')}
      rounded={'xl'}
      style={{"cursor":(link!==null)?"pointer":"arrow"}}
      onClick={e=>{link && navigate(link)}}
      >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }} >
          <StatLabel fontWeight={'medium'}>{title}</StatLabel>
          <StatNumber fontSize={'xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  }
  
  export function Stats() {
    const [data, setData] = useState(null);
    const [, setError] = useState(null);
    const [, setLoading] = useState(true);

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    
    function fetchStats(amount) {
      let url = `${REACT_APP_api_url}/v3/landingstats`;
      // console.log(url);
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((actualData) => {
          setData(actualData);
          setError(null);
        })
        .catch((err) => {
          setError(err.message);
          setData(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    useEffect(() => {
      fetchStats();
    }, []);

    return (
      data && <Center>
        <Box minW={{ xl : "5xl", lg:"5xl"}} maxW={{ xl : "7xl", lg:"5xl"}} mx={'auto'} px={{ base: 12, sm: 12, md: 17 }}>
          <Heading
            textAlign={'center'}
            fontSize={'2xl'}
            py={5}
            fontWeight={'bold'}>
            Create and Browse generative AI art created by other users.
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={{ base: 5, lg: 5 }}>
            <StatsCard
              title={'Users'}
              link='/users'
              stat={data.userCount}
              icon={<BsPerson size={'3em'} />
            }
            />
            <StatsCard
              title={'Models'}
              stat={`${data.modelCount.toLocaleString('en-US')} (${data.modelFileSize!==0?(data.modelFileSize/1024/1024/1024/1024).toFixed(1).toLocaleString('en-US'):0} TB)`}
              icon={<GiProcessor size={'3em'} />}
            />
            <StatsCard
              title={'GPUs Online'}
              stat={data.workerCount}
              icon={<BsCpuFill size={'3em'} />}
            />
            <StatsCard
              title={'Images Generated'}
              stat={data.completedCount.toLocaleString('en-US')}
              icon={<BsCardImage size={'3em'} />}
            />
          </SimpleGrid>
        </Box>
      </Center>
    );
  }