import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

import { 
    Heading, Button, Input, Textarea, useDisclosure,
    Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter,
    FormLabel, FormControl, useToast
} from '@chakra-ui/react';

export default function Albums(props) {
    const {user, token} = useAuth()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const toast = useToast()
    let [newAlbum, setNewAlbum] = useState({
        name : "",
        description : "",
        private : false
    })
    let [myAlbums, setMyAlbums] = useState([])
    let [fetching, setFetching] = useState(false)
    
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    const onAddNewAlbum = (e)=>{
        setFetching(true)
        fetch(`${REACT_APP_api_url}/v3/create_album`,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({newAlbum}),
        }).then(response=>{
            return response.json()
        }).then(d=>{
            setFetching(false)
            toast({
                title: `Album created.`
            })
            fetchMyAlbums()
            onClose(e)
        }).then(
            // lookupWorkItem(data.uuid)
        )
    }
    const fetchMyAlbums = ()=>{
        if(user && props.user_id===user.sub){
            setFetching(true)
            fetch(`${REACT_APP_api_url}/myalbums`,{
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }).then(response=>response.json()).then(d=>{
                setFetching(false)
                setMyAlbums(d)
            })
        }
    }

    useEffect(()=>{
        fetchMyAlbums()
    },[])

    return <>
    {/* <Heading>Albums</Heading> */}
    {user && props.user_id===user.sub && <>
        <Button onClick={e=>{
            setNewAlbum({
                name : "",
                description : "",
                private : false
            })
            onOpen(e)
        }}>Add Album</Button>
        <Heading fontSize={"md"}>My Albums</Heading>
        <ul>
            {myAlbums.map(album=><li key={album.name}>{album.name}<br/>{album.description}</li>)}
        </ul>
    </>
    }
    <Modal
        initialFocusRef={initialRef}
        // finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Album</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Album name</FormLabel>
              <Input ref={initialRef} value={newAlbum.name} placeholder='Album name' onChange={e=>{
                setNewAlbum({...newAlbum,["name"]:e.currentTarget.value})
              }} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea placeholder='Description of your album' value={newAlbum.description} onChange={e=>{
                setNewAlbum({...newAlbum,["description"]:e.currentTarget.value})
              }}/>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onAddNewAlbum}>
              Add
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
}