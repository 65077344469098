import { FormLabel, Select, Tooltip, Flex, Switch, Slider, SliderTrack, SliderThumb, SliderFilledTrack } from '@chakra-ui/react'
import { generateComfyUpscalerList } from 'utils/pieceUtils'
import { ModelOptions } from './ModelOptions'
import { SamplerOptions } from './SamplerOptions'
import { PropBox } from './PropBox'
import { PromptOptions } from './PromptOptions'

/**
 * Renders the Upscale Options component.
 *
 * @param {String} architecture - The architecture of the model.
 * @param {Object} value - The current value of the upscale options.
 * @param {Object} mainSamplerPipeline - The main sampler pipeline.
 * @param {Function} [props.onChange] - The onChange event handler for the upscale options.
 * @returns {JSX.Element} The rendered Upscale Options component.
 */
export const UpscaleOptions = ({
  architecture = "stable-diffusion",
  value,
  mainSamplerPipeline,
  onChange = () => { console.warn("No UpscaleOptions onChange hander.") }
}) => {

  const upscalerList = generateComfyUpscalerList()
  let pipeline = mainSamplerPipeline

  if (["stable-diffusion"].includes(architecture) && value.use_main_model_pipeline === false) pipeline = value.model.pipeline

  return <PropBox type="upscale" label="Upscale Options" value={value} onChange={v => onChange && onChange(v)} >
    <Flex w={"full"}>
      {/* method */}
      <Tooltip hasArrow label="Upscalers allow you to make your image higher resolution without looking blurry or pixelated.  Each upscaler model uses a different technique to scale your starting image up.  For example, some upscalers are better for realistic portraits while others are better at illustrations or cartoon styles." openDelay={250} >
        <FormLabel htmlFor="method">Upscaler</FormLabel>
      </Tooltip>
      <Select id="method" value={value.method}
        onChange={e => onChange && onChange({ ...value, "method": e.target.selectedOptions[0].value })}
      >{
          upscalerList.map(upscaler => {
            let disabled = upscaler.key.startsWith("-")
            return <option disabled={disabled} key={upscaler.key} value={upscaler.key}>{upscaler.text ? upscaler.text : upscaler.key}</option>
          })
        }</Select>
    </Flex>
    {/* scale */}
    <Tooltip hasArrow label="How much to upscale the image by.  Beware, the higher you go, the longer the rendering will take to complete, and you run a risk of the job failing." openDelay={250} >
      <FormLabel htmlFor="scale">Upscale Amount({value.scale})</FormLabel>
    </Tooltip>
    <Flex w={"full"}>
      <Slider
        id='scale'
        value={value.scale}
        step={0.25}
        min={1}
        max={4}
        onChange={v => onChange && onChange({ ...value, "scale": v })}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Flex>
    {["stable-diffusion"].includes(architecture) && <>
      {/* use_main_model_pipeline */}
      <Flex>
        <Tooltip hasArrow label="Use Main Pass Model Pipeline" openDelay={250} >
          <FormLabel htmlFor="use_main_model_pipeline">Use Main Pass Model Pipeline</FormLabel>
        </Tooltip>
        <Switch
          id="use_main_model_pipeline"
          isChecked={value.use_main_model_pipeline}
          onChange={e => onChange && onChange({ ...value, "use_main_model_pipeline": e.target.checked })}
        />
      </Flex>
      {/* model */}
      {value.use_main_model_pipeline === false && <Flex w={"full"}><ModelOptions architecture={architecture} value={value.model} onChange={v => { onChange && onChange({ ...value, "model": v }) }} /></Flex>}
    </>}
    {/* sampler.denoise */}
    {value.use_main_sampler === true && <Flex w={"full"}>
      <Tooltip hasArrow label="Denoise Strength" openDelay={250} >
        <FormLabel htmlFor="denoise">Denoise Strength ({value.sampler.denoise})</FormLabel>
      </Tooltip>
      <Slider id='denoise' value={value.sampler.denoise} step={0.05} min={0} max={1} onChange={v => onChange && onChange({ ...value, "sampler": { ...value.sampler, "denoise": v } })}>
        <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
      </Slider>
    </Flex>}
    {/* use_main_prompts */}
    <Flex>
      <Tooltip hasArrow label="Use Main Pass Prompts" openDelay={250} >
        <FormLabel htmlFor="use_main_prompts">Use Main Pass Prompts</FormLabel>
      </Tooltip>
      <Switch
        id="use_main_prompts"
        isChecked={value.use_main_prompts}
        onChange={e => onChange && onChange({ ...value, "use_main_prompts": e.target.checked })}
      />
    </Flex>
    {/* prompts */}
    {value.use_main_prompts === false && <PromptOptions
      value={value.prompts}
      onChange={v => { onChange && onChange({ ...value, prompts: v }) }} />}
    {/* use_main_sampler */}
    <Flex>
      <Tooltip hasArrow label="Use Main Pass Sampler Settings" openDelay={250} >
        <FormLabel htmlFor="use_main_sampler">Use Main Pass Sampler Settings</FormLabel>
      </Tooltip>
      <Switch
        id="use_main_sampler"
        isChecked={value.use_main_sampler}
        onChange={e => onChange && onChange({ ...value, "use_main_sampler": e.target.checked })}
      />
    </Flex>
    {/* sampler */}
    {value.use_main_sampler === false && <Flex w={"full"}>
      <SamplerOptions pipeline={pipeline} value={value.sampler} onChange={v => { onChange && onChange({ ...value, "sampler": v }) }} architecture= {architecture}/>
    </Flex>}
  </PropBox>
}