import { getEnvVariable } from './env'

export function cancelTraining(token, id) {
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    return fetch(`${REACT_APP_api_url}/v3/fluxtrain/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then((response) => {
        if (response.status === 200) return response.json()
        throw new Error("Error cancelling training job.")
    })
        .then(data => { return { data } })
        .catch(error => { return { error } })
}

export function getTrainingQueue(token) {
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    return fetch(`${REACT_APP_api_url}/v3/training_queue`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then((response) => {
        if (response.status === 200) return response.json()
        throw new Error("Error fetching training queue.")
    })
        .then(data => { return { data } })
        .catch(error => { return { error } })
}

export function submitFluxTraining(token, job) {
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    return fetch(`${REACT_APP_api_url}/v3/fluxtrain`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(job)
    }).then((response) => {
        if (response.status === 200) return response.json()
        // TODO: Get error message from response
        throw new Error("Error submitting training job.")
    })
        .then(data => { return { data } })
        .catch((error) => { return { error } })
}

export function getFluxTraining(token, id) {
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    return fetch(`${REACT_APP_api_url}/v3/fluxtrain/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then((response) => {
        if (response.status === 200) return response.json()
        throw new Error("Error fetching training job.")
    })
        .then(data => { return { data } })
        .catch(error => { return { error } })
}

export function tagUpload(hash, dataset, token) {
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    return fetch(`${REACT_APP_api_url}/v3/tagimagehash`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            hash,
            dataset
        })
    }).then((response) => response.json())
}

export function untagUpload(hash, dataset, token) {
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    return fetch(`${REACT_APP_api_url}/v3/tagimagehash`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            hash,
            dataset
        })
    }).then((response) => response.json())
}