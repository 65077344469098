import {Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from "@chakra-ui/react"
import InfinityFeed from 'components/shared/Feed/InfinityFeed'
import { useAuth } from 'contexts/AuthContext'
export const ModalUploadsBrowser = ({
    isOpen,
    onClose,
    hide,
    onSelect
}) => {
    const { token, user, permissions } = useAuth();
    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={"4xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Uploads Browser</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <InfinityFeed page={1} path={`/v3/my/uploads/${user.sub}/desc`} mode="uploads" source="uploads" method="continuation" onClick={upload=>{
                        onSelect && onSelect(upload)
                    }}/>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}