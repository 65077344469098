import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env'
import { Box, Badge, VStack, Progress, Text, Center, Spinner, ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { TiCancel } from "react-icons/ti";
import { FiRefreshCw } from "react-icons/fi";

export function QueueFeed({ 
    refresh = 0, 
    filters = {}, 
    onSelect = () => { },
    cancelHandler = () => { },
    retryHandler = () => { },
}) {
    const { token } = useAuth()
    const [isFetching, setIsFetching] = useState(false)
    const [queue, setQueue] = useState([])

    const fetchWorkspace = useCallback(async () => {
        const f = {architecture: filters.architecture}
        const REACT_APP_api_url = getEnvVariable('REACT_APP_api_url', process.env.REACT_APP_api_url)
        setIsFetching(true)
        fetch(`${REACT_APP_api_url}/v3/myjobs/all/1`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ f }),
        }).then(response => {
            return response.json()
        }).then(d => {
            setQueue(d)
            setIsFetching(false)
        })
    }, [token, setQueue, filters.architecture])

    useEffect(() => {
        fetchWorkspace()
    }, [refresh,fetchWorkspace])

    return (
        <Box overflowY={"scroll"} h={"100%"}>
            {isFetching && <Center p={5}><Spinner label='Loading...' /></Center>}
            {queue.map((job) => {
                let progressText = '';
                let task = '';
                let colorScheme = "blue"
                if (job.status === 'failed') colorScheme = "red"
                if (job.status === 'completed' || job.status === 'finished') colorScheme = "green"
                if (job.status === 'processing') colorScheme = "green"

                if (job.stats && job.stats.progress) {
                    progressText = `(${job.stats.progress.value}/${job.stats.progress.max})`;
                    task = job.stats.task;
                } else {
                    progressText = `Starting...`;
                }
                return (
                    <Box key={job._id} style={{cursor:"pointer"}} borderWidth={1} rounded={"md"} mb={2} p={2} onClick={e=>{onSelect(job)}}>
                        { ["queued", "failed"].includes(job.status) && <ButtonGroup>
                            {/* Cancel button */}
                            <Tooltip hasArrow label="Cancel">
                                <IconButton icon={<TiCancel />} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    cancelHandler(job).then(() => {
                                        fetchWorkspace()
                                    })
                                }} />
                            </Tooltip>
                        </ButtonGroup>}
                        {job.status === "failed" && <ButtonGroup>
                            {/* Retry button */}
                            <Tooltip hasArrow label="Retry">
                                <IconButton icon={<FiRefreshCw />} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    retryHandler(job).then(() => {
                                        fetchWorkspace()
                                    })
                                }} />
                            </Tooltip>
                        </ButtonGroup>}
                        <Badge>{job._id.substring(0, 8)}</Badge>
                        <Badge colorScheme={colorScheme}>{job.status}</Badge>
                        <Badge>{job.agent_id}</Badge>
                        {job.status === 'processing' && (
                            <VStack w={'full'} align={"left"}>
                                {job.stats && job.stats.progress && (
                                    <Progress
                                        w={'full'}
                                        rounded={'full'}
                                        colorScheme={'green'}
                                        value={job.stats.progress.progress * 100}
                                    />
                                )}
                                <Text fontSize={'md'}>{progressText}<br />{task}</Text>
                            </VStack>
                        )}
                    </Box>
                )
            })}
        </Box>
    )
}