import { useState, useEffect } from 'react';
import JsonView from '@uiw/react-json-view';
import { nordTheme } from '@uiw/react-json-view/nord';
import { 
    Box, Heading, Badge, Image, Spinner, Table, Link,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Skeleton,
    Text,
    Progress } from '@chakra-ui/react';

export default function GpuStatus({data, loading}) {
    useEffect(() => { }, [])

    return <Box width={"full"}>
        {loading && <Spinner>Loading</Spinner>}
        {!loading && data && <TableContainer>
        <Table variant="simple">
          <TableCaption>List of active agents running</TableCaption>
          <Thead>
            <Tr>
              <Th>Agent</Th>
              <Th>Filter</Th>
              <Th>Stats</Th>
              <Th>Job Progress</Th>
              {/* <Th>Pending Command</Th> */}
              <Th>Mode</Th>
              <Th>Score</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((o, i) => {
                let vram = (o.gpu && o.gpu.mem_total)?o.gpu.mem_total:"???"
                let temperature = (o.gpu && o.gpu.temperature)?o.gpu.temperature:"???"
                return (
                  <Tr key={o.agent_id}>
                    <Td>
                      <Skeleton isLoaded={!loading}>
                        <Link to={`/agentstatus/${o.agent_id}/1`}>{o.agent_id}</Link><br/>
                        {/* <Badge variant={"outline"} mr={2}>{o.worker_version}</Badge><br/> */}
                        <Badge variant={"outline"} mr={2} colorScheme={"green"}>{o.gpu && o.gpu.name}</Badge>
                        <Badge variant={"outline"} mr={2} colorScheme={"blue"}>{Math.ceil(vram/1024)}GB</Badge>
                        <Badge variant={"outline"} mr={2} colorScheme={"blue"}>{Math.ceil(temperature)}C</Badge>
                      </Skeleton>
                    </Td>
                    <Td>
                      <JsonView 
                          shortenTextAfterLength={0}
                          value={o.filter?o.filter:{}} 
                          style={nordTheme}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                      />
                    </Td>
                    <Td>
                      <JsonView 
                          shortenTextAfterLength={0}
                          value={o.worker_status?o.worker_status:{}} 
                          style={nordTheme}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                      />
                    </Td>
                    <Td>
                      <Skeleton isLoaded={!loading}>
                      {(o.stats && o.stats.progress)?<>
                        <Progress rounded={"full"} colorScheme={"green"} value={(o.stats.progress.progress*100)} />
                        <Text fontSize={"xs"}>{o.stats.task} ({o.stats.progress.value}/{o.stats.progress.max})
                         </Text>
                      </>:"Waiting"}
                      </Skeleton>
                    </Td>
                    <Td>
                      <Skeleton isLoaded={!loading}>
                        {(() => {
                          switch (o.mode) {
                            case 'dreaming':
                              return '🌜';
                            case 'working':
                              return '⚒️';
                            case 'awake':
                              return '👀';
                            default:
                              return o.mode;
                          }
                        })()}
                      </Skeleton>
                    </Td>
                    <Td>
                      <Skeleton isLoaded={!loading}>
                        {o.score}
                      </Skeleton>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>}
    </Box>
}