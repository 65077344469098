import { ChakraProvider, Flex } from "@chakra-ui/react"
import { SiteRouter } from "./components/shared/SiteRouter"
import { UserProvider } from "./contexts/UserContext"
import "./App.css"

function App() {
  return <ChakraProvider>
    <Flex direction="column" h="100vh" className="App">
      <UserProvider>
        <SiteRouter />
      </UserProvider>
    </Flex>
  </ChakraProvider>
}

export default App
