import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    NumberInput,
    NumberInputField,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper,
    Select,
    Button,
    FormControl,
    FormLabel,
    Switch,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    useDisclosure
  } from '@chakra-ui/react';
import { getEnvVariable } from 'utils/env';

function AugmentModal(props){

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const token = props.token
    const [augmentation, setAugmentation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModified, setIsModified] = useState(false);
    const [data, setData] = useState(null);
    const [upscaleEndpoint, setUpscaleEndpoint] = useState(`${REACT_APP_api_url}/upscale`);
    const params = useParams()

    function doUpscale(){
        setLoading(true)
        // console.log(augmentation)
        fetch(`${upscaleEndpoint}/${params.uuid}`,{
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ 
            uuid: data.uuid,
            augmentation : augmentation
          })
        })
        .then((response) => {
          return response.json();
        })
        .then((d) => {
          let updatedData = JSON.parse(JSON.stringify(data));
          updatedData.upscale_requested = true
          setData({ ...data, ...updatedData });
          setIsModified(true)
          return d;
        });
      }

    return <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Augment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {augmentation && <>
            <FormControl>
            <FormLabel htmlFor="private">Face Enhance</FormLabel>
            <Switch
                id="face_enhance"
                isChecked={(augmentation.face_enhance===true)?true:false}
                onChange={(event) => {
                let updatedAugmentation = JSON.parse(JSON.stringify(augmentation));
                updatedAugmentation.face_enhance = event.target.checked ? true : false;
                setAugmentation({ ...augmentation, ...updatedAugmentation });
                }}
            />
            </FormControl>
            <FormControl>
            <FormLabel htmlFor="model_name">Model</FormLabel>
            <Select id = "model_name" placeholder='Select a model' value={augmentation.model_name} onChange={(event) => {
                let updatedAugmentation = JSON.parse(JSON.stringify(augmentation));
                let value = event.target.selectedOptions[0].value;
                updatedAugmentation.model_name = value
                setAugmentation({ ...augmentation, ...updatedAugmentation});
            }}>
            {
            [
                {"key" : "RealESRGAN_x4plus", "text" : "RealESRGAN_x4plus"},
                {"key" : "RealESRGAN_x4plus_anime_6B", "text" : "RealESRGAN_x4plus_anime_6B"},
                {"key" : "RealESRGAN_x2plus", "text" : "RealESRGAN_x2plus"},
                // {"key" : "realesr-general-x4v3.pth", "text" : "realesr-general-x4v3.pth"}
                // {"key" : "realesr-animevideov3", "text" : "realesr-animevideov3"}
            ].map(shape=>{
                return <option value={shape.key}>{shape.text}</option>
            })
            }
            </Select>
            </FormControl>
            <FormControl>
            <FormLabel htmlFor="outscale">Scale</FormLabel>
            <NumberInput
                id="outscale"
                step={1}
                value={augmentation.outscale}
                min={1}
                max={4}
                clampValueOnBlur={true}
                onChange={(value) => {
                let updatedAugmentation = JSON.parse(JSON.stringify(augmentation));
                updatedAugmentation.outscale = parseInt(value)
                setAugmentation({ ...augmentation, ...updatedAugmentation});
                }}
            >
                <NumberInputField />
                <NumberInputStepper>
                <NumberIncrementStepper/>
                <NumberDecrementStepper/>
                </NumberInputStepper>
            </NumberInput>
            </FormControl>
        </>}
        </ModalBody>

        <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>Cancel</Button>
            <Button colorScheme='green' mr={3} onClick={()=>{               
            doUpscale()
            onClose()
            }}>Augment</Button>
            {/* <Button variant='ghost'>Secondary Action</Button> */}
        </ModalFooter>
        </ModalContent>
    </Modal>
}

export default AugmentModal;