import React, { useState, useCallback } from 'react';
// import { useDropzone } from 'react-dropzone';
import { Box, List, ListItem, Image } from '@chakra-ui/react';
// import { PNGMetadata } from 'image-metadata';

const PngInfo = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    // const file = acceptedFiles[0];
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   setImageSrc(reader.result);
    //   PNGMetadata.fromBlob(file)
    //     .then(meta => setMetadata(meta))
    //     .catch(error => console.log(error));
    // }
    // reader.readAsDataURL(file);
  }, []);
  
  // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'image/png'});

  return (
    <Box>
    </Box>
  );
};

export default PngInfo;
