import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const { token } = useAuth();
    const [fetching, setFetching] = useState(false);
    const [presets, setPresets] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(i => i + 1);
    };

    useEffect(() => {
        if (token) {
            const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url);
            setFetching(true);
            Promise.all([
                fetch(`${REACT_APP_api_url}/mypresets`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({})
                }).then(response => response.json()),
                fetch(`${REACT_APP_api_url}/v3/my_datasets`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                }).then(response => response.json())
            ]).then(([presetsData, datasetsData]) => {
                setPresets(presetsData);
                setDatasets(datasetsData);
                setFetching(false);
            }).catch(e => {
                console.error(e);
                setPresets([]);
                setDatasets([]);
                setFetching(false);
            })
        } else {
            setPresets([]);
            setDatasets([]);
            setFetching(false);
        }
    }, [token, refreshIndex]);

    const value = {
        presets,
        datasets,
        fetching,
        refresh
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};