import { BiShuffle } from 'react-icons/bi';
import { FaRegKeyboard } from "react-icons/fa";
import { PropBox } from './PropBox';
import { PromptTextarea } from 'components/shared/PromptTextarea';
import { useState } from 'react';
import { ButtonGroup, Button, IconButton, Tooltip, VStack, Tabs, Tab, TabList, TabPanels, TabPanel, 
    Modal, useDisclosure, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';

/**
 * Renders a component for prompt options.
 *
 * @param {Object} value - The current value of the prompts.
 * @param {Function} onChange - The function to handle changes in the prompts.
 * @returns {JSX.Element} The rendered component.
 */
export const PromptOptions = ({
    value={},
    onChange = () => { console.warn("PromptOptions onChange not implemented") },
    includeNegative = true
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    // const [selectedPrompt, setSelectedPrompt] = useState("")
    const [selectedConfig, setSelectedConfig] = useState({})
    const [modalPrompt, setModalPrompt] = useState("")

    const makeTab = (config) => {
        const val = value[config?.param]?value[config.param]:""
        return {
            tab: <Tab key={config.title}>{config.title}</Tab>,
            panel: <TabPanel key={`${config.title}-panel`} w={"full"}><VStack align={"flex-start"}>
                <PromptTextarea
                    w={"full"}
                    m={0}
                    id={config.param}
                    type="text"
                    value={val}
                    onChange={e => onChange && onChange({ ...value, [config.param]: e.target.value })}
                />
                <ButtonGroup>
                    <Tooltip hasArrow label="Shuffle prompt">
                        <IconButton
                            variant={"outline"}
                            onClick={() => {
                                const phrases = val.split(',')
                                for (let i = phrases.length - 1; i > 0; i--) {
                                    const j = Math.floor(Math.random() * (i + 1));
                                    [phrases[i], phrases[j]] = [phrases[j], phrases[i]];
                                }
                                onChange && onChange({ ...value, [config.param]: phrases.join(',') })
                            }}
                            icon={<BiShuffle />}
                            style={{ margin: "0px" }}
                        />
                    </Tooltip>
                    <Tooltip hasArrow label="Enlarge View">
                        <IconButton
                            variant={"outline"}
                            onClick={() => {
                                setModalPrompt(val)
                                setSelectedConfig(config)
                                onOpen()
                            }}
                            icon={<FaRegKeyboard />}
                            style={{ margin: "0px" }}
                        />
                    </Tooltip>
                </ButtonGroup>
            </VStack></TabPanel>
        }
    }
    let tabs = []
    if (value && value.useClipLG === true) {
        tabs.push(makeTab({ param: "promptClipL", title: "Positive CLIP L" }))
        tabs.push(makeTab({ param: "promptClipG", title: "Positive CLIP G" }))
        tabs.push(makeTab({ param: "negative_promptClipL", title: "Negative CLIP L" }))
        tabs.push(makeTab({ param: "negative_promptClipG", title: "Negative CLIP G" }))
    } else {
        tabs.push(makeTab({ param: "positive", title: "Positive" }))
        if (includeNegative) tabs.push(makeTab({ param: "negative", title: "Negative" }))
    }

    return (value !== undefined) ? <PropBox type="prompts" label="Prompts" value={value} onChange={v => onChange && onChange(v)}>
        <>
            <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent height={"full"}>
                    <ModalHeader>{selectedConfig.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <PromptTextarea
                        type="text"
                        value={modalPrompt}
                        onChange={e => {setModalPrompt(e.target.value)}}
                    />
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="solid" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="solid" mr={3} colorScheme= {"green"} onClick={e=>{
                            onChange && onChange({ ...value, [selectedConfig.param]: modalPrompt })
                            onClose()
                        }}>
                            Apply
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Tabs w={"full"}>
                {/* {pipeline && pipeline.type === "sdxl-1.0" && <Checkbox isChecked={value.useClipLG} onChange={e => updateValue({ ...value, "useClipLG": e.currentTarget.checked })}>Use CLIP-G and CLIP-L</Checkbox>} */}
                <TabList>
                    {tabs.map(o => o.tab)}
                </TabList>
                <TabPanels>
                    {tabs.map(o => o.panel)}
                </TabPanels>
            </Tabs>
        </>
    </PropBox> : <span>Error</span>
}