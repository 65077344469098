// React
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

// Pages
import Hero from "../Pages/HomePage"
import UserGalleryPage from "../Pages/UserGalleryPage"
import BrowsePage from "../Pages/BrowsePage"
import ModelsPage from "../Models/ModelsPage"
import MyInvitesPage from "../Pages/MyInvitesPage"
import PiecePage from "../Pages/PiecePage"
import MyProfile from "../Pages/MyProfile"
import StatusPage from "../Pages/StatusPage"
import NotFoundPage from "../Pages/NotFoundPage"
import Workshop from "components/Workshop/Workshop"
import Albums from "components/Albums/Albums"
import Editor from "components/Editor"
import UsersPage from "../Pages/UsersPage"
import TrainingPage from "../Pages/TrainingPage"

// Components
import Nav from "./Nav"
import {FdFooter} from "./FdFooter"

// Chakra
import { Box } from "@chakra-ui/react"

/**
 * Renders the site router component.
 * @returns {JSX.Element} The rendered site router component.
 */
export const SiteRouter = ()=>{
    
    function routes(){
        return <Routes key={"routes"}>
            {/* Users page */}
            <Route path="/users" element={<UsersPage />} />
            {/* Gallery pages */}
            <Route path="/browse/:type/" element={<BrowsePage />} />
            <Route path="/gallery/:user_id" element={<UserGalleryPage />} />
            <Route path="/gallery/:user_id/:type" element={<UserGalleryPage />} />

            {/* Training page */}
            <Route path="/train/:tab" element={<TrainingPage />} />
            <Route path="/train/:tab/:id" element={<TrainingPage />} />

            {/* Non-gallery pages */}
            <Route path="/" element={<Hero />} />
            <Route path="/piece/:uuid" element={<PiecePage />} />
            <Route path="/myinvites" element={<MyInvitesPage />}></Route>
            <Route path="/stable-diffusion/" element={<Workshop architecture="stable-diffusion" key="stable-diffusion"/>} />
            <Route path="/stable-diffusion/:uuid" element={<Workshop architecture="stable-diffusion" key="stable-diffusion"/>} />
            <Route path="/flux/" element={<Workshop architecture="flux" key="flux"/>} />
            <Route path="/flux/:uuid" element={<Workshop architecture="flux" key="flux"/>} />
            <Route path="/editor/" element={<Editor />} />
            <Route path="/editor/:id" element={<Editor />} />
            <Route path="/albums/" element={<Albums />} />
            <Route path="/status" element={<StatusPage />} />
            <Route path="/status/:type" element={<StatusPage />} />
            <Route path="/status/:type/:subtype" element={<StatusPage />} />
            {/* Models */}
            <Route path="/models" element={<ModelsPage />} />
            <Route path="/models/model/:id" element={<ModelsPage />} />
            <Route path="/models/:mode" element={<ModelsPage />} />
            <Route path="/models/:mode/:direction/:type" element={<ModelsPage />} />
            <Route path="/models/:mode/:direction/:type/:order" element={<ModelsPage />} />
            {/* User Account */}
            <Route path="/myprofile" element={<MyProfile />} />

            {/* Fallback route */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    }

    return <Router>
        <Nav />
        <Box p={5} overflowY="auto" flex={1}>{routes()}</Box>
        <FdFooter />
    </Router>
}