import { Flex, FormLabel, Tooltip, Select } from '@chakra-ui/react';
import { PropBox } from './PropBox';
import { NumberInput, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, NumberInputField } from '@chakra-ui/react';

/**
 * Renders the FreeUOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the options.
 * @param {Function} props.onChange - The callback function to handle value changes.
 * @returns {JSX.Element} The rendered FreeUOptions component.
 */
export const FreeUOptions = ({
    value,
    onChange = () => console.warn("No FreeUOptions onChange handler.")
}) => {

    const target_blocks = ["input_block", "middle_block", "output_block", "all"]

    const multiscale_modes = ["Default", "Bandpass", "Low-Pass", "High-Pass", "Pass-Through",
        "Gaussian-Blur", "Edge-Enhancement", "Sharpen", "Multi-Bandpass", "Multi-Low-Pass", "Multi-High-Pass",
        "Multi-Pass-Through", "Multi-Gaussian-Blur", "Multi-Edge-Enhancement", "Multi-Sharpen"]

    return <PropBox type="freeu" label="FreeU Options" value={value} onChange={v => onChange(v)}>
        {/* Target Block */}
        <Flex w={"full"}>
            <Tooltip hasArrow label={"Target Block"} openDelay={250}>
                <FormLabel whiteSpace={"nowrap"} htmlFor={"target_block"}>Target Block</FormLabel>
            </Tooltip>
            <Select id="target_block" onChange={e => {
                onChange({ ...value, "target_block": e.currentTarget.value })
            }}>{target_blocks.map((target_block) => {
                return <option value={target_block} key={target_block}>{target_block}</option>
            })}</Select>
        </Flex>
        {/* Multiscale Mode */}
        <Flex w={"full"}>
            <Tooltip hasArrow label={"Multiscale Mode"} openDelay={250}>
                <FormLabel whiteSpace={"nowrap"} htmlFor={"multiscale_mode"}>Multiscale Mode</FormLabel>
            </Tooltip>
            <Select id="multiscale_mode" onChange={e => {
                onChange({ ...value, "multiscale_mode": e.currentTarget.value })
            }}>{multiscale_modes.map((multiscale_mode) => {
                return <option value={multiscale_mode} key={multiscale_mode}>{multiscale_mode}</option>
            })}</Select>
        </Flex>
        {[
            { id: "b1", tt: "Output Multiplier", min: 0.0, max: 10.0, step: 0.10 },
            { id: "b2", tt: "Output Multiplier", min: 0.0, max: 10.0, step: 0.10 },
            { id: "s1", tt: "Fourier transform scale strength", min: 0.0, max: 10.0, step: 0.10 },
            { id: "s2", tt: "Fourier transform scale strength", min: 0.0, max: 10.0, step: 0.10 },
            { id: "multiscale_strength", tt: "Strength of scaling", min: 0.0, max: 1.0, step: 0.01 },
            { id: "slice_b1", tt: "The size of the array slice for b1 operation", min: 64, max: 1280, step: 1 },
            { id: "slice_b2", tt: "The size of the array slice for b2 operation", min: 64, max: 640, step: 1 },
        ].map(p => {
            return <Flex w={"full"} key={p.id}>
                <Tooltip hasArrow label={p.tt} openDelay={250}>
                    <FormLabel whiteSpace={"nowrap"} htmlFor={p.id}>{p.id}</FormLabel>
                </Tooltip>
                <NumberInput id={p.id} value={value[p.id]} min={p.min} max={p.max} step={p.step} clampValueOnBlur={true} onChange={v => { onChange({ ...value, [p.id]: v }) }}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Flex>
        })}
    </PropBox>
}