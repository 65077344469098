import { useDropzone } from 'react-dropzone';
import { Box, Text } from '@chakra-ui/react';

/**
 * FileDropZone component for handling file drops and pastes.
 *
 * @component
 * @param {Function} onDrop - The handler function for when a file is dropped or pasted.
 * @returns {JSX.Element} The FileDropZone component.
 */
const FileDropZone = ({
  onDrop = () => { console.warn("No onDrop handler for FileDropZone") }
}) => {

  const handleDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      handleFile(file)
    })
  }

  const handleFile = file => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (file.type === 'image/webp') {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(this, 0, 0);
          // const base64jpeg = canvas.toDataURL('image/jpeg');
          // onDrop(base64jpeg);
          canvas.toBlob(blob => { onDrop(blob) }, 'image/jpeg');
        }
      } else {
        onDrop(file);
      }
    };
    reader.onerror = () => {
      console.error('A problem occurred when reading the file.')
    }
    reader.readAsBinaryString(file);
    // reader.readAsDataURL(file);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop : handleDrop,
    accept: {
      'image/png': ['.png'], 
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/webp' : ['.webp']
    },
    maxFiles: 1,
  });

  return (
    <Box {...getRootProps()} p={4} flex={1} borderWidth={1} borderRadius="md" borderColor={isDragActive ? 'green.500' : 'gray.500'} style={{cursor:"pointer"}}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <Text>Drop image here...</Text> :
          <Text>Drag & drop an image here, or click here to browse.</Text>
      }
    </Box>
  );
};

export default FileDropZone;
