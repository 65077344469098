import { getEnvVariable } from 'utils/env';

const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)

export function conformJob(data) {
  let defaults = generateStarterJob(true).params
  if(!data) data = {}
  data.params = data.params || {}
  for (let key in defaults) {
    if (data && data.params && !(key in data.params)) {
      data.params[key] = JSON.parse(JSON.stringify(defaults[key]));
    } else {
      if (defaults[key] !== undefined) conformProperty(defaults[key], data.params[key])
    }
  }
  return data
}

export function conformProperty(defaultObj, newObj) {
  for (let key in defaultObj) {
    if (defaultObj.hasOwnProperty(key)) {
      if (typeof defaultObj[key] === 'object') {
        // If the property is an array, conform each object in newObj array
        // to the structure of the example entry in defaultObj array
        if (Array.isArray(defaultObj[key]) && defaultObj[key].length > 0) {
          let exampleEntry = defaultObj[key][0];
          if (!newObj[key]) {
            newObj[key] = [];
          }
          for (let i = 0; i < newObj[key].length; i++) {
            if (typeof newObj[key][i] !== 'object') {
              // If it's not an object, set it to the exampleEntry 
              // This assumes that arrays contain objects. Adjust if different types can be inside
              newObj[key][i] = JSON.parse(JSON.stringify(exampleEntry));
            } else {
              conformProperty(exampleEntry, newObj[key][i]);
            }
          }
        }
        // If the property is an object, recursively conform nested properties
        else {
          if (!newObj[key]) {
            console.log(`Object '${key}' not found.`)
            newObj[key] = {};  
          }
          conformProperty(defaultObj[key], newObj[key]);
        }
      } else {
        if (!newObj.hasOwnProperty(key)) {
          newObj[key] = defaultObj[key];
        }
      }
    }
  }
}
export async function compile(j, options) {
  let { batchSize, priority, toast, onComplete, token, isPrivate, debug, parent_id } = options
  if (isPrivate === undefined) isPrivate = false
  let headers = {
    Authorization: `Bearer ${token}`
  }
  let params = j.params
  
  j.private = isPrivate
  j.batch_size = batchSize
  j.priority = priority
  j.parent_id = parent_id
  
  j.params["debug"] = debug
  
  let imageOperations = []
  // Roop Image
  if(params.roop.enabled && params.roop.image.content){
    imageOperations.push({
      content: params.roop.image.content,
      hash: params.roop.image.hash
    })
    delete params.roop.image.content
  }
  // ControlNet Image(s)
  if(params.controlnet.enabled){
    params.controlnet.layers.forEach(layer=>{
      if(layer.image.content){
        imageOperations.push({
          content: layer.image.content,
          hash: layer.image.hash
        })
        // layer.image.content = undefined
      }
    })
  }
  // IPAdapter Image(s)
  if(params.ipadapter.enabled){
    params.ipadapter.layers.forEach(layer=>{
      if(layer.image.content){
        imageOperations.push(layer.image)
        // layer.image.content = undefined
      }
    })
  }
  // img2img Image
  if(params.mainPass.img2img.enabled && params.mainPass.img2img.image.content){
    imageOperations.push({
      content: params.mainPass.img2img.image.content,
      hash: params.mainPass.img2img.image.hash
    })
    // delete params.mainPass.img2img.image.content
  }
  // img2img Mask
  if(params.mainPass.img2img.enabled && params.mainPass.img2img.inpaint.enabled && params.mainPass.img2img.inpaint.mask.content){
    imageOperations.push({
      content: params.mainPass.img2img.inpaint.mask.content,
      hash: params.mainPass.img2img.inpaint.mask.hash
    })
    // delete params.mainPass.img2img.inpaint.mask.content
  }
  
  let imageOperationsPromises = imageOperations.map(async image=>{
    let hash = image.hash
    if(image.content instanceof Blob){
      hash = await calculateBlobSHA256(image.content)
    }
    console.log(`Hash: ${hash}`)
    const metadata = await fetch(`${REACT_APP_api_url}/v3/getimagehash/${hash}`).then(response => {
      return response.json()
    })
    if (metadata === null) {
      toast({
        title: "Uploading new image",
        description: `Hash : ${hash}`
      })
      const formData = new FormData();
      formData.append("file", image.content, hash);
      await fetch(`${REACT_APP_api_url}/v3/saveimagehash/${hash}`, {
        method: "POST",
        headers,
        body: formData
      }).then(response => {
        toast({
          title: "New image uploaded",
        })
        return response.json()
      }).then(data => {
        console.log(data)
      })
    }else{
      toast({
        title: "Image already uploaded"
      })
      console.log(metadata)
    }
  })
  await Promise.all(imageOperationsPromises)
  
  headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  async function afterLayerOperations() {
    const { success: mutateSuccess } = await fetch(
      `${REACT_APP_api_url}/v3/create/mutate`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ job: j }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        toast({
          title: "Job Received"
        });
        onComplete && onComplete();
        // onCloseHandler();
        return data;
      });

    if (mutateSuccess) {
      // Handle the success case
    }
  }
  Promise.all([]).then(afterLayerOperations);

}

export function sendToImg2Img(d) {
  let p = JSON.parse(JSON.stringify(d.params))
  p.mainPass.img2img.enabled = true
  p.mainPass.img2img.image.type = "url"
  p.mainPass.img2img.image.url = `${REACT_APP_images_url}/${d.location.png}`
  if (p.mainPass.sampler.denoise === 0) p.mainPass.sampler.denoise = 0.5
  p.roop.enabled = false
  p.faceDetailer.enabled = false
  return p
}

export function defaultControlNetLayer() {
  return {
    "image": {
      "hash": ""
    },
    "preprocessor": "Canny",
    "model": "control_v11p_sd15_canny.pth",
    "strength": 1.0,
    "start_percent": 0.0,
    "end_percent": 1.0
  }
}

export function defaultIPAdapterLayer() {
  return {
    "image": {
      "hash": ""
    },
    "inpaint": {
      "enabled": false,
      "mask": {
        "hash": ""
      }
    },
    "preset" : "STANDARD (medium strength)",
    "weight": 1.0,
    "weight_type" : "standard",
    "noise": 0.0
  }
}

export function defaultImg2Img() {
  return {
    enabled: false,
    image: {
      hash: ""
    },
    inpaint: {
      enabled: false,
      inpainting_fill: 1,  // ?
      full_res: true,
      full_res_padding: 32,
      mask_blur: 4,
      mask_invert: 1,
      noise_multiplier: 1,
      mask: {
        hash: ""
      }
    }
  }
}

// Main Pass
let mainPass = {
  img2img: defaultImg2Img(),
  prompts: {
    positive: "A beautiful painting of a singular lighthouse, shining its light across a tumultuous sea of blood by greg rutkowski and thomas kinkade, Trending on artstation",
    negative: "poor quality"
  },
  width: 512,
  height: 512,
  offset_noise: 0.0,
  useClipLG: false,
  promptClipL: "",
  promptClipG: "",
  negative_promptClipL: "",
  negative_promptClipG: "",
  model: {
    pipeline: {
      type: "sd-1.5",
      actual: {},
      custom: {}
    },
    sd_model_checkpoint: "cc6cb27103417325ff94f52b7a5d2dde45a7515b25c255d8e396c90014281516",
    loras_enabled: false,
    locons_enabled: false,
    ti_enabled: false,
    freeU : {
      enabled : false,
      b1 : 1.10,
      b2 : 1.20,
      s1 : 0.90,
      s2 : 0.20,
      target_block : "output_block",
      multiscale_mode : "Default",
      multiscale_strength : 1.0,
      slice_b1 : 640,
      slice_b2 : 320,
      b1_mode : "bislerp",
      b2_mode : "bislerp",
      b1_blend : 1.0,
      b2_blend : 1.0,
      threshold : 1,
      use_override_scales : "false",
      override_scales : ""
    },
    loras: [],
    locons: [],
    embeddings: [],
    clip_skip: 1
  },
  sampler: {
    cfg: 7.0,
    seed: -1,
    steps: 25,
    refiner_steps: 5,
    detail_level: 1.0,
    sampler_name: "euler_ancestral",
    scheduler: "normal",
    denoise: 1.0
  }
}

// Upscaler
let upscaler = {
  enabled: false,
  method: "Latent",
  scale: 2,
  use_main_sampler: true,
  use_main_model_pipeline: true,
  use_main_prompts: true,
  prompts: {
    positive: "highly detailed scene",
    negative: "blurry"
  },
  model: {
    pipeline: {
      type: "sd-1.5",
      actual: {},
      custom: {}
    },
    sd_model_checkpoint: "cc6cb27103417325ff94f52b7a5d2dde45a7515b25c255d8e396c90014281516",
    loras_enabled: false,
    locons_enabled: false,
    ti_enabled: false,
    freeU : {
      enabled : false,
      b1 : 1.10,
      b2 : 1.20,
      s1 : 0.90,
      s2 : 0.20,
      target_block : "output_block",
      multiscale_mode : "Default",
      multiscale_strength : 1.0,
      slice_b1 : 640,
      slice_b2 : 320,
      b1_mode : "bislerp",
      b2_mode : "bislerp",
      b1_blend : 1.0,
      b2_blend : 1.0,
      threshold : 1,
      use_override_scales : "false",
      override_scales : ""
    },
    loras: [],
    locons: [],
    embeddings: [],
    clip_skip: 1
  },
  sampler: {
    cfg: 7.0,
    seed: -1,
    steps: 25,
    refiner_steps: 5,
    detail_level: 1.0,
    sampler_name: "euler_ancestral",
    scheduler: "normal",
    denoise: 0.4
  }
}

// Face Detailer
let faceDetailer = {
  enabled: false,
  detection_model: "face_yolov8n.pt",
  prompts: {
    positive: "highly detailed face",
    negative: "ugly"
  },
  threshold: 0.3,
  dilation: 32,
  mask_blur: 5,
  crop_factor: 3.0,
  use_main_sampler: true,
  use_main_model_pipeline: true,
  use_main_prompts: false,
  model: {
    pipeline: {
      type: "sd-1.5",
      actual: {},
      custom: {}
    },
    sd_model_checkpoint: "cc6cb27103417325ff94f52b7a5d2dde45a7515b25c255d8e396c90014281516",
    loras_enabled: false,
    locons_enabled: false,
    ti_enabled: false,
    freeU : {
      enabled : false,
      b1 : 1.10,
      b2 : 1.20,
      s1 : 0.90,
      s2 : 0.20,
      target_block : "output_block",
      multiscale_mode : "Default",
      multiscale_strength : 1.0,
      slice_b1 : 640,
      slice_b2 : 320,
      b1_mode : "bislerp",
      b2_mode : "bislerp",
      b1_blend : 1.0,
      b2_blend : 1.0,
      threshold : 1,
      use_override_scales : "false",
      override_scales : ""
    },
    loras: [],
    locons: [],
    embeddings: [],
    clip_skip: 1
  },
  sampler: {
    cfg: 7.0,
    seed: -1,
    steps: 25,
    refiner_steps: 5,
    detail_level: 1.0,
    sampler_name: "euler_ancestral",
    scheduler: "normal",
    denoise: 0.4
  }
}

// Post Processing
let postProcessing = {
  skin_enhancement: false,
  skin_enhancement_strength: 0.9,
  texturize: false,
  texturize_strength: 0.9
}

export function generateStarterJob({ privatesettings }) {
  return {
    status: "new",
    architecture: "stable-diffusion",
    nsfw: false,
    private: privatesettings ? true : false,
    hide: false,
    review: true,
    priority: "medium",
    location: {
      png: null,
      jpg: null,
      thumbs: {
        "64": null,
        "128": null,
        "256": null,
        "512": null,
        "1024": null,
      }
    },
    params: {
      // Main Pass
      mainPass,
      // ControlNet
      controlnet: {
        enabled: false,
        layers: [defaultControlNetLayer()]
      },
      // IP-Adapter
      ipadapter: {
        enabled: false,
        layers: [defaultIPAdapterLayer()]
      },
      // Upscaler
      upscaler,
      // Roop
      roop: {
        enabled: false,
        image: {
          hash: ""
        },
        facenumbers: "0",
        facerestore: "none",
        model: "inswapper_128.onnx"
      },
      // Face Restore
      faceRestore: {
        enabled: false,
        model: "codeformer.pth",
        weight: 0.5
      },
      // Face Detailer
      faceDetailer,
      // Post-processing
      postProcessing
    }
  }
}

export async function calculateBlobSHA256(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function() {
      const arrayBuffer = this.result;
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      resolve(hashHex);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}


  export async function calculateSHA256(string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(string);

    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

export function sourceToName(source) {
  let sources = {
    reviews: "Unpublished",
    personal_pieces: "Private Gallery",
    pieces: "Published",
    queue: "Queued"
  }
  return sources[source] ? sources[source] : "Unknown Source"
}

export function generateFaceRestoreList() {
  return [
    { "key": "none" },
    { "key": "codeformer.pth" },
    { "key": "GFPGANv1.4.pth" }
  ]
}

export function generateRoopModelList() {
  return [
    { "key": "inswapper_128.onnx" },
  ]
}

export function generateImg2imgResizeModeList() {
  return [
    { "key": 0, "text": "Just Resize" },
    { "key": 1, "text": "Crop and Resize" },
    { "key": 2, "text": "Resize and Fill" },
    { "key": 3, "text": "Just Resize (Latent Upscale)" }
  ]
}

export function generateAfterDetailerList() {
  return [
    { "key": "face_yolov8n.pt", "text": "face_yolov8n.pt" },
    { "key": "face_yolov8s.pt", "text": "face_yolov8s.pt" },
    { "key": "mediapipe_face_full", "text": "mediapipe_face_full" },
    { "key": "mediapipe_face_short", "text": "mediapipe_face_short" },
    { "key": "mediapipe_face_mesh", "text": "mediapipe_face_mesh" },
    { "key": "hand_yolov8n.pt", "text": "hand_yolov8n.pt" },
    { "key": "person_yolov8n-seg.pt", "text": "person_yolov8n-seg.pt" },
    { "key": "person_yolov8s-seg.pt", "text": "person_yolov8s-seg.pt" },
  ]
}

export function generateAfterDetailerControlNetList() {
  return [
    { "key": "None", "text": "None" },
    { "key": "control_v11p_sd15_inpaint [ebff9138]", "text": "control_v11p_sd15_inpaint [ebff9138]" }
  ]
}

export function generateUpscalerList() {
  return [
    { "key": "None" },
    // {"key" : "Latent (antialiased)"},
    // {"key" : "Latent (bicubic)"},
    // {"key" : "Latent (bicubic antialiased)"},
    // {"key" : "Latent (nearest)"},
    // {"key" : "Latent (nearest-exact)"},
    { "key": "Lanczos" },
    { "key": "Nearest" },
    { "key": "BSRGAN" },
    { "key": "ESRGAN_4x" },
    { "key": "R-ESRGAN 4x+" },
    { "key": "R-ESRGAN 4x+ Anime6B" },
    { "key": "LDSR" },
    { "key": "SwinIR_4x" },
    { "key": "4x_RealisticRescaler_100000_G.pth" },
    { "key": "4x-UltraSharp.pth" }
  ]
}

export function generateCommonComfyUpscalerList() {
  return [
    // {"key" : "LDSR"},
    { "key": "Latent" },
    { "key": "BSRGAN.pth" },
    { "key": "ESRGAN_4x.pth" },
    { "key": "RealESRGAN_x4plus.pth" },
    { "key": "RealESRGAN_x4plus_anime_6B.pth" },
    { "key": "SwinIR_4x.pth" },
    { "key": "4x_RealisticRescaler_100000_G.pth" },
    { "key": "4x-UltraSharp.pth" }
  ]
}

export function generateComfyUpscalerList() {
  return [
    { "key": "-- Common Upscalers --" },
    { "key": "Latent" },
    { "key": "BSRGAN.pth" },
    { "key": "ESRGAN_4x.pth" },
    { "key": "RealESRGAN_x4plus.pth" },
    { "key": "RealESRGAN_x4plus_anime_6B.pth" },
    { "key": "4x_RealisticRescaler_100000_G.pth" },
    { "key": "4x-UltraSharp.pth" },
    { "key": "SwinIR_4x.pth" },
    { "key": "-- Other Upscalers --" },
    { "key": "16xPSNR.pth" },
    { "key": "4x_CountryRoads_377000_G.pth" },
    { "key": "4x_fatal_Anime_500000_G.pth" },
    { "key": "4x_Fatality_Comix_260000_G.pth" },
    { "key": "4x_foolhardy_Remacri.pth" },
    { "key": "4x_Nickelback_70000G.pth" },
    { "key": "4x_NickelbackFS_72000_G.pth" },
    { "key": "4x_NMKD-Siax_200k.pth" },
    { "key": "4x_NMKDSuperscale_Artisoft_120000_G.pth" },
    { "key": "4x_NMKD-Superscale-Artisoftject_210000_G.pth" },
    { "key": "4x_NMKD-Superscale-SP_178000_G.pth" },
    { "key": "4x_NMKD-UltraYandere_300k.pth" },
    { "key": "4x_NMKD-UltraYandere-Lite_280k.pth" },
    { "key": "4x_NMKD-YandereNeoXL_200k.pth" },
    { "key": "4xPSNR.pth" },
    { "key": "4x_Valar_v1.pth" },
    { "key": "8x_NMKD-Superscale_150000_G.pth" },
    { "key": "8x_NMKD-Typescale_175k.pth" },
    { "key": "8xPSNR.pth" },
    { "key": "A_ESRGAN_Single.pth" },
    { "key": "BSRGANx2.pth" },
    { "key": "BSRNet.pth" },
    { "key": "LADDIER1_282500_G.pth" },
    { "key": "lollypop.pth" },
    { "key": "sudo_rife4_269.662_testV1_scale1.pth" },
    { "key": "WaifuGAN_v3_30000.pth" },
    { "key": "-- Post Processing --" },
    { "key": "1x_NMKD-h264Texturize_500k.pth" },
    { "key": "x1_ITF_SkinDiffDetail_Lite_v1.pth" },
    { "key": "1x_NMKD-BrightenRedux_200k.pth" },
    { "key": "1x_NMKDDetoon_97500_G.pth" },
    { "key": "1x_NMKD-YandereInpaint_375000_G.pth" },
    { "key": "1x_NoiseToner-Poisson-Detailed_108000_G.pth" },
    { "key": "1x_NoiseToner-Uniform-Detailed_100000_G.pth" },
    { "key": "1x_artifacts_dithering_alsa.pth" }
  ]
}

export function generateModelTypeList() {
  return [
    { key: "Checkpoint", text: "Checkpoint" },
    { key: "LORA", text: "LORA" },
    { key: "TextualInversion", text: "Textual Inversion" }
  ]
}

export function generateBaseModelTypeList() {
  return [
    { key: "flux", text: "Flux" },
    { key: "sd-1.5", text: "SD 1.x" },
    { key: "sd-xl", text: "SDXL" },
    { key: "sd-3.0", text: "SD 3.x" },
    { key: "pony", text: "Pony" },
  ]
}

export function generateComfyCnModelList() {
  return [
    { "key": "-- SD 1.5 Models --" },
    { "key": "control_v11e_sd15_ip2p_fp16.safetensors" },
    { "key": "control_v11e_sd15_shuffle_fp16.safetensors" },
    { "key": "control_v11f1p_sd15_depth_fp16.safetensors" },
    { "key": "control_v11p_sd15_canny_fp16.safetensors" },
    { "key": "control_v11p_sd15_inpaint_fp16.safetensors" },
    { "key": "control_v11p_sd15_lineart_fp16.safetensors" },
    { "key": "control_v11p_sd15_mlsd_fp16.safetensors" },
    { "key": "control_v11p_sd15_normalbae_fp16.safetensors" },
    { "key": "control_v11p_sd15_openpose_fp16.safetensors" },
    { "key": "control_v11p_sd15_scribble_fp16.safetensors" },
    { "key": "control_v11p_sd15_seg_fp16.safetensors" },
    { "key": "control_v11p_sd15_softedge_fp16.safetensors" },
    { "key": "control_v11p_sd15s2_lineart_anime_fp16.safetensors" },
    { "key": "control_v11f1e_sd15_tile_fp16.safetensors" },
    { "key": "qrCodeMonster_v20.safetensors" },
    { "key": "control_v1p_sd15_brightness.safetensors" },
    { "key": "control_v1p_sd15_illumination.safetensors" },
    { "key": "-- SD XL Models --" },
    { "key": "OpenPoseXL2.safetensors" },
    { "key": "controlnet-sd-xl-1.0-softedge-dexined.safetensors" },
    { "key": "depth-zoe-xl-v1.0-controlnet.safetensors" },
    { "key": "diffusers_xl_canny_full.safetensors" },
    { "key": "diffusers_xl_canny_mid.safetensors" },
    { "key": "diffusers_xl_canny_small.safetensors" },
    { "key": "diffusers_xl_depth_full.safetensors" },
    { "key": "control-lora-canny-rank128.safetensors" },
    { "key": "control-lora-canny-rank256.safetensors" },
    { "key": "control-lora-depth-rank128.safetensors" },
    { "key": "control-lora-depth-rank256.safetensors" },
    { "key": "control-lora-sketch-rank128.safetensors" },
    { "key": "control-lora-sketch-rank256.safetensors" },
    { "key": "control-lora-recolor-rank128.safetensors" },
    { "key": "control-lora-recolor-rank256.safetensors" },
  ]
}

export function generateIPAdapterPresetList() {
  return [
    { "key": "LIGHT - SD1.5 only (low strength)" },
    { "key": "STANDARD (medium strength)" },
    { "key": "VIT-G (medium strength)" },
    { "key": "PLUS (high strength)" },
    { "key": "PLUS FACE (portraits)" },
    { "key": "FULL FACE - SD1.5 only (portraits stronger)" }
  ]
}

export function generateComfyCnPreprocessorList() {
  return [
    { "key": "None" },
    // Edge Line
    { "key": "Canny" },
    { "key": "HEDPreprocessor" },
    { "key": "ScribblePreprocessor" },
    { "key": "FakeScribblePreprocessor" },
    { "key": "BinaryPreprocessor" },
    { "key": "PIDINetPreprocessor" },
    { "key": "LineArtPreprocessor" },  // coarse = disable
    { "key": "AnimeLineArtPreprocessor" },
    { "key": "Manga2Anime-LineArtPreprocessor" },
    // Depth
    { "key": "MiDaS-NormalMapPreprocessor" },  // a = 6.283, bg_threshold = 0.050
    { "key": "Zoe-DepthMapPreprocessor" },
    { "key": "BAE-NormalMapPreprocessor" },
    // Pose
    { "key": "OpenposePreprocessor" }, // detect_hand = enable, detect_body = enable, detect_face = enable , version = 'v1.1'
    { "key": "MediaPipe-HandPosePreprocessor" }, // detect_pose = enable, detect_hands = enable
    // Segmentation
    { "key": "OneFormer-COCO-SemSegPreprocessor" },
    { "key": "OneFormer-ADE20K-SemSegPreprocessor" },
    // Face Mesh
    { "key": "MediaPipe-FaceMeshPreprocessor" }, // max_faces = 10, min_confidence = 0.500
    // Color Style
    { "key": "ColorPreprocessor" },
    // Tile
    { "key": "TilePreprocessor" }, // pyrUp_iters = 3
    // Inpaint
    { "key": "InpaintPreprocessor" },  // image, mask
  ]
}

export function generateCnPreprocessorList() {
  const preprocessor_resolution = {
    "name": "Preprocessor Resolution",
    "value": 512,
    "low": 64,
    "high": 2048
  }
  return [
    { "key": "none", "text": "None" },
    {
      "key": "canny", "text": "canny", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "Canny Low Threshold",
          "value": 100,
          "low": 1,
          "high": 255,
        },
        "threshold_b": {
          "name": "Canny High Threshold",
          "value": 200,
          "low": 1,
          "high": 255,
        }
      }
    },
    {
      "key": "depth_lres", "text": "depth_lres", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "Remove Near %",
          "value": 0,
          "low": 0,
          "high": 100,
        },
        "threshold_b": {
          "name": "Remove Background %",
          "value": 0,
          "low": 0,
          "high": 100
        },
      }
    },
    {
      "key": "depth_lres++", "text": "depth_lres++", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "Remove Near %",
          "value": 0,
          "low": 0,
          "high": 100,
        },
        "threshold_b": {
          "name": "Remove Background %",
          "value": 0,
          "low": 0,
          "high": 100
        },
      }
    },
    { "key": "depth_midas", "text": "depth_midas", "params": { preprocessor_resolution } },
    { "key": "depth_zoe", "text": "depth_zoe" },
    { "key": "inpaint_global_harmonious", "text": "inpaint_global_harmonious" },
    { "key": "lineart", "text": "lineart" },
    { "key": "lineart_anime", "text": "lineart_anime" },
    { "key": "lineart_coarse", "text": "lineart_coarse" },
    {
      "key": "mlsd", "text": "mlsd", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "MLSD Value Threshold",
          "value": 0.1,
          "low": 0.01,
          "high": 2.0,
          "step": 0.01
        },
        "threshold_b": {
          "name": "MLSD Distance Threshold",
          "value": 0.1,
          "low": 0.01,
          "high": 20.0,
          "step": 0.5
        },
      }
    },
    {
      "key": "mediapipe_face", "text": "mediapipe_face", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "Max Faces",
          "value": 1,
          "low": 1,
          "high": 10,
          "step": 1
        },
        "threshold_b": {
          "name": "Min Face Confidence",
          "value": 0.5,
          "low": 0.001,
          "high": 1.0,
          "step": 0.01
        },
      }
    },
    { "key": "normal_bae", "text": "normal_bae" },
    {
      "key": "normal_midas", "text": "normal_midas", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "Normal Background Threshold",
          "value": 0.4,
          "low": 0,
          "high": 1,
          "step": 0.01
        }
      }
    },
    { "key": "hed", "text": "hed", "params": { preprocessor_resolution } },
    { "key": "hed_safe", "text": "hed_safe", "params": { preprocessor_resolution } },
    { "key": "openpose", "text": "openpose", "params": { preprocessor_resolution } },
    { "key": "openpose_face", "text": "openpose_face" },
    { "key": "openpose_faceonly", "text": "openpose_faceonly" },
    { "key": "openpose_full", "text": "openpose_full", "params": { preprocessor_resolution } },
    { "key": "openpose_hand", "text": "openpose_hand" },
    {
      "key": "reference_only", "text": "reference_only", "params": {
        "threshold_a": {
          "name": "Style Fidelity",
          "value": 0.5,
          "low": 0.0,
          "high": 1.0,
          "step": 0.01
        }
      }
    },
    {
      "key": "reference_adain", "text": "reference_adain", "params": {
        "threshold_a": {
          "name": "Style Fidelity",
          "value": 0.5,
          "low": 0.0,
          "high": 1.0,
          "step": 0.01
        }
      }
    },
    {
      "key": "reference_adain+attn", "text": "reference_adain+attn", "params": {
        "threshold_a": {
          "name": "Style Fidelity",
          "value": 0.5,
          "low": 0.0,
          "high": 1.0,
          "step": 0.01
        }
      }
    },
    {
      "key": "scribble_hed", "text": "scribble_hed", "params": {
        preprocessor_resolution
      }
    },
    { "key": "scribble_pidinet", "text": "scribble_pidinet" },
    {
      "key": "scribble_xdog", "text": "scribble_xdog", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "XDoG Threshold",
          "value": 32,
          "low": 1,
          "high": 64,
          "step": 1
        }
      }
    },
    { "key": "seg_ofade20k", "text": "seg_ofade20k" },
    { "key": "seg_ofcoco", "text": "seg_ofcoco" },
    { "key": "seg_ufade20k", "text": "seg_ufade20k" },
    { "key": "shuffle", "text": "shuffle" },
    { "key": "softedge_hed", "text": "softedge_hed" },
    { "key": "softedge_hedsafe", "text": "softedge_hedsafe" },
    { "key": "softedge_pidinet", "text": "softedge_pidinet" },
    { "key": "softedge_pidisafe", "text": "softedge_pidisafe" },
    { "key": "t2ia_color_grid", "text": "t2ia_color_grid" },
    { "key": "t2ia_sketch_pidi", "text": "t2ia_sketch_pidi" },
    { "key": "t2ia_style_clipvision", "text": "t2ia_style_clipvision" },
    {
      "key": "threshold", "text": "threshold", "params": {
        preprocessor_resolution,
        "threshold_a": {
          "name": "Binarization Threshold",
          "value": 127,
          "low": 0,
          "high": 255
        }
      }
    },
    { "key": "tile_gaussian", "text": "tile_gaussian" },
    {
      "key": "tile_resample", "text": "tile_resample", "params": {
        "threshold_a": {
          "name": "Down Sampling Rate",
          "value": 1.0,
          "low": 1.0,
          "high": 8.0,
          "step": 0.01
        }
      }
    }
  ]
}

export function generateSdModelList(options) {
  let headers = {
    'Content-Type': 'application/json'
  }
  if (options && options.token) {
    headers["Authorization"] = `Bearer ${options.token}`
  }
  if (options.pipeline.type === "sd-1.5") {

  }
  return fetch(`${REACT_APP_api_url}/v3/modellist`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(options.pipeline)
  }).then((response) => {
    return response.json()
  }).then(modelList => {
    let models = []
    let model_hashes = {}
    modelList.forEach(model => {
      if (!model_hashes[model.hash]) {
        models.push(model)
        model_hashes[model.hash] = true
      }
    })
    return models
  })
}

export function generateComfySdSamplerList() {
  return [
    { "key": "euler" },
    { "key": "euler_cfg_pp" },
    { "key": "euler_ancestral" },
    { "key": "euler_ancestral_cfg_pp" },
    { "key": "heun" },
    { "key": "heunpp2" },
    { "key": "dpm_2" },
    { "key": "dpm_2_ancestral" },
    { "key": "lms" },
    { "key": "dpm_fast" },
    { "key": "dpm_adaptive" },
    { "key": "dpmpp_2s_ancestral" },
    { "key": "dpmpp_2s_ancestral_cfg_pp" },
    { "key": "dpmpp_sde" },
    { "key": "dpmpp_sde_gpu" },
    { "key": "dpmpp_2m" },
    { "key": "dpmpp_2m_sde" },
    { "key": "dpmpp_2m_sde_gpu" },
    { "key": "dpmpp_3m_sde" },
    { "key": "dpmpp_3m_sde_gpu" },
    { "key": "ddpm" },
    { "key": "lcm" },
    { "key": "ipndm" },
    { "key": "ipndm_v" },
    { "key": "deis" },
    { "key": "ddim" },
    { "key": "uni_pc" },
    { "key": "uni_pc_bh2" }
  ]
}

export function generateComfySdSchedulerList() {
  return [
    { "key": "normal" },
    { "key": "karras" },
    { "key": "exponential" },
    { "key": "sgm_uniform" },
    { "key": "simple" },
    { "key": "ddim_uniform" },
    { "key": "beta" }
  ]
}

export function rotateImageData(imageData) {
  const { width, height, data } = imageData;
  const rotatedData = new Uint8ClampedArray(width * height * 4);

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const i = (y * width + x) * 4;
      const rotatedI = ((width - x - 1) * height + y) * 4;

      rotatedData[rotatedI] = data[i];
      rotatedData[rotatedI + 1] = data[i + 1];
      rotatedData[rotatedI + 2] = data[i + 2];
      rotatedData[rotatedI + 3] = data[i + 3];
    }
  }

  return new ImageData(rotatedData, height, width);
}

export function generateCheckboardImage(width, height, squareSize) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = width
  canvas.height = height

  context.fillStyle = "#f2f2f2";
  context.fillRect(0, 0, width, height);

  context.fillStyle = "#ffffff";
  for (let x = 0; x < width; x += squareSize * 2) {
    for (let y = 0; y < height; y += squareSize * 2) {
      context.fillRect(x, y, squareSize, squareSize);
      context.fillRect(x + squareSize, y + squareSize, squareSize, squareSize);
    }
  }

  return canvas.toDataURL();
}