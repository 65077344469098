import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Flex } from '@chakra-ui/react';

/**
 * Renders the author of a dream.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.userdets - The details of the user.
 * @param {string} props.userdets.user_str - The user's unique identifier.
 * @param {string} [props.userdets.picture] - The URL of the user's picture.
 * @param {string} [props.userdets.avatar] - The URL of the user's avatar.
 * @param {string} [props.userdets.nickname] - The user's nickname.
 * @param {string} [props.userdets.display_name] - The user's display name.
 * @returns {JSX.Element} The rendered DreamAuthor component.
 */
export function DreamAuthor(props) {
  return (
    <Link to={`/gallery/${props.userdets.user_str}/1`}>
      <Flex mt="3" mb="3">
        <Image
          borderRadius="full"
          boxSize="60px"
          mr="3"
          src={
            props.userdets.picture?props.userdets.picture:props.userdets.avatar?props.userdets.avatar:'/avatar-placeholder.gif'
          }
          alt={`Avatar of ${props.userdets.nickname?props.userdets.nickname:props.userdets.display_name?props.userdets.display_name:"Unknown"}`}
        />
      </Flex>
    </Link>
  );
}
