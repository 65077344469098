import React from 'react'
import { Heading } from '@chakra-ui/react'
import ModelBrowser from './ModelBrowser';

export default function ModelsPage() {
  return <>
    <Heading>Models</Heading>
    <ModelBrowser />
  </>
}
