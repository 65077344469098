import { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { PropertyHeader } from './PropertyHeader';

/**
 * Renders a property box component.
 *
 * @param {number} h - The height of the box.
 * @param {ReactNode} children - The content of the box.
 * @param {any} value - The value of the property.
 * @param {string} type - The type of the property.
 * @param {string} label - The label of the property.
 * @param {Array} extras - Additional properties.
 * @param {function} onChange - The callback function for value change.
 * @param {boolean} clipboard - Indicates if clipboard functionality is enabled.
 * @param {boolean} fetching - Indicates if data is being fetched.
 * @param {boolean} header - Indicates if the header should be displayed.
 * @param {boolean} collapsible - Indicates if the box is collapsible.
 * @returns {ReactNode} The rendered property box component.
 */
export const PropBox = ({
    h, children, value, type, label, extras=[], clipboard, fetching, header, collapsible = true,
    onChange = () => {console.warn("No PropBox onChange handler found.")}
}) => {
    const [collapsed, setCollapsed] = useState(false);

    return <Box borderWidth={1} borderRadius={"md"} w={"full"} h={h}>
        <Flex h={"100%"} direction={"column"}>
            <Box flexShrink={0}>
                {header !== false && <PropertyHeader
                    fetching={fetching}
                    type={type}
                    label={label}
                    value={value}
                    extras={extras}
                    clipboard={clipboard}
                    onChange={v => onChange && onChange(v)}
                    onCollapse={e => { setCollapsed(true) }}
                    onExpand={e => { setCollapsed(false) }}
                    collapsed={collapsed}
                    collapsible={collapsible}
                />}
            </Box>
            <Flex
                p={!collapsed && 3}
                h={collapsed ? "0px" : "auto"}
                overflowY={collapsed ? "hidden" : "auto"}
                gap={2}
                direction={"column"}
                visibility={collapsed ? 'hidden' : 'visible'}
            >
                {children}
            </Flex>
        </Flex>
    </Box >
}