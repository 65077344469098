import {
  Box, Switch, FormLabel, Tooltip, Flex, 
  Slider, SliderThumb, SliderTrack, SliderFilledTrack,
} from '@chakra-ui/react';

import { ImageOptions } from './ImageOptions';
import { PropBox } from './PropBox';
import { MaskEditor } from './MaskEditor';

/**
 * Renders the Img2ImgOptions component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the options.
 * @param {Function} props.onChange - The handler function for value change.
 * @param {boolean} props.denoise - The current denoise value.
 * @param {Function} props.onDenoiseChange - The handler function for denoise value change.
 * @returns {JSX.Element} The rendered Img2ImgOptions component.
 */
export const Img2ImgOptions = ({value, onChange, denoise, onDenoiseChange,
  includeInpaint = true
}) => {

  const updateValue = v => {onChange?onChange(v):console.warn("No onChange handler")}
  const updateDenoise = v => {onDenoiseChange?onDenoiseChange(v):console.warn("No Denoise Change Handler.")}

  return value ? <PropBox type="img2img" label="img2img Options" value={value} onChange={v => updateValue(v)}>
      {includeInpaint && <Flex>
        <Tooltip hasArrow label="Enable inpainting"openDelay={250}>
          <FormLabel htmlFor="inpaint_enabled">Inpaint</FormLabel>
        </Tooltip>
        <Switch
          id="inpaint_enabled"
          isChecked={value?.inpaint?.enabled}
          onChange={e => updateValue({ ...value, inpaint: { ...value.inpaint, enabled: e.target.checked } })}
        />
      </Flex>}
      <Tooltip hasArrow label="Denoise Strength" openDelay={250}>
          <FormLabel htmlFor="denoise">Denoise Strength ({denoise})</FormLabel>
      </Tooltip>
      <Slider id='denoise' value={denoise} step={0.05} min={0} max={1} onChange={v => updateDenoise(v)}>
          <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
      </Slider>
      {/* image */}
      <ImageOptions label={"Reference Image"} hideImage={value?.inpaint?.enabled} value={value?.image} onChange={v => { updateValue({ ...value, image: v }) }} />

      {/* Enable inpainting */}
      {value?.inpaint?.enabled === true && <>
        <MaskEditor bgContent={value?.image?.content} value={value?.inpaint?.mask} onChange={v => updateValue({ ...value, inpaint : {...value.inpaint, mask: v} })} />
        <Tooltip hasArrow label="Mask Blur - How much to feather edges of the mask" openDelay={250} >
          <FormLabel htmlFor="inpaint_mask_blur">Mask Blur ({value.inpaint.mask_blur})</FormLabel>
        </Tooltip>
        <Flex w={"full"} direction={"column"}>
          <Slider
            id='inpaint_mask_blur'
            value={value.inpaint.mask_blur}
            step={1}
            min={0}
            max={256}
            onChange={v => updateValue({ ...value, inpaint: { ...value.inpaint, mask_blur: v } })}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
        <Flex>
          <Tooltip hasArrow label="Full Res Inpaint" openDelay={250}>
            <FormLabel htmlFor="inpaint_full_res">Full Res Inpaint</FormLabel>
          </Tooltip>
          <Switch
            id="inpaint_full_res"
            isChecked={value.inpaint.full_res}
            onChange={e => updateValue({ ...value, inpaint: { ...value.inpaint, full_res: e.target.checked } })}
          />
        </Flex>
        {value?.inpaint?.full_res && <>
          <Tooltip hasArrow label="Full Res Padding" openDelay={250} >
            <FormLabel htmlFor="inpaint_full_res_padding">Full Res Padding ({value.inpaint.full_res_padding})</FormLabel>
          </Tooltip>
          <Flex w={"full"} direction={"column"}>
            <Slider
              id='inpaint_full_res_padding'
              value={value.inpaint.full_res_padding}
              step={1}
              min={0}
              max={256}
              onChange={full_res_padding => updateValue({ ...value, inpaint: { ...value.inpaint, full_res_padding } })}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
        </>}
        <Tooltip hasArrow label="Noise Multiplier" openDelay={250}>
          <FormLabel htmlFor="img2img_initial_noise_multiplier">Noise Multiplier ({value.inpaint.noise_multiplier})</FormLabel>
        </Tooltip>
        <Flex w={"full"} direction="column">
          <Slider
            id='img2img_initial_noise_multiplier'
            value={value.inpaint.noise_multiplier}
            step={0.05}
            min={0.5}
            max={1.5}
            onChange={noise_multiplier => updateValue({ ...value, inpaint: { ...value.inpaint, noise_multiplier } })}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
      </>}
  </PropBox> : <Box>Loading...</Box>
}