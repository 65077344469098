import {
    Box,
    Select,
    Wrap,
    WrapItem,
    FormLabel,
    Tooltip,
    Flex,
    VStack,
} from '@chakra-ui/react';

/**
 * Renders the PipelinePicker component.
 * @param {Object} value - The selected value.
 * @param {Function} onChange - The change event handler.
 * @returns {JSX.Element} The pipeline picker component.
 */
export const PipelinePicker = ({ 
    value,
    onChange = () => {console.warn("No onChange handler for PipelinePicker.")}
}) => {
    
    const pipelineTypes = [
        { key: "sd-1.5", text: "Stable Diffusion 1.5" },
        { key: "sdxl-1.0", text: "Stable Diffusion XL 1.0" },
        { key: "sd-3.0", text: "Stable Diffusion 3.0" },
        // { key: "custom", text: "Custom" }
    ]

    return value !== undefined ?
        <VStack align={"flex-start"} w={"full"}>
            <Wrap mr={2}>
                <Flex>
                    <Tooltip hasArrow label="The type of pipeline your jobs will be.  For example, selecting an sd-1.5 pipeline will have different options than am sdxl-1.0 pipeline." openDelay={250}>
                        <FormLabel htmlFor="type">Pipeline Type</FormLabel>
                    </Tooltip>
                </Flex>
                <WrapItem>
                    <Select id="type" value={value.type} onChange={e => onChange && onChange({ ...value, "type": e.target.selectedOptions[0].value })}>{
                        pipelineTypes.map(shape => {
                            return <option key={shape.key} value={shape.key}>{shape.text ? shape.text : shape.key}</option>
                        })}
                    </Select>
                </WrapItem>
            </Wrap>
        </VStack> : <Box>Loading...</Box>
}